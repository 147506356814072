<mat-toolbar>
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" style="margin-right: 20px;">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <button mat-icon-button (click)="toggleMenu.emit()">
                <mat-icon>menu</mat-icon>
            </button>
            <!-- {{ activeMenuItem?.title | translate | uppercase }} -->
            <div fxLayout="row">
                <img src="/assets/images/logo_wit.png" style="height: 36px"/>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div *ngIf="!viewMobile && isOperator" fxLayoutGap="20px">
                <button mat-raised-button [routerLink]="['/voucher/new']" color="accent">
                    <mat-icon>receipt</mat-icon> 
                    {{ 'APPLICATION.add_voucher' | translate | capitalize}}
                </button>
                <button mat-raised-button [routerLink]="['/booking/new']" color="accent">
                    <mat-icon>perm_contact_calendar</mat-icon> 
                    {{ 'APPLICATION.add_booking' | translate | capitalize}}
                </button>
                <button mat-raised-button [routerLink]="['/flight/new']" color="accent">
                    <mat-icon>flight_takeoff</mat-icon> 
                    {{ 'APPLICATION.add_flight' | translate | capitalize}}
                </button>
            </div>
            <div *ngIf="isOperator">
                <button *ngIf="unreadSms > 0" mat-icon-button [routerLink]="['/dashboard']" color="accent">
                    <mat-icon  [matBadge]="unreadSms" matBadgeColor="warn">sms</mat-icon> 
                </button>
                <button *ngIf="unreadSms === 0" mat-icon-button [routerLink]="['/dashboard']" color="primary">
                    <mat-icon>sms</mat-icon> 
                </button>
            </div>
            <div *ngIf="!viewMobile">{{ tenant | uppercase }}</div>
            <div style="display:flex; flex-direction: row; align-items: center;">
                <button *ngIf="!viewMobile" [disabled]="helpUrl===''"  mat-icon-button (click)="getHelp(helpUrl)">
                    <mat-icon [color]="helpUrl === '' ? 'primary' : 'blank'">help</mat-icon>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="dropdownMenu">
                    <mat-icon>account_circle</mat-icon>
                </button>
            </div>
        </div>
    </div>
</mat-toolbar>
<mat-menu #dropdownMenu x-position="before">
    <a mat-menu-item href="" (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ 'APPLICATION.sign_out' | translate | capitalize}}</span>
    </a>
    <!-- SHOW RELEASE NOTES -->
    <a mat-menu-item (click)="showReleaseNotes()">
        <mat-icon>open_in_browser</mat-icon>
        <span>{{ 'VALIDATIONS.show_new_features' | translate | capitalize}}</span>
    </a>
    <div mat-menu-item>Version {{ currentAppVersion }}</div>
  </mat-menu>