import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
	SendBookingSmsDocument,
	GetAllSmsTemplatesDocument,
	SmsByBookingDocument,
	MarkBookingMessagesReadDocument,
	SmsByCrewDocument,
	GetSmsTemplatesDocument,
	SmsTemplateInput,
	SaveSmsTemplateDocument,
	UnreadSmsDocument,
	SendCrewSmsDocument,
	MarkCrewMessagesReadDocument,
	AddSmsTemplateLocationContactDocument,
	SendLocationSmsDocument,
} from '../../graphql/generated/gen-types';
import { AuthenticationService } from '../authentication/authentication.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SmsService {
	currentUser;
	private newSms = new BehaviorSubject(0);
	newSmsChanged = this.newSms.asObservable();

	constructor(private apollo: Apollo, private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

	receivedNewSms(count: any) {
		this.newSms.next(count);
	}

	public saveSmsTemplate(smsTemplateInput: SmsTemplateInput, smsTemplateId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveSmsTemplateDocument,
				variables: {
					smsTemplateInput,
					smsTemplateId,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public sendBookingSMS(bookingId, smsInput): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SendBookingSmsDocument,
				variables: {
					bookingId,
					smsInput,
				},
				refetchQueries: [
					{
						query: SmsByBookingDocument,
						variables: {
							bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public sendLocationSMS(locationId, flightId, smsInput): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SendLocationSmsDocument,
				variables: {
					locationId,
					flightId,
					smsInput,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public sendCrewSms(crewId, smsInput): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SendCrewSmsDocument,
				variables: {
					crewId,
					smsInput,
				},
				refetchQueries: [
					{
						query: SmsByCrewDocument,
						variables: {
							crewId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public smsByBooking(bookingId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: SmsByBookingDocument,
			variables: {
				bookingId,
			},
		});
	}

	public smsByCrew(crewId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: SmsByCrewDocument,
			variables: {
				crewId,
			},
		});
	}

	public unreadSms(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: UnreadSmsDocument,
		});
	}

	public markBookingMessagesRead(bookingId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: MarkBookingMessagesReadDocument,
				variables: {
					bookingId,
				},
				refetchQueries: [
					{
						query: SmsByBookingDocument,
						variables: { bookingId },
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}
	public addSmsTemplateLocationContact(): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: AddSmsTemplateLocationContactDocument,
				refetchQueries: [
					{
						query: GetAllSmsTemplatesDocument,
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public markCrewMessagesRead(crewId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: MarkCrewMessagesReadDocument,
				variables: {
					crewId,
				},
				refetchQueries: [
					{
						query: SmsByCrewDocument,
						variables: { crewId },
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public getAllSmsTemplates(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetAllSmsTemplatesDocument,
		});
	}

	public getSmsTemplates(type: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetSmsTemplatesDocument,
			variables: {
				type,
			},
		});
	}

	//TODO: add save

	// public updateSmsTemplate():  Observable<any>{
	// 	return this.apollo
	// 	.mutate<any>({
	// 		mutation: ,
	// 		variables: {

	// 		},
	// 	})
	// 	.pipe(map((result: any) => result.data));
	// }

	// public getSMSOut(crewPhone: string): QueryRef<any> {
	// 	return this.apollo.watchQuery({
	// 		query: GetSmsOutDocument,
	// 		variables: {
	// 			crewPhone,
	// 		},
	// 	});
	// }
}
