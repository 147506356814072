import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './directives/auto-focus.directive';
@NgModule({
  declarations: [
    AutoFocusDirective,
  ],
  imports: [],
  exports: [
    AutoFocusDirective
  ]
})
export class CoreModule { }
