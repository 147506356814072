<mat-nav-list>
	<div *ngFor="let menuItem of menuItems">
		<div *ngIf="!menuItem.subMenu" [routerLink]="menuItem.link" (click)="toggle(menuItem)">
			<div class="menuItem">
				<button mat-icon-button>
					<mat-icon>{{ menuItem.icon }}</mat-icon>
					<span style="font-size: larger; margin-left: 20px">{{ menuItem.title | translate | capitalize }}</span>
				</button>
			</div>
		</div>
		<div *ngIf="menuItem.subMenu">
			<div class="menuItem" (click)="toggle(menuItem)">
				<button mat-icon-button>
					<mat-icon>{{ menuItem.icon }}</mat-icon>
					<span style="font-size: larger; margin-left: 20px">{{ menuItem.title | translate | capitalize }}</span>
				</button>
				<mat-icon>expand_more</mat-icon>
			</div>
        </div>
		<div *ngIf="menuItem.expanded" >
			<div class="menuItem" style="margin-left: 30px" *ngFor="let subMenu of menuItem.subMenu">
				<button mat-icon-button [routerLink]="subMenu.link">
					<mat-icon>{{ subMenu.icon }}</mat-icon>
					<span style="font-size: larger; margin-left: 20px">{{ subMenu.title | translate | capitalize }}</span>
				</button>
			</div>
        </div>

	</div>
</mat-nav-list>
