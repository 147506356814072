import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ReleaseNote, Subscription } from 'src/app/core/graphql/generated/gen-types';
import { ResponsiveService, ScreenSize } from 'src/app/core/services/admin/responsive.service';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';
import { SnackBar } from 'src/app/core/utility/snackBar';
import { DialogReleasenotesComponent } from 'src/app/features/dialog/dialog-releasenotes/dialog-releasenotes.component';

@Component({
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
	sideNavOpened = true;
	mode: string = 'side';
	mobileRoute: boolean = false;
	xsmall: boolean = false;
	subscription: Subscription;
	releaseNotesCount: number;
	constructor(
		private responsiveService: ResponsiveService,
		private router: Router,
		private snackBar: SnackBar,
		private tenantSettingService: TenantSettingService,
		private dialog: MatDialog
	) {
		this.tenantSettingService.getTenantSetting().valueChanges.subscribe((result) => {
			this.subscription = result.data.tenantSetting.subscription;
		});
		this.tenantSettingService.getReleaseNotesCount().valueChanges.subscribe((result) => {
			this.releaseNotesCount = result.data.releaseNotesCount;
		});
	}

	ngOnInit() {
		// observe the viewport, responsive in case of Mobile
		this.responsiveService.XSmallChanged.subscribe(
			(xsmall) => {
				this.xsmall = xsmall;
				if (xsmall || this.mobileRoute) {
					this.mode = 'push';
					this.sideNavOpened = false;
				} else {
					this.mode = 'side';
					this.sideNavOpened = true;
				}
			},
			(err) => this.snackBar.openSnackBarError(err.message)
		);

		// observe the route and close sidenav when mobile route is taken
		if (this.router.url.includes('mobile')) {
			this.mobileRoute = true;
			this.mode = 'push';
			this.sideNavOpened = false;
		}

		this.router.events.subscribe(
			(event) => {
				if (event instanceof NavigationEnd) {
					if (event.url.includes('mobile')) {
						this.mobileRoute = true;
						this.mode = 'push';
						this.sideNavOpened = false;
					} else {
						this.mobileRoute = false;
						this.mode = this.xsmall ? 'push' : 'side';
						this.sideNavOpened = !this.xsmall;
					}
					this.responsiveService.changeHelpURL('/');
				}
			},
			(err) => this.snackBar.openSnackBarError(err.message)
		);
	}

	toggleMenu() {
		this.sideNavOpened = !this.sideNavOpened;
	}

	showReleaseNotes() {
		this.dialog.open(DialogReleasenotesComponent, { width: '80%'});
	}
}
