<app-toolbar (toggleMenu)="toggleMenu()"></app-toolbar>
<mat-sidenav-container>
	<mat-sidenav fxLayout="column" [mode]="mode" [opened]="sideNavOpened">
		<app-side-menu></app-side-menu>
	</mat-sidenav>
	<mat-sidenav-content class="mat-typography">
		<div *ngIf="subscription?.sms?.pctUsed >= 95 && !subscription?.sms?.ignoreWarning" class="message">
			<div fxLayout="row" fxLayoutAlign="space-around center">
				<mat-icon color="warn">warning</mat-icon>
				<div>
					{{ 'SUBSCRIPTION.sms_warning' | translate | capitalize }}
					<a routerLink="/subscription">{{ 'SUBSCRIPTION.order_sms' | translate | capitalize }}</a>
				</div>
				<mat-icon color="warn">warning</mat-icon>
			</div>
		</div>
		<div *ngIf="releaseNotesCount" class="message">
			<div style="display:flex; flex-direction: row; justify-content: space-around; align-items:center;">
				<div style="display:flex; flex-direction: row; justify-content: center; align-items:center; gap: 10px;">
					{{ ('VALIDATIONS.new_features_available' | translate | capitalize).replace('{0}', releaseNotesCount) }}
					<button mat-icon-button color="primary" (click)="showReleaseNotes()"><mat-icon>open_in_browser</mat-icon></button>
				</div>
			</div>
		</div>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
