import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NewSmsDocument, UserRole } from 'src/app/core/graphql/generated/gen-types';
import { AuthenticationService, UserService } from 'src/app/core/services';
import { ResponsiveService } from 'src/app/core/services/admin/responsive.service';
import { SmsService } from 'src/app/core/services/sms/sms.service';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';
import { SnackBar } from 'src/app/core/utility/snackBar';
import { DialogReleasenotesComponent } from 'src/app/features/dialog/dialog-releasenotes/dialog-releasenotes.component';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
	@Output() toggleMenu = new EventEmitter<any>();
	guards = environment.componentGuards.toolbar;
	userRole = UserRole;
	tenant;
	viewMobile: boolean = true;
	isOperator: boolean = false;
	unreadSms: number = 0;
	unreadMessages: any;
	newSmsUnsub: () => void;
	// tenants: Tenant[];
	currentAppVersion: string;
	helpUrl: string;
	baseHelpUrl = environment.baseHelpUrl;

	constructor(
		private tenantSettingService: TenantSettingService,
		private authenticationService: AuthenticationService,
		private responsiveService: ResponsiveService,
		private router: Router,
		private userService: UserService,
		private smsService: SmsService,
		private snackBar: SnackBar,
		private dialog: MatDialog
	) {
		this.currentAppVersion = environment.appVersion;
	}

	ngOnInit(): void {
		this.responsiveService.helpURLChanged.subscribe((url) => {
			this.helpUrl = url;
		});
		this.responsiveService.viewMobileChanged.subscribe((viewMobile) => {
			this.viewMobile = viewMobile;
		});

		this.userService.userProfileChanged.subscribe((userProfile) => {
			this.userRole = userProfile.role;
			this.isOperator = this.guards.operator.includes(userProfile.role);
			if (this.isOperator) {
				this.smsService.unreadSms().valueChanges.subscribe((result) => {
					this.unreadSms = result.data.unreadSms.length;
					this.unreadMessages = result.data.unreadSms;
					// TODO: this.showSms();
				});
			}
		});

		this.tenantSettingService.getTenantSetting().valueChanges.subscribe((result) => {
			this.tenant = result.data.tenantSetting.name;
		});

		this.newSmsUnsub = this.smsService.unreadSms().subscribeToMore({
			document: NewSmsDocument,
			updateQuery: (prev, { subscriptionData }) => {
				this.unreadMessages = subscriptionData.data.newSms.filter((sms) => sms.path.includes(this.tenant)); // TODO: move filter to backend using onConnect
				this.unreadSms = this.unreadMessages.length;
				this.smsService.receivedNewSms(this.unreadSms);
				// TODO: this.showSms();
			},
		});

		this.responsiveService.changeHelpURL('/');
	}

	ngOnDestroy(): void {
		if (this.newSmsUnsub) {
			this.newSmsUnsub();
		}
	}

	
	
	logout() {
		this.authenticationService.logout();
		this.router.navigate(['/account/login']);
	}

	showSms() {
		if (!environment.production) console.log(this.unreadMessages);
	}

	getHelp(url) {
		window.open(this.baseHelpUrl + '/' + url, '_blank');
	}
	
	showReleaseNotes() {
		window.open(this.baseHelpUrl + '/release-notes/', '_blank');
	
	}
}
