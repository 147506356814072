import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Apollo, QueryRef } from 'apollo-angular';
import {
	CreateUserDocument,
	DeleteUserDocument,
	GetUserDocument,
	GetUsersDocument,
	SaveUserViewStateDocument,
	SendPasswordResetEmailDocument,
	TenantDocument,
} from '../../graphql/generated/gen-types';
@Injectable({
	providedIn: 'root',
})
export class UserService {
	userProfile: BehaviorSubject<any>;
	userProfileChanged: Observable<any>;

	constructor(private apollo: Apollo) {
		this.userProfile = new BehaviorSubject({});
		this.userProfileChanged = this.userProfile.asObservable();
	}

	public get getUserProfile() {
		return this.userProfile.value;
	}

	public nextUserProfile(user) {
		return this.userProfile.next(user);
	}

	public createUser(GcpipUserInput, tenantId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: CreateUserDocument,
				variables: {
					GcpipUserInput,
					tenantId,
				},
				update: (cache, { data }) => {
					const existingUsers = cache.readQuery<any>({ query: GetUsersDocument, variables: { tenantId } });
					const newUser = data.createUser;
					cache.writeQuery({ query: GetUsersDocument, variables: { tenantId }, data: { getUsers: [...existingUsers.getUsers, newUser] } });
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public deleteUser(uid: string, tenantId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteUserDocument,
				variables: {
					uid: uid,
					tenantId: tenantId,
				},
				update: (cache, { data }) => {
					const existingUsers = cache.readQuery<any>({ query: GetUsersDocument, variables: { tenantId } });
					const deletedUserUid = data.deleteUser;
					const remainingUsers = existingUsers.getUsers.filter((user: any) => user.uid !== deletedUserUid);
					cache.writeQuery({ query: GetUsersDocument, variables: { tenantId }, data: { getUsers: remainingUsers } });
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public getUsers(tenantId): QueryRef<any[]> {
		return this.apollo.watchQuery({
			query: GetUsersDocument,
			variables: {
				tenantId: tenantId,
			},
		});
	}

	public getUser(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetUserDocument,
		});
	}

	public tenant(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: TenantDocument,
		});
	}

	public sendPasswordResetEmail(email: string, tenantId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SendPasswordResetEmailDocument,
				variables: {
					email: email,
					tenantId: tenantId,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public saveUserViewState(viewState): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveUserViewStateDocument,
				variables: {
					viewState,
				},
				refetchQueries: [
					{
						query: GetUserDocument,
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}
}
