import { NgModule } from '@angular/core';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DialogEmailComponent } from './dialog-email/dialog-email.component';
import { DialogEventComponent } from './dialog-event/dialog-event.component';
import { DialogOffsetComponent } from './dialog-offset/dialog-offset.component';
import { DialogEventCrewComponent } from './dialog-event-crew/dialog-event-crew.component';
import { DialogSmsComponent } from './dialog-sms/dialog-sms.component';
import { DialogSkeyesComponent } from './dialog-skeyes/dialog-skeyes.component';
import { DialogEmailMultipleComponent } from './dialog-email-multiple/dialog-email-multiple.component';
import { DialogTrajectoryComponent } from './dialog-trajectory/dialog-trajectory.component';
import { DialogFlightCopyComponent } from './dialog-flight-copy/dialog-flight-copy.component';
import { DialogFlightPilotCrewComponent } from './dialog-flight-pilot-crew/dialog-flight-pilot-crew.component';
import { DialogReleasenotesComponent } from './dialog-releasenotes/dialog-releasenotes.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
	imports: [SharedModule, YouTubePlayerModule, MarkdownModule.forChild()],
	exports: [DialogConfirmComponent],
	declarations: [
		DialogConfirmComponent,
		DialogEmailComponent,
		DialogEmailMultipleComponent,
		DialogEventComponent,
		DialogOffsetComponent,
		DialogEventCrewComponent,
		DialogSmsComponent,
		DialogSkeyesComponent,
		DialogTrajectoryComponent,
		DialogFlightCopyComponent,
		DialogFlightPilotCrewComponent,
		DialogReleasenotesComponent,
	],
})
export class DialogModule {}
