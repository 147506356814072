import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Currency: any;
  Date: any;
  DateTime: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  JSON: any;
  JSONObject: any;
  Long: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};



export type AddressComponent = {
  __typename?: 'AddressComponent';
  long_name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApiBookingInput = {
  contactCity?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactLanguage: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  flightId?: Maybe<Scalars['String']>;
  regionId: Scalars['String'];
  passengers?: Maybe<Array<Maybe<ApiPassengerInput>>>;
};

export type ApiPassengerInput = {
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['NonNegativeInt']>;
  child?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['NonNegativeInt']>;
  paymentVoucher?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentVoucherId?: Maybe<Scalars['String']>;
};

export type ApiVoucherInput = {
  adults?: Maybe<Scalars['NonNegativeInt']>;
  children?: Maybe<Scalars['NonNegativeInt']>;
  comments?: Maybe<Scalars['String']>;
  contactCity?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactLanguage: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  expiryDate?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['NonNegativeInt']>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  voucherReference: Scalars['String'];
  voucherType: Scalars['String'];
};

export enum AvailabilityType {
  Peri = 'PERI',
  Date = 'DATE'
}

export type Balloon = {
  __typename?: 'Balloon';
  id: Scalars['ID'];
  balloonTemp?: Maybe<Scalars['PositiveInt']>;
  basketManufacturer?: Maybe<Scalars['String']>;
  basketType?: Maybe<Scalars['String']>;
  basketWeight?: Maybe<Scalars['PositiveInt']>;
  burnerManufacturer?: Maybe<Scalars['String']>;
  burnerType?: Maybe<Scalars['String']>;
  burnerWeight?: Maybe<Scalars['PositiveInt']>;
  callSign?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['PositiveInt']>;
  classGroups?: Maybe<Scalars['String']>;
  cylinder: Array<Maybe<Cylinder>>;
  envelopeHours?: Maybe<Scalars['PositiveInt']>;
  envelopeInspectionDate?: Maybe<Scalars['DateTime']>;
  envelopeManufacturer?: Maybe<Scalars['String']>;
  envelopeType?: Maybe<Scalars['String']>;
  envelopeVolume?: Maybe<Scalars['PositiveInt']>;
  envelopeWeight?: Maybe<Scalars['PositiveInt']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  insuranceDueDate?: Maybe<Scalars['DateTime']>;
  isCurrent?: Maybe<Scalars['Int']>;
  minFuel?: Maybe<Scalars['PositiveInt']>;
  mlm?: Maybe<Scalars['PositiveInt']>;
  mtom?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['DateTime']>;
  balloonWeight?: Maybe<BalloonWeight>;
  classGroup: Array<Maybe<ClassGroup>>;
};

export type BalloonInput = {
  balloonTemp?: Maybe<Scalars['PositiveInt']>;
  basketManufacturer?: Maybe<Scalars['String']>;
  basketType?: Maybe<Scalars['String']>;
  basketWeight?: Maybe<Scalars['PositiveInt']>;
  burnerManufacturer?: Maybe<Scalars['String']>;
  burnerType?: Maybe<Scalars['String']>;
  burnerWeight?: Maybe<Scalars['PositiveInt']>;
  callSign?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['PositiveInt']>;
  classGroups?: Maybe<Scalars['String']>;
  envelopeHours?: Maybe<Scalars['PositiveInt']>;
  envelopeInspectionDate?: Maybe<Scalars['DateTime']>;
  envelopeManufacturer?: Maybe<Scalars['String']>;
  envelopeType?: Maybe<Scalars['String']>;
  envelopeVolume?: Maybe<Scalars['PositiveInt']>;
  envelopeWeight?: Maybe<Scalars['PositiveInt']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  insuranceDueDate?: Maybe<Scalars['DateTime']>;
  minFuel?: Maybe<Scalars['PositiveInt']>;
  mlm?: Maybe<Scalars['PositiveInt']>;
  mtom?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
};

export type BalloonWeight = {
  __typename?: 'BalloonWeight';
  totalWeight: Scalars['PositiveInt'];
  cylindersWeight: Scalars['PositiveInt'];
};


export type Booking = {
  __typename?: 'Booking';
  availabilityFridayEvening?: Maybe<Scalars['Boolean']>;
  availabilityFridayMorning?: Maybe<Scalars['Boolean']>;
  availabilityMondayEvening?: Maybe<Scalars['Boolean']>;
  availabilityMondayMorning?: Maybe<Scalars['Boolean']>;
  availabilitySaturdayEvening?: Maybe<Scalars['Boolean']>;
  availabilitySaturdayMorning?: Maybe<Scalars['Boolean']>;
  availabilitySundayEvening?: Maybe<Scalars['Boolean']>;
  availabilitySundayMorning?: Maybe<Scalars['Boolean']>;
  availabilityThursdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityThursdayMorning?: Maybe<Scalars['Boolean']>;
  availabilityTuesdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityTuesdayMorning?: Maybe<Scalars['Boolean']>;
  availabilityType?: Maybe<AvailabilityType>;
  availabilityWednesdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityWednesdayMorning?: Maybe<Scalars['Boolean']>;
  bookingCode?: Maybe<Scalars['String']>;
  bookingDate?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLanguage?: Maybe<ContactLanguage>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['PhoneNumber']>;
  endDate?: Maybe<Scalars['DateTime']>;
  daysExpired?: Maybe<Scalars['NonNegativeInt']>;
  external?: Maybe<Scalars['Boolean']>;
  externalSource?: Maybe<Scalars['String']>;
  flight?: Maybe<Flight>;
  flightId?: Maybe<Scalars['ID']>;
  icon?: Maybe<Scalars['String']>;
  iconColor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviteCount?: Maybe<Scalars['Int']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  locationType?: Maybe<LocationType>;
  mailEventLatest?: Maybe<MailEventLatest>;
  mergeCandidates?: Maybe<Array<Maybe<Booking>>>;
  mismatchReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  passengerCounters?: Maybe<PassengerCounters>;
  passengers: Array<Maybe<Passenger>>;
  preferredDate?: Maybe<Scalars['String']>;
  preferredFlights?: Maybe<Array<Maybe<PreferredFlight>>>;
  prepaymentAmount?: Maybe<Scalars['Int']>;
  prepaymentDate?: Maybe<Scalars['DateTime']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['ID']>;
  removed?: Maybe<Scalars['DateTime']>;
  smsLatest?: Maybe<SmsLatest>;
  smsType?: Maybe<SmsConversationType>;
  smsUnread?: Maybe<Scalars['NonNegativeInt']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<BookingStatus>;
  upUrl?: Maybe<Scalars['String']>;
  logs?: Maybe<Array<Maybe<BookingLog>>>;
  logsMerged?: Maybe<Array<Maybe<BookingLog>>>;
  mails?: Maybe<Array<Maybe<Email>>>;
  mailsMerged?: Maybe<Array<Maybe<Email>>>;
  bookingsMerged?: Maybe<Array<Maybe<Booking>>>;
  invoiceName?: Maybe<Scalars['String']>;
  VATnumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  upcoming?: Maybe<Scalars['Boolean']>;
};

export type BookingCounters = {
  __typename?: 'BookingCounters';
  historical?: Maybe<Scalars['NonNegativeInt']>;
  statusCounters?: Maybe<Array<Maybe<StatusCounter>>>;
  toConfirm?: Maybe<Scalars['NonNegativeInt']>;
  upcoming?: Maybe<Scalars['NonNegativeInt']>;
};

export type BookingData = {
  __typename?: 'BookingData';
  id: Scalars['ID'];
  contactName?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  iconColor?: Maybe<Scalars['String']>;
  passengers?: Maybe<Array<Maybe<PassengerData>>>;
  status?: Maybe<Scalars['String']>;
};

export enum BookingFilter {
  Standard = 'standard',
  ToConfirm = 'toConfirm',
  OpenAmount = 'openAmount',
  AvailableThisYear = 'availableThisYear',
  AvailableNext30Days = 'availableNext30Days',
  FlyingThisYear = 'flyingThisYear',
  FlyingNext30Days = 'flyingNext30Days',
  WithoutFlight = 'withoutFlight'
}

export type BookingInput = {
  availabilityFridayEvening?: Maybe<Scalars['Boolean']>;
  availabilityFridayMorning?: Maybe<Scalars['Boolean']>;
  availabilityMondayEvening?: Maybe<Scalars['Boolean']>;
  availabilityMondayMorning?: Maybe<Scalars['Boolean']>;
  availabilitySaturdayEvening?: Maybe<Scalars['Boolean']>;
  availabilitySaturdayMorning?: Maybe<Scalars['Boolean']>;
  availabilitySundayEvening?: Maybe<Scalars['Boolean']>;
  availabilitySundayMorning?: Maybe<Scalars['Boolean']>;
  availabilityThursdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityThursdayMorning?: Maybe<Scalars['Boolean']>;
  availabilityTuesdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityTuesdayMorning?: Maybe<Scalars['Boolean']>;
  availabilityType?: Maybe<AvailabilityType>;
  availabilityWednesdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityWednesdayMorning?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLanguage?: Maybe<ContactLanguage>;
  contactName: Scalars['String'];
  contactPhone: Scalars['PhoneNumber'];
  endDate?: Maybe<Scalars['DateTime']>;
  external?: Maybe<Scalars['Boolean']>;
  externalSource?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  locationType?: Maybe<LocationType>;
  pax?: Maybe<Scalars['Int']>;
  preferredDate?: Maybe<Scalars['String']>;
  preferredFlights?: Maybe<Array<Maybe<PreferredFlightInput>>>;
  prepaymentAmount?: Maybe<Scalars['Int']>;
  prepaymentDate?: Maybe<Scalars['DateTime']>;
  regionId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<BookingStatus>;
  invoiceName?: Maybe<Scalars['String']>;
  VATnumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BookingLog = {
  __typename?: 'BookingLog';
  id: Scalars['ID'];
  action?: Maybe<BookingLogAction>;
  booking?: Maybe<Booking>;
  date?: Maybe<Scalars['DateTime']>;
  flight?: Maybe<Flight>;
  user?: Maybe<Scalars['String']>;
};

export enum BookingLogAction {
  Dele = 'DELE',
  Remo = 'REMO',
  Adde = 'ADDE',
  Invi = 'INVI',
  Conf = 'CONF',
  Comp = 'COMP',
  Mail = 'MAIL',
  Crea = 'CREA'
}

export type BookingPaymentInput = {
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['PhoneNumber']>;
  prepaymentAmount?: Maybe<Scalars['Int']>;
  prepaymentDate?: Maybe<Scalars['DateTime']>;
  invoiceName?: Maybe<Scalars['String']>;
  VATnumber?: Maybe<Scalars['String']>;
};

export enum BookingStatus {
  Open = 'OPEN',
  Adde = 'ADDE',
  Invi = 'INVI',
  Conf = 'CONF',
  Comp = 'COMP',
  Canc = 'CANC'
}

export type BookingsData = {
  __typename?: 'BookingsData';
  id?: Maybe<Scalars['ID']>;
  list?: Maybe<Array<Maybe<BookingData>>>;
  count?: Maybe<Scalars['NonNegativeInt']>;
  addedCount?: Maybe<Scalars['NonNegativeInt']>;
  notConfirmedCount?: Maybe<Scalars['NonNegativeInt']>;
  notConfirmedNames?: Maybe<Scalars['String']>;
};


export type Calendar = {
  __typename?: 'Calendar';
  flights?: Maybe<Array<Maybe<Flight>>>;
  events?: Maybe<Array<Maybe<Event>>>;
};

export type CalendarItem = {
  __typename?: 'CalendarItem';
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['JSONObject']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CallSignPopularity = {
  __typename?: 'CallSignPopularity';
  callSign?: Maybe<Scalars['String']>;
  crewPopularity?: Maybe<Scalars['NonNegativeInt']>;
  pilotPopularity?: Maybe<Scalars['NonNegativeInt']>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  child?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  removed?: Maybe<Scalars['DateTime']>;
};

export type CategoryInput = {
  child?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type Chart = {
  __typename?: 'Chart';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ChartType>;
  data?: Maybe<Scalars['JSONObject']>;
  columns?: Maybe<Scalars['JSONObject']>;
  options?: Maybe<Scalars['JSONObject']>;
};

export type ChartColor = {
  __typename?: 'ChartColor';
  backgroundColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  borderColor?: Maybe<Scalars['String']>;
};

export enum ChartType {
  AnnotationChart = 'AnnotationChart',
  AreaChart = 'AreaChart',
  Bar = 'Bar',
  BarChart = 'BarChart',
  BubbleChart = 'BubbleChart',
  Calendar = 'Calendar',
  CandlestickChart = 'CandlestickChart',
  ColumnChart = 'ColumnChart',
  ComboChart = 'ComboChart',
  PieChart = 'PieChart',
  Gantt = 'Gantt',
  Gauge = 'Gauge',
  GeoChart = 'GeoChart',
  Histogram = 'Histogram',
  Line = 'Line',
  LineChart = 'LineChart',
  Map = 'Map',
  OrgChart = 'OrgChart',
  Sankey = 'Sankey',
  Scatter = 'Scatter',
  ScatterChart = 'ScatterChart',
  SteppedAreaChart = 'SteppedAreaChart',
  Table = 'Table',
  Timeline = 'Timeline',
  TreeMap = 'TreeMap',
  Wordtree = 'wordtree'
}

export type ClassGroup = {
  __typename?: 'ClassGroup';
  id: Scalars['String'];
  description: Scalars['String'];
};

export enum ContactLanguage {
  Nl = 'nl',
  Fr = 'fr',
  En = 'en',
  De = 'de'
}

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type Crew = ICrew & {
  __typename?: 'Crew';
  callSignPopularity?: Maybe<Array<Maybe<CallSignPopularity>>>;
  driverLicense?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['EmailAddress']>;
  emailNotifications?: Maybe<Scalars['EmailAddress']>;
  groundCrew?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initials?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Int']>;
  isPilot?: Maybe<Scalars['Boolean']>;
  language?: Maybe<ContactLanguage>;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  licenseExtraCrew?: Maybe<Scalars['Boolean']>;
  licenseGas?: Maybe<Scalars['Boolean']>;
  licenseGroupA?: Maybe<Scalars['Boolean']>;
  licenseGroupB?: Maybe<Scalars['Boolean']>;
  licenseGroupC?: Maybe<Scalars['Boolean']>;
  licenseGroupD?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  medicalExpiryDate?: Maybe<Scalars['DateTime']>;
  medicalWeight?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  pilotLicense?: Maybe<PilotLicense>;
  proficiencyLatestBpl?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightA?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightB?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightC?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightD?: Maybe<Scalars['DateTime']>;
  proficiencyLatestGas?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['DateTime']>;
  smsLatest?: Maybe<SmsLatest>;
  smsType?: Maybe<SmsConversationType>;
  smsUnread?: Maybe<Scalars['NonNegativeInt']>;
  status?: Maybe<CrewStatus>;
  totalWeight?: Maybe<Scalars['PositiveInt']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  userRole?: Maybe<UserRole>;
};

export enum CrewAvailability {
  Unk = 'UNK',
  Yes = 'YES',
  Maybe = 'MAYBE',
  No = 'NO'
}

export type CrewAvailabilityInput = {
  availability: CrewAvailability;
  crewId?: Maybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  period: FlightPeriod;
  type?: Maybe<EventType>;
};

export type CrewCalendarDay = {
  __typename?: 'CrewCalendarDay';
  flightsMorning?: Maybe<CrewDayContent>;
  flightsEvening?: Maybe<CrewDayContent>;
};

export type CrewCalendarItem = {
  __typename?: 'CrewCalendarItem';
  start?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<CrewCalendarDay>;
};

export type CrewDayContent = {
  __typename?: 'CrewDayContent';
  totalFlights?: Maybe<Scalars['Int']>;
  crewEvent?: Maybe<Event>;
  selectedFlights?: Maybe<Array<Maybe<Flight>>>;
};

export type CrewInFlight = {
  __typename?: 'CrewInFlight';
  crew?: Maybe<CrewPerFlight>;
  status?: Maybe<CrewStatus>;
};

export type CrewInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  email?: Maybe<Scalars['EmailAddress']>;
  emailNotifications?: Maybe<Scalars['EmailAddress']>;
  language?: Maybe<ContactLanguage>;
  pilotLicense?: Maybe<PilotLicense>;
  licenseGroupA?: Maybe<Scalars['Boolean']>;
  licenseGroupB?: Maybe<Scalars['Boolean']>;
  licenseGroupC?: Maybe<Scalars['Boolean']>;
  licenseGroupD?: Maybe<Scalars['Boolean']>;
  licenseGas?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseExtraCrew?: Maybe<Scalars['Boolean']>;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  medicalExpiryDate?: Maybe<Scalars['DateTime']>;
  groundCrew?: Maybe<Scalars['Boolean']>;
  driverLicense?: Maybe<Scalars['Boolean']>;
  medicalWeight?: Maybe<Scalars['PositiveInt']>;
  userRole?: Maybe<UserRole>;
};

export type CrewPerFlight = ICrew & {
  __typename?: 'CrewPerFlight';
  callSignPopularity?: Maybe<Array<Maybe<CallSignPopularity>>>;
  driverLicense?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['EmailAddress']>;
  emailNotifications?: Maybe<Scalars['EmailAddress']>;
  groundCrew?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  language?: Maybe<ContactLanguage>;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  licenseExtraCrew?: Maybe<Scalars['Boolean']>;
  licenseGas?: Maybe<Scalars['Boolean']>;
  licenseGroupA?: Maybe<Scalars['Boolean']>;
  licenseGroupB?: Maybe<Scalars['Boolean']>;
  licenseGroupC?: Maybe<Scalars['Boolean']>;
  licenseGroupD?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  medicalExpiryDate?: Maybe<Scalars['DateTime']>;
  medicalWeight?: Maybe<Scalars['PositiveInt']>;
  mostPopular?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  pilotLicense?: Maybe<PilotLicense>;
  proficiencyLatestBpl?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightA?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightB?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightC?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightD?: Maybe<Scalars['DateTime']>;
  proficiencyLatestGas?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  userRole?: Maybe<UserRole>;
  isCurrent?: Maybe<Scalars['Int']>;
  isPilot?: Maybe<Scalars['Boolean']>;
  totalWeight?: Maybe<Scalars['PositiveInt']>;
  availability?: Maybe<CrewAvailability>;
  otherFlight?: Maybe<Flight>;
  popularity?: Maybe<Scalars['NonNegativeInt']>;
  status?: Maybe<CrewStatus>;
};

export enum CrewStatus {
  Unk = 'UNK',
  Con = 'CON',
  Inv = 'INV',
  Dec = 'DEC'
}


export type Cylinder = {
  __typename?: 'Cylinder';
  emptyWeight?: Maybe<Scalars['PositiveInt']>;
  fullWeight?: Maybe<Scalars['PositiveInt']>;
  id: Scalars['ID'];
  manufacturer?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  removed?: Maybe<Scalars['DateTime']>;
  serialNumber?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['PositiveInt']>;
};

export type CylinderInput = {
  emptyWeight?: Maybe<Scalars['PositiveInt']>;
  fullWeight?: Maybe<Scalars['PositiveInt']>;
  manufacturer?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['PositiveInt']>;
};

export type DashboardOptions = {
  __typename?: 'DashboardOptions';
  toConfirm?: Maybe<Scalars['Boolean']>;
  preferredDateToday?: Maybe<Scalars['Boolean']>;
  recent?: Maybe<Scalars['NonNegativeInt']>;
};


export type DatePart = {
  __typename?: 'DatePart';
  part?: Maybe<DatePartType>;
  text?: Maybe<Scalars['String']>;
};

export enum DatePartType {
  Sunrise = 'SUNRISE',
  Sunset = 'SUNSET'
}


export type ElevationResult = {
  __typename?: 'ElevationResult';
  elevation: Scalars['Float'];
  location?: Maybe<Mapslocation>;
  resolution?: Maybe<Scalars['Float']>;
};

export type Email = {
  __typename?: 'Email';
  attachments: Array<Maybe<EmailAttachment>>;
  bookingId?: Maybe<Scalars['ID']>;
  events?: Maybe<Array<Maybe<EmailEvent>>>;
  flightId?: Maybe<Scalars['ID']>;
  from?: Maybe<EmailAddressObject>;
  html?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  messageId?: Maybe<Scalars['String']>;
  replyTo?: Maybe<EmailAddressObject>;
  result?: Maybe<Scalars['String']>;
  resultAt?: Maybe<Scalars['DateTime']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};


export type EmailAddressInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EmailAddressObject = {
  __typename?: 'EmailAddressObject';
  email?: Maybe<Scalars['EmailAddress']>;
  name?: Maybe<Scalars['String']>;
};

export type EmailAttachment = {
  __typename?: 'EmailAttachment';
  filename?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type EmailAttachmentInput = {
  filename?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type EmailEvent = {
  __typename?: 'EmailEvent';
  attempt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  sg_content_type?: Maybe<Scalars['String']>;
  sg_event_id?: Maybe<Scalars['String']>;
  sg_machine_open?: Maybe<Scalars['String']>;
  sg_message_id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['URL']>;
  useragent?: Maybe<Scalars['String']>;
};

export type EmailInput = {
  flightId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  crewId?: Maybe<Scalars['String']>;
  from?: Maybe<EmailAddressInput>;
  replyTo?: Maybe<EmailAddressInput>;
  to?: Maybe<Scalars['String']>;
  cc?: Maybe<Array<Maybe<EmailAddressInput>>>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  attachments: Array<Maybe<EmailAttachmentInput>>;
  contactName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type EmailLanguages = {
  __typename?: 'EmailLanguages';
  en?: Maybe<EmailTemplateByLanguage>;
  fr?: Maybe<EmailTemplateByLanguage>;
  nl?: Maybe<EmailTemplateByLanguage>;
  de?: Maybe<EmailTemplateByLanguage>;
};

export type EmailLanguagesInput = {
  en?: Maybe<EmailTemplateByLanguageInput>;
  fr?: Maybe<EmailTemplateByLanguageInput>;
  nl?: Maybe<EmailTemplateByLanguageInput>;
  de?: Maybe<EmailTemplateByLanguageInput>;
};

export type EmailSettingsTemplate = {
  __typename?: 'EmailSettingsTemplate';
  id: Scalars['ID'];
  template?: Maybe<EmailLanguages>;
  type?: Maybe<EmailTemplateType>;
  title?: Maybe<Scalars['String']>;
};

export type EmailTemplateByLanguage = {
  __typename?: 'EmailTemplateByLanguage';
  body: Scalars['String'];
  subject: Scalars['String'];
  attachments?: Maybe<Array<Maybe<EmailAttachment>>>;
};

export type EmailTemplateByLanguageInput = {
  body: Scalars['String'];
  subject: Scalars['String'];
  attachments?: Maybe<Array<Maybe<EmailAttachmentInput>>>;
};

export type EmailTemplateInput = {
  template?: Maybe<EmailLanguagesInput>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum EmailTemplateType {
  CrewOtpEmail = 'crewOtpEmail',
  PilotFlightEmail = 'pilotFlightEmail',
  CrewFlightEmail = 'crewFlightEmail',
  BookingConfirmEmail = 'bookingConfirmEmail',
  BookingFlightConfirmEmail = 'bookingFlightConfirmEmail',
  BookingFlightCancelEmail = 'bookingFlightCancelEmail',
  LocationContactEmail = 'locationContactEmail'
}

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  removed?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EventType>;
  availability?: Maybe<CrewAvailability>;
  period?: Maybe<FlightPeriod>;
  crew?: Maybe<Crew>;
};

export type EventData = {
  __typename?: 'EventData';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
};

export type EventInput = {
  description: Scalars['String'];
  date: Scalars['DateTime'];
  type?: Maybe<EventType>;
  crewId?: Maybe<Scalars['ID']>;
};

export enum EventType {
  Opr = 'OPR',
  Cre = 'CRE'
}

export type FilterRange = {
  __typename?: 'FilterRange';
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type FilterRangeInput = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type Flight = {
  __typename?: 'Flight';
  _created?: Maybe<Scalars['DateTime']>;
  _modified?: Maybe<Scalars['DateTime']>;
  api?: Maybe<FlightApi>;
  balloon?: Maybe<Balloon>;
  bookings: FlightBookings;
  bookingsData?: Maybe<BookingsData>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  events: Array<Maybe<Event>>;
  eventsData?: Maybe<Array<Maybe<EventData>>>;
  flightTime?: Maybe<Scalars['PositiveInt']>;
  flightDistance?: Maybe<Scalars['Float']>;
  flightHeading?: Maybe<Scalars['Float']>;
  flightsAtSameDay: FlightsAtSameDay;
  flightDuplicates?: Maybe<Array<Maybe<Flight>>>;
  meteo?: Maybe<MeteoblueForecastSlot>;
  groundCrews: Array<Maybe<CrewInFlight>>;
  hour?: Maybe<Scalars['DateTime']>;
  hourMeeting?: Maybe<Scalars['DateTime']>;
  icon: Icon;
  id: Scalars['ID'];
  landingLocation?: Maybe<Scalars['String']>;
  landingTime?: Maybe<Scalars['DateTime']>;
  landingDetails?: Maybe<Scalars['String']>;
  landingLatitude?: Maybe<Scalars['Float']>;
  landingLongitude?: Maybe<Scalars['Float']>;
  liftAvailable?: Maybe<Scalars['Float']>;
  liftDataMismatch?: Maybe<Array<Maybe<Scalars['String']>>>;
  liftNeeded?: Maybe<Scalars['Float']>;
  liftPassengers?: Maybe<Scalars['Float']>;
  liftSpare?: Maybe<Scalars['Float']>;
  location?: Maybe<Location>;
  locationType?: Maybe<LocationType>;
  locationEmail?: Maybe<MailEventLatest>;
  locationSmsLatest?: Maybe<SmsLatest>;
  logs?: Maybe<Array<Maybe<BookingLog>>>;
  meetingPointSameAsTakeOff?: Maybe<Scalars['Boolean']>;
  meetingPointAddress?: Maybe<Scalars['String']>;
  meetingPointLatitude?: Maybe<Scalars['Float']>;
  meetingPointLongitude?: Maybe<Scalars['Float']>;
  meetingPointURL?: Maybe<Scalars['String']>;
  meetingPointCity?: Maybe<Scalars['String']>;
  maximumFlightTime?: Maybe<Scalars['PositiveInt']>;
  maximumHeight?: Maybe<Scalars['PositiveInt']>;
  passengerCounters: FlightPassengerCounters;
  passengersCount?: Maybe<Scalars['Int']>;
  passengersConfirmed?: Maybe<Scalars['Int']>;
  passengersData?: Maybe<PassengerData>;
  passengersOccupancy?: Maybe<Scalars['NonNegativeInt']>;
  period?: Maybe<FlightPeriod>;
  pilot?: Maybe<CrewInFlight>;
  pilotActionNeeded?: Maybe<Scalars['Boolean']>;
  pilotComments?: Maybe<Scalars['String']>;
  pilotSignature?: Maybe<Scalars['String']>;
  pilotSignoff?: Maybe<Scalars['String']>;
  pilotSignOffDate?: Maybe<Scalars['DateTime']>;
  pilotStatus?: Maybe<CrewStatus>;
  plannedFlightTime?: Maybe<Scalars['PositiveInt']>;
  refuelVolume?: Maybe<Scalars['PositiveInt']>;
  removed?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  spareFlightTime?: Maybe<Scalars['Int']>;
  status?: Maybe<FlightStatus>;
  skeyesForecast?: Maybe<FlightSkeyesForecast>;
  sunriseSunset?: Maybe<SunriseSunset>;
  takeoffTime?: Maybe<Scalars['DateTime']>;
  tempBalloon?: Maybe<Scalars['PositiveInt']>;
  tempMax?: Maybe<Scalars['Int']>;
  tempTakeoff?: Maybe<Scalars['Int']>;
  trajectory?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectoryCustom?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory1000?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory950?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory925?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory800?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory700?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory600?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory500?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory400?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory300?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory200?: Maybe<Array<Maybe<Mapslocation>>>;
  vfrClouds?: Maybe<Scalars['Int']>;
  vfrVisual?: Maybe<Scalars['Int']>;
  visibility?: Maybe<VisibilityType>;
  weatherForecastDate?: Maybe<Scalars['DateTime']>;
  weatherPmax?: Maybe<Scalars['Int']>;
  weatherQnh?: Maybe<Scalars['Int']>;
  weatherSource?: Maybe<Scalars['String']>;
  wind10?: Maybe<Scalars['Int']>;
  wind10Direction?: Maybe<Scalars['Int']>;
  wind20?: Maybe<Scalars['Int']>;
  wind20Direction?: Maybe<Scalars['Int']>;
  windGl?: Maybe<Scalars['Int']>;
  windGlDirection?: Maybe<Scalars['Int']>;
  windSummary?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  tagsData?: Maybe<Array<Maybe<TagData>>>;
  upcoming?: Maybe<Scalars['Boolean']>;
};

export type FlightApi = {
  __typename?: 'FlightApi';
  balloonCapacity?: Maybe<Scalars['NonNegativeInt']>;
  balloonName?: Maybe<Scalars['String']>;
  confirmedPassengers?: Maybe<Scalars['NonNegativeInt']>;
  toConfirmPassengers?: Maybe<Scalars['NonNegativeInt']>;
  flightDate?: Maybe<Scalars['String']>;
  flightHour?: Maybe<Scalars['String']>;
  flightId?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['NonNegativeInt']>;
  flightPeriod?: Maybe<Scalars['String']>;
  freeCapacity?: Maybe<Scalars['NonNegativeInt']>;
  locationAddress?: Maybe<Scalars['String']>;
  locationCity?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionNumber?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
};

export type FlightBookings = {
  __typename?: 'FlightBookings';
  id: Scalars['ID'];
  list: Array<Maybe<Booking>>;
  count: Scalars['NonNegativeInt'];
  addedCount: Scalars['NonNegativeInt'];
  notConfirmedCount: Scalars['NonNegativeInt'];
  notConfirmedNames: Scalars['String'];
};

export type FlightCounters = {
  __typename?: 'FlightCounters';
  upcoming?: Maybe<Scalars['NonNegativeInt']>;
  historical?: Maybe<Scalars['NonNegativeInt']>;
  scheduled?: Maybe<Scalars['NonNegativeInt']>;
  statusCounters?: Maybe<Array<Maybe<StatusCounter>>>;
};

export type FlightCrewInput = {
  id?: Maybe<Scalars['ID']>;
  isPilot?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CrewStatus>;
};

export type FlightInput = {
  balloon?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  hour?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['ID']>;
  locationType?: Maybe<LocationType>;
  meetingPointSameAsTakeOff?: Maybe<Scalars['Boolean']>;
  meetingPointAddress?: Maybe<Scalars['String']>;
  meetingPointLatitude?: Maybe<Scalars['Float']>;
  meetingPointLongitude?: Maybe<Scalars['Float']>;
  meetingPointURL?: Maybe<Scalars['String']>;
  meetingPointCity?: Maybe<Scalars['String']>;
  maximumHeight?: Maybe<Scalars['PositiveInt']>;
  period?: Maybe<FlightPeriod>;
  plannedFlightTime?: Maybe<Scalars['PositiveInt']>;
  tempBalloon?: Maybe<Scalars['PositiveInt']>;
  tempTakeoff?: Maybe<Scalars['Int']>;
  vfrClouds?: Maybe<Scalars['Int']>;
  vfrVisual?: Maybe<Scalars['Int']>;
  visibility?: Maybe<VisibilityType>;
  weatherForecastDate?: Maybe<Scalars['DateTime']>;
  weatherQnh?: Maybe<Scalars['Int']>;
  weatherSource?: Maybe<Scalars['String']>;
  wind10?: Maybe<Scalars['Int']>;
  wind10Direction?: Maybe<Scalars['Int']>;
  wind20?: Maybe<Scalars['Int']>;
  wind20Direction?: Maybe<Scalars['Int']>;
  windGl?: Maybe<Scalars['Int']>;
  windGlDirection?: Maybe<Scalars['Int']>;
  windSummary?: Maybe<Scalars['String']>;
  refuelVolume?: Maybe<Scalars['PositiveInt']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FlightPassengerCounters = {
  __typename?: 'FlightPassengerCounters';
  id: Scalars['ID'];
  freeSpots: Scalars['NonNegativeInt'];
  occupancy: Scalars['NonNegativeInt'];
  passengersCount: Scalars['NonNegativeInt'];
  passengersCountAdded: Scalars['NonNegativeInt'];
  passengersCountConfirmed: Scalars['NonNegativeInt'];
  passengersCountInvited: Scalars['NonNegativeInt'];
  passengersWeight: Scalars['NonNegativeInt'];
};

export enum FlightPeriod {
  Mor = 'MOR',
  Eve = 'EVE'
}

export type FlightPeriodCounters = {
  __typename?: 'FlightPeriodCounters';
  flightsCount: Scalars['NonNegativeInt'];
  passengersCount: Scalars['NonNegativeInt'];
  passengersCountAdded: Scalars['NonNegativeInt'];
  passengersCountInvited: Scalars['NonNegativeInt'];
  passengersCountConfirmed: Scalars['NonNegativeInt'];
  freeSpots: Scalars['NonNegativeInt'];
};

export type FlightPeriodCountersPerPeriod = {
  __typename?: 'FlightPeriodCountersPerPeriod';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  MOR?: Maybe<FlightPeriodCounters>;
  EVE?: Maybe<FlightPeriodCounters>;
};

export type FlightPeriods = {
  __typename?: 'FlightPeriods';
  month?: Maybe<Array<Maybe<FlightPeriodCountersPerPeriod>>>;
  week?: Maybe<Array<Maybe<FlightPeriodCountersPerPeriod>>>;
  day?: Maybe<Array<Maybe<FlightPeriodCountersPerPeriod>>>;
};

export type FlightSkeyesForecast = {
  __typename?: 'FlightSkeyesForecast';
  username?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  forecast?: Maybe<SkeyesForecast>;
};

export enum FlightStatus {
  Sch = 'SCH',
  Rea = 'REA',
  Can = 'CAN',
  Fin = 'FIN'
}

export type FlightsAtSameDay = {
  __typename?: 'FlightsAtSameDay';
  flights: Array<Maybe<Flight>>;
  count: Scalars['NonNegativeInt'];
  names: Scalars['String'];
};

export type GcpipUser = {
  __typename?: 'GCPIPUser';
  uid: Scalars['ID'];
  email: Scalars['String'];
  accessToken: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  customClaims?: Maybe<Scalars['JSON']>;
  userData?: Maybe<User>;
};


export type GcpipUserInput = {
  email: Scalars['String'];
  role?: Maybe<UserRole>;
  displayName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type GeocodeResult = {
  __typename?: 'GeocodeResult';
  address_components?: Maybe<Array<Maybe<AddressComponent>>>;
  formatted_address?: Maybe<Scalars['String']>;
  geometry?: Maybe<Geometry>;
  place_id?: Maybe<Scalars['String']>;
  partial_match?: Maybe<Scalars['Boolean']>;
  plus_code?: Maybe<Pluscode>;
  elevation?: Maybe<Array<Maybe<ElevationResult>>>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Geometry = {
  __typename?: 'Geometry';
  location?: Maybe<Mapslocation>;
  location_type?: Maybe<Scalars['String']>;
  viewport?: Maybe<Viewport>;
};






export type ICrew = {
  callSignPopularity?: Maybe<Array<Maybe<CallSignPopularity>>>;
  driverLicense?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['EmailAddress']>;
  emailNotifications?: Maybe<Scalars['EmailAddress']>;
  groundCrew?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  language?: Maybe<ContactLanguage>;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  licenseExtraCrew?: Maybe<Scalars['Boolean']>;
  licenseGas?: Maybe<Scalars['Boolean']>;
  licenseGroupA?: Maybe<Scalars['Boolean']>;
  licenseGroupB?: Maybe<Scalars['Boolean']>;
  licenseGroupC?: Maybe<Scalars['Boolean']>;
  licenseGroupD?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  medicalExpiryDate?: Maybe<Scalars['DateTime']>;
  medicalWeight?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  pilotLicense?: Maybe<PilotLicense>;
  proficiencyLatestBpl?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightA?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightB?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightC?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightD?: Maybe<Scalars['DateTime']>;
  proficiencyLatestGas?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  userRole?: Maybe<UserRole>;
};




export type Icon = {
  __typename?: 'Icon';
  sign: Scalars['String'];
  color: Scalars['String'];
};

export type InputLocationOptions = {
  defaultLocationId?: Maybe<Scalars['String']>;
};

export type InputOperatorSettingsBackup = {
  active: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
};

export type InputOperatorSettingsCrew = {
  shareBookings?: Maybe<Scalars['Boolean']>;
  allowFlightSelection?: Maybe<Scalars['Boolean']>;
};

export type InputOperatorSettingsFlights = {
  sunriseOffset?: Maybe<Scalars['NonNegativeInt']>;
  sunsetOffset?: Maybe<Scalars['NonNegativeInt']>;
  meetingTimeOffset?: Maybe<Scalars['NonNegativeInt']>;
};

export type InputOperatorSettingsMyFlight = {
  autoConfirm?: Maybe<Scalars['Boolean']>;
  flights?: Maybe<MyflightFlights>;
  endOfSeason?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputOperatorSettingsSms = {
  footerEnabled?: Maybe<Scalars['Boolean']>;
};

export type InputOperatorSettingsTrajectoryProfile = {
  duration?: Maybe<Scalars['NonNegativeInt']>;
  level?: Maybe<Scalars['NonNegativeInt']>;
};

export type InputReleaseNote = {
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  documentation?: Maybe<Scalars['String']>;
  oneliner?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};



export type LandingInput = {
  takeoffTime?: Maybe<Scalars['DateTime']>;
  landingTime?: Maybe<Scalars['DateTime']>;
  landingLocation?: Maybe<Scalars['String']>;
  landingDetails?: Maybe<Scalars['String']>;
  landingLatitude?: Maybe<Scalars['Float']>;
  landingLongitude?: Maybe<Scalars['Float']>;
  refuelVolume?: Maybe<Scalars['PositiveInt']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact?: Maybe<LocationContact>;
  country?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  forecasts?: Maybe<Array<Maybe<MeteoblueForecastSlot>>>;
  height?: Maybe<Scalars['PositiveInt']>;
  id: Scalars['ID'];
  isHotAirfield?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maximumHeight?: Maybe<Scalars['Float']>;
  meetingPointSameAsTakeOff?: Maybe<Scalars['Boolean']>;
  meetingPointAddress?: Maybe<Scalars['String']>;
  meetingPointLatitude?: Maybe<Scalars['Float']>;
  meetingPointLongitude?: Maybe<Scalars['Float']>;
  meetingPointURL?: Maybe<Scalars['String']>;
  meetingPointCity?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  postalCode?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  region: Array<Maybe<Region>>;
  removed?: Maybe<Scalars['DateTime']>;
};

export type LocationContact = {
  __typename?: 'LocationContact';
  comment?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  language?: Maybe<ContactLanguage>;
  smsTemplate?: Maybe<Scalars['ID']>;
  media?: Maybe<LocationContactMedia>;
};

export type LocationContactInput = {
  comment?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  language?: Maybe<ContactLanguage>;
  smsTemplate?: Maybe<Scalars['ID']>;
  media?: Maybe<LocationContactMedia>;
};

export enum LocationContactMedia {
  Mail = 'MAIL',
  Sms = 'SMS',
  Nomsg = 'NOMSG'
}

export type LocationInput = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact?: Maybe<LocationContactInput>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['PositiveInt']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maximumHeight?: Maybe<Scalars['Float']>;
  meetingPointSameAsTakeOff?: Maybe<Scalars['Boolean']>;
  meetingPointAddress?: Maybe<Scalars['String']>;
  meetingPointLatitude?: Maybe<Scalars['Float']>;
  meetingPointLongitude?: Maybe<Scalars['Float']>;
  meetingPointURL?: Maybe<Scalars['String']>;
  meetingPointCity?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type LocationOptions = {
  __typename?: 'LocationOptions';
  defaultLocation?: Maybe<Location>;
};

export enum LocationType {
  Regi = 'REGI',
  Fixe = 'FIXE',
  Priv = 'PRIV'
}



export type MlText = {
  __typename?: 'MLText';
  lang?: Maybe<ContactLanguage>;
  text?: Maybe<Scalars['String']>;
};

export type MlTextInput = {
  lang?: Maybe<ContactLanguage>;
  text?: Maybe<Scalars['String']>;
};

export type MailEventLatest = {
  __typename?: 'MailEventLatest';
  date?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Scalars['String']>;
};

export type Manufacturers = {
  __typename?: 'Manufacturers';
  envelopeManufacturers: Array<Maybe<Scalars['String']>>;
  basketManufacturers: Array<Maybe<Scalars['String']>>;
  burnerManufacturers: Array<Maybe<Scalars['String']>>;
  cylinderManufacturers: Array<Maybe<Scalars['String']>>;
};

export type Mapslocation = {
  __typename?: 'Mapslocation';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  alt?: Maybe<Scalars['NonNegativeInt']>;
};

export type MeteoblueForecast = {
  __typename?: 'MeteoblueForecast';
  _created?: Maybe<Scalars['DateTime']>;
  profilewind?: Maybe<MeteoblueProfilewind>;
  trendpro?: Maybe<MeteoblueTrendpro>;
  daytime?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  flytime?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  datepart?: Maybe<Array<Maybe<DatePart>>>;
};

export type MeteoblueForecastSlot = {
  __typename?: 'MeteoblueForecastSlot';
  forecast?: Maybe<SlotForecast>;
  slot?: Maybe<Scalars['String']>;
  tempTakeoff?: Maybe<Scalars['Int']>;
  vfrVisual?: Maybe<Scalars['Int']>;
  weatherLocationId?: Maybe<Scalars['String']>;
  weatherQnh?: Maybe<Scalars['Int']>;
  wind10?: Maybe<Scalars['Int']>;
  wind10Direction?: Maybe<Scalars['Int']>;
  wind20?: Maybe<Scalars['Int']>;
  wind20Direction?: Maybe<Scalars['Int']>;
  windGl?: Maybe<Scalars['Int']>;
  windGlDirection?: Maybe<Scalars['Int']>;
};

export type MeteoblueProfileWindData = {
  __typename?: 'MeteoblueProfileWindData';
  time?: Maybe<Array<Maybe<Scalars['String']>>>;
  winddirectionprofile1000_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile200_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile250_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile300_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile350_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile400_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile450_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile500_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile550_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile600_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile650_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile700_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile750_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile800_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile850_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile875_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile900_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile925_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile950_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile975_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_1000_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_200_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_250_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_300_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_350_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_400_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_450_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_500_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_550_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_600_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_650_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_700_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_750_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_800_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_850_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_875_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_900_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_925_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_950_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_975_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type MeteoblueProfilewind = {
  __typename?: 'MeteoblueProfilewind';
  data_1h?: Maybe<MeteoblueProfileWindData>;
  metadata?: Maybe<Scalars['JSON']>;
  units?: Maybe<Scalars['JSON']>;
};

export type MeteoblueTrendpro = {
  __typename?: 'MeteoblueTrendpro';
  metadata?: Maybe<Scalars['JSON']>;
  trend_1h?: Maybe<MeteoblueTrendproData>;
  units?: Maybe<Scalars['JSON']>;
};

export type MeteoblueTrendproData = {
  __typename?: 'MeteoblueTrendproData';
  cape?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dewpointtemperature?: Maybe<Array<Maybe<Scalars['Float']>>>;
  evapotranspiration?: Maybe<Array<Maybe<Scalars['Float']>>>;
  extraterrestrialradiation_backwards?: Maybe<Array<Maybe<Scalars['Float']>>>;
  ghi_backwards?: Maybe<Array<Maybe<Scalars['Float']>>>;
  gust?: Maybe<Array<Maybe<Scalars['Float']>>>;
  hiclouds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  liftedindex?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lowclouds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  midclouds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  pictocode?: Maybe<Array<Maybe<Scalars['Int']>>>;
  precipitation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  precipitation_probability?: Maybe<Array<Maybe<Scalars['Int']>>>;
  precipitation_spread?: Maybe<Array<Maybe<Scalars['Float']>>>;
  referenceevapotranspiration_fao?: Maybe<Array<Maybe<Scalars['Float']>>>;
  relativehumidity?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sealevelpressure?: Maybe<Array<Maybe<Scalars['Int']>>>;
  skintemperature?: Maybe<Array<Maybe<Scalars['Float']>>>;
  snowfraction?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sunshinetime?: Maybe<Array<Maybe<Scalars['Int']>>>;
  temperature?: Maybe<Array<Maybe<Scalars['Float']>>>;
  temperature_spread?: Maybe<Array<Maybe<Scalars['Float']>>>;
  time?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalcloudcover?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalcloudcover_spread?: Maybe<Array<Maybe<Scalars['Int']>>>;
  visibility?: Maybe<Array<Maybe<Scalars['Int']>>>;
  winddirection?: Maybe<Array<Maybe<Scalars['Int']>>>;
  windspeed?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeed_spread?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAndConfirmBookingsToFlight?: Maybe<Scalars['NonNegativeInt']>;
  addBookingToMyFlight: Scalars['ID'];
  addBookingsToFlight?: Maybe<Scalars['NonNegativeInt']>;
  addCrewToFlightFromCalendar: Scalars['ID'];
  addEmailOut: Scalars['ID'];
  addEmailTemplateLocationContact?: Maybe<EmailSettingsTemplate>;
  addPassengers?: Maybe<Scalars['Int']>;
  addReleaseNote?: Maybe<ReleaseNote>;
  addSmsTemplateLocationContact?: Maybe<SmsTemplate>;
  changeFlightStatus: Flight;
  confirmBookingToFlight: Scalars['ID'];
  confirmBookingToMyFlight: Scalars['ID'];
  copyEmailTemplate?: Maybe<EmailSettingsTemplate>;
  copyFlight: Scalars['ID'];
  copyWeather: Scalars['ID'];
  createBooking: Scalars['ID'];
  createBookingFromVoucher: Scalars['ID'];
  createIcsId?: Maybe<Scalars['String']>;
  createTenant: Tenant;
  createUser: GcpipUser;
  create_booking_tenant?: Maybe<Scalars['JSONObject']>;
  create_voucher_tenant?: Maybe<Scalars['JSONObject']>;
  deleteBalloon: Scalars['ID'];
  deleteBooking: Scalars['ID'];
  deleteCategory: Scalars['ID'];
  deleteCrew: Scalars['ID'];
  deleteCylinder: Scalars['ID'];
  deleteEmailTemplate: Scalars['ID'];
  deleteEvent: Scalars['ID'];
  deleteFlight: Scalars['ID'];
  deleteLocation: Scalars['ID'];
  deleteLog: Scalars['ID'];
  deleteMonthAvailabilityEvents?: Maybe<Scalars['ID']>;
  deletePassenger: Scalars['ID'];
  deleteQuestion: Scalars['ID'];
  deleteRegion: Scalars['ID'];
  deleteReleaseNote?: Maybe<Scalars['Boolean']>;
  deleteTag: Scalars['ID'];
  deleteUser: Scalars['ID'];
  deleteVoucher: Scalars['ID'];
  fetchHolidays?: Maybe<Scalars['PositiveInt']>;
  initializeTenant?: Maybe<Scalars['String']>;
  inviteBookingsToFlight?: Maybe<Scalars['NonNegativeInt']>;
  markBookingMessagesRead?: Maybe<Scalars['ID']>;
  markBookingPaid: Scalars['ID'];
  markCrewMessagesRead?: Maybe<Scalars['ID']>;
  markReleaseNoteAsRead?: Maybe<Scalars['NonNegativeInt']>;
  mergeBookings?: Maybe<Booking>;
  rejectBookingToMyFlight: Scalars['ID'];
  removeBookingFromFlight: Scalars['ID'];
  saveBalloon: Scalars['ID'];
  saveBooking: Scalars['ID'];
  saveBookingPayment: Booking;
  saveCategory: Scalars['ID'];
  saveCrew: Scalars['ID'];
  saveCrewAvailability?: Maybe<Scalars['ID']>;
  saveCylinder: Scalars['ID'];
  saveEmailTemplate?: Maybe<EmailSettingsTemplate>;
  saveEvent: Scalars['ID'];
  saveFlight: Scalars['ID'];
  saveFlightCrew: Flight;
  saveFlightLanding: Scalars['ID'];
  saveLanguageSetting: Scalars['ID'];
  saveLocation: Scalars['ID'];
  savePassenger: Scalars['ID'];
  savePassengerPayment?: Maybe<Passenger>;
  savePilotSignOff: Scalars['ID'];
  saveQuestionOrder: Scalars['ID'];
  saveQuestionText: Scalars['ID'];
  saveRegion: Scalars['ID'];
  saveSmsTemplate?: Maybe<SmsTemplate>;
  saveTag: Tag;
  saveUserViewState: User;
  saveVoucher: Scalars['ID'];
  sendBookingSms?: Maybe<Scalars['ID']>;
  sendCrewSms?: Maybe<Scalars['ID']>;
  sendFlightSms?: Maybe<Scalars['ID']>;
  sendLocationSms?: Maybe<Scalars['ID']>;
  sendPasswordResetEmail: Scalars['String'];
  setMonthAvailability?: Maybe<Scalars['ID']>;
  subscriptionSmsIgnoreWarning?: Maybe<Scalars['Boolean']>;
  toggleFlightVisibility: Flight;
  toggleQuestionActive: Scalars['ID'];
  toggleTenantOperatorSettingsBookingsShowPayments?: Maybe<TenantSetting>;
  updateBookingComments: Scalars['ID'];
  updateBookingPayment?: Maybe<Booking>;
  updateBookingPreferredFlights: Scalars['ID'];
  updateBookingTags: Scalars['ID'];
  updateFlightComments: Flight;
  updateFlightTags: Flight;
  updateMeteo?: Maybe<Scalars['Boolean']>;
  updateMyBooking?: Maybe<Scalars['ID']>;
  updateMyPassenger: Scalars['ID'];
  updatePassengerPaymentStatus?: Maybe<Passenger>;
  updateReleaseNote?: Maybe<ReleaseNote>;
  updateTenantLocationOptions?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsBackup?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsBookingsChildAge?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsCrew?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsDisabledPaymentTypes?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsFlights?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsMyFlight?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsSms?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsTrajectoryProfile?: Maybe<TenantSetting>;
  update_booking_flight_tenant?: Maybe<Scalars['JSONObject']>;
};


export type MutationAddAndConfirmBookingsToFlightArgs = {
  bookings: Array<Maybe<Scalars['ID']>>;
  flightId: Scalars['ID'];
};


export type MutationAddBookingToMyFlightArgs = {
  myFlightId: Scalars['ID'];
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationAddBookingsToFlightArgs = {
  bookings: Array<Maybe<Scalars['ID']>>;
  flightId: Scalars['ID'];
};


export type MutationAddCrewToFlightFromCalendarArgs = {
  flightCrewInput?: Maybe<FlightCrewInput>;
  flightId: Scalars['ID'];
};


export type MutationAddEmailOutArgs = {
  emailInput: EmailInput;
};


export type MutationAddPassengersArgs = {
  bookingId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
};


export type MutationAddReleaseNoteArgs = {
  releaseNote: InputReleaseNote;
};


export type MutationChangeFlightStatusArgs = {
  flightId: Scalars['ID'];
  flightStatus: FlightStatus;
};


export type MutationConfirmBookingToFlightArgs = {
  bookingId: Scalars['ID'];
};


export type MutationConfirmBookingToMyFlightArgs = {
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationCopyEmailTemplateArgs = {
  emailTemplateId: Scalars['ID'];
};


export type MutationCopyFlightArgs = {
  sourceFlightId: Scalars['ID'];
  destinationDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  timezoneOffset?: Maybe<Scalars['Int']>;
};


export type MutationCopyWeatherArgs = {
  sourceFlightId: Scalars['ID'];
  destinationFlightIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationCreateBookingArgs = {
  flightId: Scalars['ID'];
};


export type MutationCreateBookingFromVoucherArgs = {
  voucherId: Scalars['ID'];
};


export type MutationCreateTenantArgs = {
  displayName: Scalars['String'];
};


export type MutationCreateUserArgs = {
  GcpipUserInput?: Maybe<GcpipUserInput>;
  tenantId?: Maybe<Scalars['ID']>;
};


export type MutationCreate_Booking_TenantArgs = {
  bookingInput?: Maybe<ApiBookingInput>;
};


export type MutationCreate_Voucher_TenantArgs = {
  voucherInput?: Maybe<ApiVoucherInput>;
};


export type MutationDeleteBalloonArgs = {
  balloonId: Scalars['ID'];
};


export type MutationDeleteBookingArgs = {
  bookingId: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type MutationDeleteCrewArgs = {
  crewId: Scalars['ID'];
};


export type MutationDeleteCylinderArgs = {
  cylinderId: Scalars['ID'];
};


export type MutationDeleteEmailTemplateArgs = {
  emailTemplateId: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['ID'];
};


export type MutationDeleteFlightArgs = {
  flightId: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  locationId: Scalars['ID'];
};


export type MutationDeleteLogArgs = {
  bookingId: Scalars['ID'];
  logId: Scalars['ID'];
};


export type MutationDeleteMonthAvailabilityEventsArgs = {
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  crewId: Scalars['ID'];
};


export type MutationDeletePassengerArgs = {
  passengerId: Scalars['ID'];
  bookingId: Scalars['ID'];
};


export type MutationDeleteQuestionArgs = {
  questionId: Scalars['ID'];
};


export type MutationDeleteRegionArgs = {
  regionId: Scalars['ID'];
};


export type MutationDeleteReleaseNoteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTagArgs = {
  tagId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  uid: Scalars['String'];
  tenantId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteVoucherArgs = {
  voucherId: Scalars['ID'];
};


export type MutationFetchHolidaysArgs = {
  year: Scalars['PositiveInt'];
  countryCode?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationInitializeTenantArgs = {
  tenantId: Scalars['ID'];
};


export type MutationInviteBookingsToFlightArgs = {
  bookings: Array<Maybe<Scalars['ID']>>;
  flightId: Scalars['ID'];
};


export type MutationMarkBookingMessagesReadArgs = {
  bookingId: Scalars['ID'];
};


export type MutationMarkBookingPaidArgs = {
  bookingId: Scalars['ID'];
};


export type MutationMarkCrewMessagesReadArgs = {
  crewId: Scalars['ID'];
};


export type MutationMarkReleaseNoteAsReadArgs = {
  releaseNoteIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationMergeBookingsArgs = {
  bookingIdFrom: Scalars['ID'];
  bookingIdTo: Scalars['ID'];
};


export type MutationRejectBookingToMyFlightArgs = {
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationRemoveBookingFromFlightArgs = {
  bookingId: Scalars['ID'];
};


export type MutationSaveBalloonArgs = {
  balloonInput: BalloonInput;
  cylinders: Array<Maybe<Scalars['ID']>>;
  balloonId?: Maybe<Scalars['ID']>;
};


export type MutationSaveBookingArgs = {
  bookingInput: BookingInput;
  bookingId?: Maybe<Scalars['ID']>;
  locationInput?: Maybe<LocationInput>;
};


export type MutationSaveBookingPaymentArgs = {
  bookingId: Scalars['ID'];
  bookingPaymentInput: BookingPaymentInput;
};


export type MutationSaveCategoryArgs = {
  categoryInput: CategoryInput;
  categoryId?: Maybe<Scalars['ID']>;
};


export type MutationSaveCrewArgs = {
  crewInput: CrewInput;
  crewId?: Maybe<Scalars['ID']>;
};


export type MutationSaveCrewAvailabilityArgs = {
  crewAvailabilityInput: CrewAvailabilityInput;
  eventId?: Maybe<Scalars['ID']>;
};


export type MutationSaveCylinderArgs = {
  cylinderInput: CylinderInput;
  cylinderId?: Maybe<Scalars['ID']>;
};


export type MutationSaveEmailTemplateArgs = {
  emailTemplateInput?: Maybe<EmailTemplateInput>;
  emailTemplateId: Scalars['ID'];
};


export type MutationSaveEventArgs = {
  eventInput: EventInput;
  eventId?: Maybe<Scalars['ID']>;
};


export type MutationSaveFlightArgs = {
  flightInput: FlightInput;
  flightId?: Maybe<Scalars['ID']>;
};


export type MutationSaveFlightCrewArgs = {
  flightCrewInput?: Maybe<Array<Maybe<FlightCrewInput>>>;
  flightId: Scalars['ID'];
};


export type MutationSaveFlightLandingArgs = {
  landingInput: LandingInput;
  flightId: Scalars['ID'];
};


export type MutationSaveLanguageSettingArgs = {
  systemLanguage: Scalars['String'];
};


export type MutationSaveLocationArgs = {
  locationInput: LocationInput;
  regions: Array<Maybe<Scalars['ID']>>;
  locationId?: Maybe<Scalars['ID']>;
};


export type MutationSavePassengerArgs = {
  passengerInput: PassengerInput;
  passengerId?: Maybe<Scalars['ID']>;
  bookingId: Scalars['ID'];
};


export type MutationSavePassengerPaymentArgs = {
  bookingId: Scalars['ID'];
  passengerId: Scalars['ID'];
  passengerPaymentInput: PassengerPaymentInput;
};


export type MutationSavePilotSignOffArgs = {
  pilotSignOffInput: PilotSignOffInput;
  flightId: Scalars['ID'];
};


export type MutationSaveQuestionOrderArgs = {
  order: Scalars['NonNegativeInt'];
  questionId: Scalars['ID'];
};


export type MutationSaveQuestionTextArgs = {
  questionInput: QuestionInput;
  questionId: Scalars['ID'];
};


export type MutationSaveRegionArgs = {
  regionInput: RegionInput;
  regionId?: Maybe<Scalars['ID']>;
};


export type MutationSaveSmsTemplateArgs = {
  smsTemplateInput: SmsTemplateInput;
  smsTemplateId: Scalars['ID'];
};


export type MutationSaveTagArgs = {
  tagInput: TagInput;
  tagId?: Maybe<Scalars['ID']>;
};


export type MutationSaveUserViewStateArgs = {
  viewState: Scalars['JSONObject'];
};


export type MutationSaveVoucherArgs = {
  voucherInput: VoucherInput;
  voucherId?: Maybe<Scalars['ID']>;
};


export type MutationSendBookingSmsArgs = {
  bookingId: Scalars['ID'];
  smsInput: SmsInput;
};


export type MutationSendCrewSmsArgs = {
  crewId: Scalars['ID'];
  smsInput: SmsInput;
};


export type MutationSendFlightSmsArgs = {
  flightId: Scalars['ID'];
  smsInput: SmsInput;
};


export type MutationSendLocationSmsArgs = {
  locationId: Scalars['ID'];
  flightId: Scalars['ID'];
  smsInput: SmsInput;
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['ID'];
  tenantId?: Maybe<Scalars['ID']>;
};


export type MutationSetMonthAvailabilityArgs = {
  availability: CrewAvailability;
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  crewId: Scalars['ID'];
};


export type MutationSubscriptionSmsIgnoreWarningArgs = {
  ignore: Scalars['Boolean'];
};


export type MutationToggleFlightVisibilityArgs = {
  flightId: Scalars['ID'];
};


export type MutationToggleQuestionActiveArgs = {
  questionId: Scalars['ID'];
};


export type MutationUpdateBookingCommentsArgs = {
  bookingId: Scalars['ID'];
  comments?: Maybe<Scalars['String']>;
};


export type MutationUpdateBookingPaymentArgs = {
  bookingId: Scalars['ID'];
  paymentType: PaymentType;
};


export type MutationUpdateBookingPreferredFlightsArgs = {
  bookingId: Scalars['ID'];
  preferredFlights?: Maybe<Array<Maybe<PreferredFlightInput>>>;
};


export type MutationUpdateBookingTagsArgs = {
  bookingId: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateFlightCommentsArgs = {
  flightId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationUpdateFlightTagsArgs = {
  flightId: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateMeteoArgs = {
  locationId: Scalars['ID'];
  flightId: Scalars['ID'];
};


export type MutationUpdateMyBookingArgs = {
  myBookingInput: MyBookingInput;
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationUpdateMyPassengerArgs = {
  myPassengerInput: MyPassengerInput;
  myPassengerId: Scalars['ID'];
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationUpdatePassengerPaymentStatusArgs = {
  bookingId: Scalars['ID'];
  passengerId: Scalars['ID'];
  paymentStatus: PaymentStatus;
};


export type MutationUpdateReleaseNoteArgs = {
  id: Scalars['ID'];
  releaseNote: InputReleaseNote;
};


export type MutationUpdateTenantLocationOptionsArgs = {
  options: InputLocationOptions;
};


export type MutationUpdateTenantOperatorSettingsBackupArgs = {
  settings: InputOperatorSettingsBackup;
};


export type MutationUpdateTenantOperatorSettingsBookingsChildAgeArgs = {
  childAge?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationUpdateTenantOperatorSettingsCrewArgs = {
  settings: InputOperatorSettingsCrew;
};


export type MutationUpdateTenantOperatorSettingsDisabledPaymentTypesArgs = {
  disabledPaymentTypes: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateTenantOperatorSettingsFlightsArgs = {
  settings: InputOperatorSettingsFlights;
};


export type MutationUpdateTenantOperatorSettingsMyFlightArgs = {
  settings: InputOperatorSettingsMyFlight;
};


export type MutationUpdateTenantOperatorSettingsSmsArgs = {
  settings: InputOperatorSettingsSms;
};


export type MutationUpdateTenantOperatorSettingsTrajectoryProfileArgs = {
  settings: Array<Maybe<InputOperatorSettingsTrajectoryProfile>>;
};


export type MutationUpdate_Booking_Flight_TenantArgs = {
  bookingId?: Maybe<Scalars['ID']>;
  flightId?: Maybe<Scalars['ID']>;
};

export type MyBookingInput = {
  contactEmail: Scalars['String'];
  contactLanguage?: Maybe<ContactLanguage>;
  contactName: Scalars['String'];
  contactPhone: Scalars['PhoneNumber'];
};

export type MyPassengerInput = {
  child?: Maybe<Scalars['Boolean']>;
  disability?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  luggage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['PositiveInt']>;
  age?: Maybe<Scalars['PositiveInt']>;
  transport?: Maybe<Scalars['Boolean']>;
};

export type Myflight = {
  __typename?: 'Myflight';
  booking?: Maybe<Booking>;
  flights?: Maybe<Array<Maybe<Flight>>>;
  regions?: Maybe<Array<Maybe<Region>>>;
  tenantSetting?: Maybe<TenantSetting>;
  messagesCollection?: Maybe<Scalars['String']>;
};

export type MyflightBooking = {
  __typename?: 'MyflightBooking';
  booking?: Maybe<Booking>;
  regions?: Maybe<Array<Maybe<Region>>>;
  tenantSetting?: Maybe<TenantSetting>;
  messagesCollection?: Maybe<Scalars['String']>;
};

export enum MyflightFlights {
  All = 'ALL',
  Regional = 'REGIONAL',
  None = 'NONE'
}








export type OperatorSettings = {
  __typename?: 'OperatorSettings';
  backup?: Maybe<OperatorSettingsBackup>;
  bookings?: Maybe<OperatorSettingsBookings>;
  flights?: Maybe<OperatorSettingsFlights>;
  crew?: Maybe<OperatorSettingsCrew>;
  currency?: Maybe<TenantCurrency>;
  manifest?: Maybe<OperatorSettingsManifest>;
  myflight?: Maybe<OperatorSettingsMyflight>;
  releasenotes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sms?: Maybe<OperatorSettingsSms>;
  timezone?: Maybe<Scalars['String']>;
  trajectoryProfile?: Maybe<Array<Maybe<TrajectoryProfile>>>;
};

export type OperatorSettingsBackup = {
  __typename?: 'OperatorSettingsBackup';
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
};

export type OperatorSettingsBookings = {
  __typename?: 'OperatorSettingsBookings';
  showPayments?: Maybe<Scalars['Boolean']>;
  disabledPaymentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  childAge?: Maybe<Scalars['NonNegativeInt']>;
};

export type OperatorSettingsCrew = {
  __typename?: 'OperatorSettingsCrew';
  shareBookings?: Maybe<Scalars['Boolean']>;
  allowFlightSelection?: Maybe<Scalars['Boolean']>;
};

export type OperatorSettingsFlights = {
  __typename?: 'OperatorSettingsFlights';
  sunriseOffset?: Maybe<Scalars['NonNegativeInt']>;
  sunsetOffset?: Maybe<Scalars['NonNegativeInt']>;
  meetingTimeOffset?: Maybe<Scalars['NonNegativeInt']>;
};

export type OperatorSettingsManifest = {
  __typename?: 'OperatorSettingsManifest';
  pilotDeclaration?: Maybe<Array<Maybe<Scalars['String']>>>;
  footer?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OperatorSettingsMyflight = {
  __typename?: 'OperatorSettingsMyflight';
  autoConfirm?: Maybe<Scalars['Boolean']>;
  flights?: Maybe<MyflightFlights>;
  endOfSeason?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OperatorSettingsSms = {
  __typename?: 'OperatorSettingsSms';
  footerEnabled?: Maybe<Scalars['Boolean']>;
};

export enum OrderByBooking {
  ContactName = 'contactName',
  BookingDate = 'bookingDate',
  PreferredDate = 'preferredDate'
}

export enum OrderByFlight {
  Status = 'status',
  Date = 'date'
}

export enum OrderByVoucher {
  ContactName = 'contactName',
  VoucherReference = 'voucherReference',
  IssueDate = 'issueDate'
}

export enum OrderDirection {
  Desc = 'desc',
  Asc = 'asc'
}

export type Passenger = {
  __typename?: 'Passenger';
  accompany?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  child?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  disability?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  luggage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  postalCode?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['DateTime']>;
  signature?: Maybe<Scalars['String']>;
  totalWeight?: Maybe<Scalars['PositiveInt']>;
  voucher?: Maybe<Voucher>;
  voucherNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['PositiveInt']>;
  age?: Maybe<Scalars['PositiveInt']>;
  transport?: Maybe<Scalars['Boolean']>;
};

export type PassengerCounters = {
  __typename?: 'PassengerCounters';
  id?: Maybe<Scalars['ID']>;
  childCount?: Maybe<Scalars['NonNegativeInt']>;
  disabilityCount?: Maybe<Scalars['NonNegativeInt']>;
  luggageCount?: Maybe<Scalars['NonNegativeInt']>;
  transportCount?: Maybe<Scalars['NonNegativeInt']>;
  openAmount?: Maybe<Scalars['NonNegativeInt']>;
  paidAmount?: Maybe<Scalars['NonNegativeInt']>;
  passengerCount?: Maybe<Scalars['NonNegativeInt']>;
  totalAmount?: Maybe<Scalars['NonNegativeInt']>;
  totalWeight?: Maybe<Scalars['NonNegativeInt']>;
  unpaidPassengerCount?: Maybe<Scalars['NonNegativeInt']>;
};

export type PassengerData = {
  __typename?: 'PassengerData';
  freeSpots?: Maybe<Scalars['NonNegativeInt']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  occupancy?: Maybe<Scalars['NonNegativeInt']>;
  passengersCount?: Maybe<Scalars['NonNegativeInt']>;
  passengersCountAdded?: Maybe<Scalars['NonNegativeInt']>;
  passengersCountConfirmed?: Maybe<Scalars['NonNegativeInt']>;
  passengersCountInvited?: Maybe<Scalars['NonNegativeInt']>;
  passengersWeight?: Maybe<Scalars['NonNegativeInt']>;
};

export type PassengerInput = {
  accompany?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['ID']>;
  child: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  disability?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  luggage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  voucherId?: Maybe<Scalars['ID']>;
  voucherNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['PositiveInt']>;
  age?: Maybe<Scalars['PositiveInt']>;
  transport?: Maybe<Scalars['Boolean']>;
};

export type PassengerPaymentInput = {
  category?: Maybe<Scalars['ID']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  child?: Maybe<Scalars['Boolean']>;
  voucherId?: Maybe<Scalars['ID']>;
  voucherNumber?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  Open = 'OPEN',
  Paid = 'PAID',
  Refunded = 'REFUNDED'
}

export enum PaymentType {
  Nopa = 'NOPA',
  Invo = 'INVO',
  Cash = 'CASH',
  Coup = 'COUP',
  Vouc = 'VOUC',
  Wire = 'WIRE',
  Banc = 'BANC',
  Cred = 'CRED',
  Onli = 'ONLI'
}


export enum PilotLicense {
  Com = 'COM',
  Nco = 'NCO',
  Nol = 'NOL'
}

export type PilotSignOffInput = {
  pilotComments?: Maybe<Scalars['String']>;
  pilotSignature?: Maybe<Scalars['String']>;
};

export type Pluscode = {
  __typename?: 'Pluscode';
  compound_code?: Maybe<Scalars['String']>;
  global_code?: Maybe<Scalars['String']>;
};





export type PreferredDateInput = {
  date?: Maybe<Scalars['String']>;
  period?: Maybe<FlightPeriod>;
};

export type PreferredFlight = {
  __typename?: 'PreferredFlight';
  date?: Maybe<Scalars['String']>;
  period?: Maybe<FlightPeriod>;
};

export type PreferredFlightInput = {
  date?: Maybe<Scalars['String']>;
  period?: Maybe<FlightPeriod>;
};

export type Query = {
  __typename?: 'Query';
  allReleaseNotes?: Maybe<Array<Maybe<ReleaseNote>>>;
  balloon: Balloon;
  balloonWeight: BalloonWeight;
  balloons: Array<Maybe<Balloon>>;
  booking?: Maybe<Booking>;
  bookingConversations: Array<Maybe<Booking>>;
  bookingCounters?: Maybe<BookingCounters>;
  bookingsPreferredDate: Array<Maybe<Booking>>;
  bookingsToConfirm?: Maybe<Scalars['NonNegativeInt']>;
  calendar: Calendar;
  categories: Array<Maybe<Category>>;
  category: Category;
  copyProductionToDevelopment: Scalars['NonNegativeInt'];
  countries: Array<Maybe<Country>>;
  crew: Crew;
  crewCalendar: Array<Maybe<CrewCalendarItem>>;
  crewConversations: Array<Maybe<Crew>>;
  crews: Array<Maybe<Crew>>;
  crewsPerFlight: Array<Maybe<CrewPerFlight>>;
  cylinder: Cylinder;
  cylinders: Array<Maybe<Cylinder>>;
  dashboardBookings: Array<Maybe<Booking>>;
  defaultCategory: Category;
  events: Array<Maybe<Event>>;
  expiredVouchers: Array<Maybe<Voucher>>;
  fetchSkeyesForecast?: Maybe<FlightSkeyesForecast>;
  firstBookingWithFlight?: Maybe<Array<Maybe<Booking>>>;
  flight: Flight;
  flightCounters?: Maybe<FlightCounters>;
  flightPeriods?: Maybe<FlightPeriods>;
  flightsOfDashboard: Array<Maybe<Flight>>;
  flightsOfDate: Array<Maybe<Flight>>;
  flightsOfDateCrew: Array<Maybe<Flight>>;
  geocode: Array<Maybe<GeocodeResult>>;
  getAllEmailTemplates: Array<Maybe<EmailSettingsTemplate>>;
  getAllSmsTemplates: Array<Maybe<SmsTemplate>>;
  getAllTags: Array<Maybe<Tag>>;
  getBookingsIS: Array<Maybe<Booking>>;
  getEmailTemplate: EmailSettingsTemplate;
  getEmailTemplateByID: EmailSettingsTemplate;
  getEmailTemplateByLanguage: EmailTemplateByLanguage;
  getEmailTemplates: Array<Maybe<EmailSettingsTemplate>>;
  getFlightsList: Array<Maybe<Flight>>;
  getHistoricalFlightsList: Array<Maybe<Flight>>;
  getSkeyesForecast?: Maybe<SkeyesForecast>;
  getSmsTemplates: Array<Maybe<SmsTemplate>>;
  getTag: Tag;
  getTagsOfType: Array<Maybe<Tag>>;
  getTenantByDisplayName: Tenant;
  getTenants: Array<Maybe<Tenant>>;
  getUser?: Maybe<GcpipUser>;
  getUsers: Array<Maybe<GcpipUser>>;
  get_booking_tenant?: Maybe<Scalars['JSONObject']>;
  get_crew_ics?: Maybe<Scalars['JSON']>;
  get_flights_tenant?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  historicalBookings: Array<Maybe<Booking>>;
  location: Location;
  locations: Array<Maybe<Location>>;
  manufacturers?: Maybe<Manufacturers>;
  myflight?: Maybe<Myflight>;
  myflightBooking?: Maybe<MyflightBooking>;
  myflightFlights?: Maybe<Array<Maybe<Flight>>>;
  myflightLegacy?: Maybe<Myflight>;
  openVouchers: Array<Maybe<Voucher>>;
  parseEmailTemplate: EmailTemplateByLanguage;
  passengerCounters?: Maybe<PassengerCounters>;
  passengerRegionDistribution?: Maybe<Chart>;
  pilotsPerFlight: Array<Maybe<CrewPerFlight>>;
  questions: Array<Maybe<Question>>;
  receive?: Maybe<Scalars['String']>;
  region: Region;
  regions: Array<Maybe<Region>>;
  releaseNote?: Maybe<ReleaseNote>;
  releaseNotes?: Maybe<Array<Maybe<ReleaseNote>>>;
  releaseNotesCount?: Maybe<Scalars['NonNegativeInt']>;
  reports?: Maybe<Reports>;
  reverseGeocode?: Maybe<Scalars['String']>;
  scheduledFlights: Array<Maybe<Flight>>;
  serverStatus?: Maybe<ServerStatus>;
  smsByBooking?: Maybe<Array<Maybe<Sms>>>;
  smsByCrew?: Maybe<Array<Maybe<Sms>>>;
  smsByFlight?: Maybe<Array<Maybe<Sms>>>;
  smsByPhone?: Maybe<Array<Maybe<Sms>>>;
  sunriseSunset: SunriseSunset;
  tenant?: Maybe<TenantInfo>;
  tenantSetting?: Maybe<TenantSetting>;
  unreadSms: Array<Maybe<Sms>>;
  upFlights?: Maybe<Array<Flight>>;
  upUrl?: Maybe<Scalars['String']>;
  upcomingBookings: Array<Maybe<Booking>>;
  upcomingFlights: Array<Maybe<Flight>>;
  usedVouchers: Array<Maybe<Voucher>>;
  voucher?: Maybe<Voucher>;
  voucherCounters?: Maybe<VoucherCounters>;
  vouchers: Array<Maybe<Voucher>>;
};


export type QueryBalloonArgs = {
  balloonId: Scalars['ID'];
};


export type QueryBalloonWeightArgs = {
  balloonId: Scalars['ID'];
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryBookingConversationsArgs = {
  offset?: Maybe<Scalars['NonNegativeInt']>;
};


export type QueryBookingsPreferredDateArgs = {
  preferredDateInput?: Maybe<PreferredDateInput>;
};


export type QueryCalendarArgs = {
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
};


export type QueryCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type QueryCopyProductionToDevelopmentArgs = {
  tenantId?: Maybe<Scalars['String']>;
  copyUsers?: Maybe<Scalars['Boolean']>;
  copyHistory?: Maybe<Scalars['Boolean']>;
};


export type QueryCrewArgs = {
  crewId: Scalars['ID'];
};


export type QueryCrewCalendarArgs = {
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  userId?: Maybe<Scalars['String']>;
};


export type QueryCrewConversationsArgs = {
  offset?: Maybe<Scalars['NonNegativeInt']>;
};


export type QueryCrewsPerFlightArgs = {
  flightId: Scalars['ID'];
};


export type QueryCylinderArgs = {
  cylinderId: Scalars['ID'];
};


export type QueryExpiredVouchersArgs = {
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
};


export type QueryFetchSkeyesForecastArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
  flightId: Scalars['ID'];
};


export type QueryFlightArgs = {
  flightId: Scalars['ID'];
};


export type QueryFlightsOfDateArgs = {
  date: Scalars['String'];
};


export type QueryFlightsOfDateCrewArgs = {
  date: Scalars['String'];
};


export type QueryGeocodeArgs = {
  address: Scalars['String'];
};


export type QueryGetBookingsIsArgs = {
  showUpcoming?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  bookingFilter?: Maybe<BookingFilter>;
  flightId?: Maybe<Scalars['ID']>;
  flightOnlyMatching?: Maybe<Scalars['Boolean']>;
  endAt?: Maybe<Scalars['Boolean']>;
};


export type QueryGetEmailTemplateArgs = {
  type: EmailTemplateType;
};


export type QueryGetEmailTemplateByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetEmailTemplateByLanguageArgs = {
  type: EmailTemplateType;
  lang?: Maybe<ContactLanguage>;
};


export type QueryGetEmailTemplatesArgs = {
  type: EmailTemplateType;
};


export type QueryGetFlightsListArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterRange?: Maybe<FilterRangeInput>;
};


export type QueryGetHistoricalFlightsListArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterRange?: Maybe<FilterRangeInput>;
};


export type QueryGetSkeyesForecastArgs = {
  slot: Scalars['String'];
};


export type QueryGetSmsTemplatesArgs = {
  type: Scalars['String'];
};


export type QueryGetTagArgs = {
  tagId: Scalars['ID'];
};


export type QueryGetTagsOfTypeArgs = {
  tagType?: Maybe<TagType>;
};


export type QueryGetTenantByDisplayNameArgs = {
  displayName: Scalars['String'];
};


export type QueryGetUsersArgs = {
  tenantId: Scalars['String'];
};


export type QueryGet_Booking_TenantArgs = {
  bookingId: Scalars['ID'];
};


export type QueryHistoricalBookingsArgs = {
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  cursor?: Maybe<Scalars['String']>;
  bookingFilter?: Maybe<BookingFilter>;
};


export type QueryLocationArgs = {
  locationId: Scalars['ID'];
};


export type QueryMyflightArgs = {
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
};


export type QueryMyflightBookingArgs = {
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
};


export type QueryMyflightFlightsArgs = {
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
};


export type QueryMyflightLegacyArgs = {
  legacyBooking: Scalars['String'];
};


export type QueryOpenVouchersArgs = {
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
};


export type QueryParseEmailTemplateArgs = {
  emailBody: Scalars['String'];
  language: Scalars['String'];
  flightPeriod: Scalars['String'];
};


export type QueryPassengerCountersArgs = {
  passengers: Array<Maybe<PassengerInput>>;
};


export type QueryPilotsPerFlightArgs = {
  flightId: Scalars['ID'];
};


export type QueryQuestionsArgs = {
  active?: Maybe<Scalars['Boolean']>;
};


export type QueryReceiveArgs = {
  msisdn: Scalars['String'];
  text: Scalars['String'];
};


export type QueryRegionArgs = {
  regionId: Scalars['ID'];
};


export type QueryReleaseNoteArgs = {
  releaseNoteId: Scalars['ID'];
};


export type QueryReverseGeocodeArgs = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};


export type QueryScheduledFlightsArgs = {
  orderBy?: Maybe<OrderByFlight>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
};


export type QuerySmsByBookingArgs = {
  bookingId: Scalars['ID'];
};


export type QuerySmsByCrewArgs = {
  crewId: Scalars['ID'];
};


export type QuerySmsByFlightArgs = {
  flightId: Scalars['ID'];
};


export type QuerySmsByPhoneArgs = {
  phone?: Maybe<Scalars['String']>;
};


export type QuerySunriseSunsetArgs = {
  sunriseSunsetInput?: Maybe<SunriseSunsetInput>;
};


export type QueryUpUrlArgs = {
  bookingId: Scalars['ID'];
};


export type QueryUpcomingBookingsArgs = {
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  cursor?: Maybe<Scalars['String']>;
  bookingFilter?: Maybe<BookingFilter>;
  flightId?: Maybe<Scalars['ID']>;
  flightOnlyMatching?: Maybe<Scalars['Boolean']>;
};


export type QueryUpcomingFlightsArgs = {
  orderBy?: Maybe<OrderByFlight>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
};


export type QueryUsedVouchersArgs = {
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
};


export type QueryVoucherArgs = {
  id: Scalars['ID'];
};

export type Question = {
  __typename?: 'Question';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<QuestionTemplate>;
  content?: Maybe<QuestionContent>;
  id: Scalars['ID'];
  kind?: Maybe<QuestionKind>;
};

export type QuestionContent = {
  __typename?: 'QuestionContent';
  description?: Maybe<Array<Maybe<MlText>>>;
  order: Scalars['PositiveInt'];
  title: Array<Maybe<MlText>>;
};

export type QuestionContentInput = {
  description?: Maybe<Array<Maybe<MlTextInput>>>;
  order: Scalars['PositiveInt'];
  title: Array<Maybe<MlTextInput>>;
};

export type QuestionInput = {
  description?: Maybe<Scalars['String']>;
  lang?: Maybe<SystemLanguage>;
  title?: Maybe<Scalars['String']>;
};

export enum QuestionKind {
  Mandatory = 'mandatory',
  Optional = 'optional'
}

export enum QuestionTemplate {
  Accompany = 'accompany',
  Address = 'address',
  Child = 'child',
  Email = 'email',
  Luggage = 'luggage',
  Name = 'name',
  Pay = 'pay',
  Signature = 'signature',
  Weight = 'weight'
}



export type Region = {
  __typename?: 'Region';
  color?: Maybe<Scalars['String']>;
  defaultColor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locations: Array<Maybe<Location>>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  removed?: Maybe<Scalars['DateTime']>;
};

export type RegionInput = {
  color?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  id?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  documentation?: Maybe<Scalars['String']>;
  oneliner?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  tenants?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Reports = {
  __typename?: 'Reports';
  flights?: Maybe<Scalars['URL']>;
  bookings?: Maybe<Scalars['URL']>;
  bookingsOpen?: Maybe<Scalars['URL']>;
  vouchers?: Maybe<Scalars['URL']>;
  payments?: Maybe<Scalars['URL']>;
  sms?: Maybe<Scalars['URL']>;
};

export type ServerStatus = {
  __typename?: 'ServerStatus';
  maintenance?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  purpose?: Maybe<Scalars['String']>;
  expectedDuration?: Maybe<Scalars['NonNegativeInt']>;
};

export type SkeyesForecast = {
  __typename?: 'SkeyesForecast';
  id?: Maybe<Scalars['ID']>;
  datetime?: Maybe<Scalars['DateTime']>;
  full?: Maybe<Scalars['String']>;
  inversions?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['DateTime']>;
  outlook?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  slot?: Maybe<Scalars['String']>;
  weather?: Maybe<Scalars['String']>;
  winds?: Maybe<Scalars['String']>;
};

export type Sms = {
  __typename?: 'Sms';
  date?: Maybe<SmsDates>;
  direction?: Maybe<SmsDirection>;
  flight?: Maybe<Flight>;
  id: Scalars['ID'];
  messageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  nexmo?: Maybe<Scalars['JSONObject']>;
  path?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsProvider>;
  raspberry?: Maybe<Scalars['JSONObject']>;
  text?: Maybe<Scalars['String']>;
  eventSummary?: Maybe<SmsEventSummary>;
};

export type SmsConversation = {
  __typename?: 'SmsConversation';
  bookingId?: Maybe<Scalars['ID']>;
  crewId?: Maybe<Scalars['ID']>;
  latest?: Maybe<SmsSummary>;
  type?: Maybe<SmsConversationType>;
  unread?: Maybe<Scalars['NonNegativeInt']>;
  booking?: Maybe<Booking>;
  crew?: Maybe<Crew>;
};

export enum SmsConversationType {
  Boo = 'BOO',
  Cre = 'CRE'
}

export type SmsDates = {
  __typename?: 'SmsDates';
  sms?: Maybe<Scalars['DateTime']>;
  read?: Maybe<Scalars['DateTime']>;
  processed?: Maybe<Scalars['DateTime']>;
};

export enum SmsDirection {
  In = 'IN',
  Out = 'OUT'
}

export type SmsEventSummary = {
  __typename?: 'SmsEventSummary';
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type SmsInput = {
  text?: Maybe<Scalars['String']>;
  up?: Maybe<Scalars['Boolean']>;
  positiveReply?: Maybe<Scalars['String']>;
  negativeReply?: Maybe<Scalars['String']>;
  repeat?: Maybe<SmsRepeat>;
};

export type SmsLatest = {
  __typename?: 'SmsLatest';
  date?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  direction?: Maybe<SmsDirection>;
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export enum SmsProvider {
  Nexmo = 'NEXMO',
  Raspberry = 'RASPBERRY',
  Eimers = 'EIMERS'
}

export enum SmsRepeat {
  Single = 'SINGLE',
  Pax = 'PAX',
  All = 'ALL'
}

export type SmsSummary = {
  __typename?: 'SmsSummary';
  date?: Maybe<Scalars['DateTime']>;
  direction?: Maybe<SmsDirection>;
  messageId?: Maybe<Scalars['ID']>;
  summary?: Maybe<Scalars['String']>;
};

export type SmsTemplate = {
  __typename?: 'SmsTemplate';
  id: Scalars['ID'];
  template: Template;
  type: Scalars['String'];
};

export type SmsTemplateInput = {
  template?: Maybe<TemplateInput>;
  type?: Maybe<Scalars['String']>;
};

export enum SmsType {
  PassengerInvitationSms = 'passengerInvitationSms',
  ReminderSms = 'reminderSms',
  PilotInvitationSms = 'pilotInvitationSms',
  CancelSms = 'cancelSms',
  CrewInvitationSms = 'crewInvitationSms',
  LocationContactSms = 'locationContactSms'
}

export type StatusCounter = {
  __typename?: 'StatusCounter';
  status?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['NonNegativeInt']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  bookingCountersUpdated?: Maybe<BookingCounters>;
  factuursturenClient?: Maybe<Scalars['NonNegativeInt']>;
  flightCountersUpdated?: Maybe<FlightCounters>;
  newSms?: Maybe<Array<Maybe<Sms>>>;
  plan?: Maybe<SubscriptionPlan>;
  sms?: Maybe<SubscriptionSms>;
  voucherCountersUpdated?: Maybe<VoucherCounters>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  name?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['NonNegativeInt']>;
  used?: Maybe<Scalars['NonNegativeInt']>;
  pctUsed?: Maybe<Scalars['NonNegativeInt']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionSms = {
  __typename?: 'SubscriptionSMS';
  available?: Maybe<Scalars['NonNegativeInt']>;
  ignoreWarning?: Maybe<Scalars['Boolean']>;
  usedSMS?: Maybe<Array<Maybe<UsedSms>>>;
  pctUsed?: Maybe<Scalars['NonNegativeInt']>;
};

export type SunriseSunset = {
  __typename?: 'SunriseSunset';
  sunrise?: Maybe<Scalars['DateTime']>;
  sunset?: Maybe<Scalars['DateTime']>;
  morningFlight?: Maybe<Scalars['String']>;
  eveningFlight?: Maybe<Scalars['String']>;
};

export type SunriseSunsetInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  flightDate?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
};

export enum SystemLanguage {
  Nl = 'nl',
  Du = 'du',
  Fr = 'fr',
  En = 'en',
  Cs = 'cs',
  De = 'de',
  Es = 'es',
  It = 'it',
  Lv = 'lv',
  Pt = 'pt'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  tag?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  type?: Maybe<TagType>;
  enabled?: Maybe<Scalars['Boolean']>;
  upcoming?: Maybe<Scalars['PositiveInt']>;
  history?: Maybe<Scalars['PositiveInt']>;
  locked?: Maybe<Scalars['Boolean']>;
  dashboard?: Maybe<Scalars['Boolean']>;
};

export type TagData = {
  __typename?: 'TagData';
  id: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type TagInput = {
  tag?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  type?: Maybe<TagType>;
  enabled?: Maybe<Scalars['Boolean']>;
  dashboard?: Maybe<Scalars['Boolean']>;
};

export enum TagType {
  Booking = 'booking',
  Flight = 'flight'
}

export type Template = {
  __typename?: 'Template';
  en: Scalars['String'];
  fr: Scalars['String'];
  nl: Scalars['String'];
  de: Scalars['String'];
};

export type TemplateInput = {
  en: Scalars['String'];
  fr: Scalars['String'];
  nl: Scalars['String'];
  de: Scalars['String'];
};

export type Tenant = {
  __typename?: 'Tenant';
  tenantSetting?: Maybe<TenantSetting>;
  tenantId?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
};

export enum TenantCurrency {
  Eur = 'EUR',
  Chf = 'CHF'
}

export type TenantInfo = {
  __typename?: 'TenantInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo?: Maybe<Scalars['String']>;
};

export type TenantSetting = {
  __typename?: 'TenantSetting';
  dashboardOptions?: Maybe<DashboardOptions>;
  locationOptions?: Maybe<LocationOptions>;
  logo?: Maybe<Scalars['String']>;
  icsId?: Maybe<Scalars['String']>;
  mailAccountant?: Maybe<EmailAddressObject>;
  mailFrom?: Maybe<EmailAddressObject>;
  mailReplyTo?: Maybe<EmailAddressObject>;
  name?: Maybe<Scalars['String']>;
  operatorSettings?: Maybe<OperatorSettings>;
  subscription?: Maybe<Subscription>;
  tenantId: Scalars['String'];
  trial?: Maybe<Scalars['Boolean']>;
  trialEnds?: Maybe<Scalars['DateTime']>;
  upEnabled?: Maybe<Scalars['Boolean']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  passenger?: Maybe<Passenger>;
  booking?: Maybe<Booking>;
  flight?: Maybe<Flight>;
};



export type TrajectoryProfile = {
  __typename?: 'TrajectoryProfile';
  duration?: Maybe<Scalars['NonNegativeInt']>;
  level?: Maybe<Scalars['NonNegativeInt']>;
};





export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  language?: Maybe<SystemLanguage>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  tenant?: Maybe<Scalars['String']>;
  userRef?: Maybe<Scalars['String']>;
  viewState?: Maybe<Scalars['JSONObject']>;
  crew?: Maybe<Crew>;
};

export enum UserRole {
  Own = 'OWN',
  Opr = 'OPR',
  Cre = 'CRE',
  Rep = 'REP',
  Noa = 'NOA',
  Met = 'MET',
  Ass = 'ASS'
}


export type Viewport = {
  __typename?: 'Viewport';
  northeast?: Maybe<Mapslocation>;
  southwest?: Maybe<Mapslocation>;
};

export enum VisibilityType {
  Pri = 'PRI',
  Pub = 'PUB'
}


export type Voucher = {
  __typename?: 'Voucher';
  adults?: Maybe<Scalars['PositiveInt']>;
  adultsUsed?: Maybe<Scalars['PositiveInt']>;
  adultsAvailable?: Maybe<Scalars['PositiveInt']>;
  children?: Maybe<Scalars['PositiveInt']>;
  childrenUsed?: Maybe<Scalars['PositiveInt']>;
  childrenAvailable?: Maybe<Scalars['PositiveInt']>;
  comments?: Maybe<Scalars['String']>;
  contactCity?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLanguage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['PhoneNumber']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  external?: Maybe<Scalars['Boolean']>;
  externalSource?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  issueDate?: Maybe<Scalars['DateTime']>;
  paymentAmount?: Maybe<Scalars['PositiveInt']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  status?: Maybe<Scalars['String']>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
  voucherReference?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['PhoneNumber']>;
  recipientLanguage?: Maybe<ContactLanguage>;
  recipientEmail?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<Scalars['String']>;
  recipientPostalCode?: Maybe<Scalars['String']>;
  recipientCity?: Maybe<Scalars['String']>;
  recipientCountry?: Maybe<Scalars['String']>;
};

export type VoucherCounters = {
  __typename?: 'VoucherCounters';
  statusCounters?: Maybe<VoucherStatusCounters>;
  voucherTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VoucherInput = {
  adults?: Maybe<Scalars['PositiveInt']>;
  children?: Maybe<Scalars['PositiveInt']>;
  comments?: Maybe<Scalars['String']>;
  contactCity?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLanguage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['PhoneNumber']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  external?: Maybe<Scalars['Boolean']>;
  externalSource?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  paymentAmount?: Maybe<Scalars['PositiveInt']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  voucherReference?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['PhoneNumber']>;
  recipientLanguage?: Maybe<ContactLanguage>;
  recipientEmail?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<Scalars['String']>;
  recipientPostalCode?: Maybe<Scalars['String']>;
  recipientCity?: Maybe<Scalars['String']>;
  recipientCountry?: Maybe<Scalars['String']>;
};

export enum VoucherStatus {
  Avai = 'AVAI',
  Used = 'USED',
  Expi = 'EXPI'
}

export type VoucherStatusCounters = {
  __typename?: 'VoucherStatusCounters';
  AVAI?: Maybe<Scalars['PositiveInt']>;
  USED?: Maybe<Scalars['PositiveInt']>;
  EXPI?: Maybe<Scalars['PositiveInt']>;
};

export type SlotForecast = {
  __typename?: 'slotForecast';
  datepart?: Maybe<Array<Maybe<DatePart>>>;
  daytime?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  flytime?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  lowCloudsData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  pictoCodeData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  precipitationData?: Maybe<Array<Maybe<Scalars['Float']>>>;
  pressureData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  sunrise?: Maybe<Scalars['DateTime']>;
  sunset?: Maybe<Scalars['DateTime']>;
  temperatureData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  time?: Maybe<Array<Maybe<Scalars['String']>>>;
  utc_timeoffset?: Maybe<Scalars['Int']>;
  visibilityData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  windDirectionData?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_1000?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_200?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_250?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_300?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_350?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_400?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_450?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_500?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_550?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_600?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_650?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_700?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_750?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_800?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_925?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_950?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_975?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windGustData?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_1000?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_200?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_250?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_300?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_350?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_400?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_450?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_500?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_550?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_600?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_650?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_700?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_750?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_800?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_925?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_950?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_975?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type UpdateResult = {
  __typename?: 'updateResult';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type UsedSms = {
  __typename?: 'usedSMS';
  year?: Maybe<Scalars['NonNegativeInt']>;
  month?: Maybe<Scalars['NonNegativeInt']>;
  used?: Maybe<Scalars['NonNegativeInt']>;
};

export type GetBalloonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBalloonsQuery = (
  { __typename?: 'Query' }
  & { balloons: Array<Maybe<(
    { __typename?: 'Balloon' }
    & Pick<Balloon, 'isCurrent' | 'callSign' | 'capacity' | 'id' | 'name'>
  )>> }
);

export type GetBalloonQueryVariables = Exact<{
  balloonId: Scalars['ID'];
}>;


export type GetBalloonQuery = (
  { __typename?: 'Query' }
  & { balloon: (
    { __typename?: 'Balloon' }
    & Pick<Balloon, 'id' | 'isCurrent' | 'name' | 'callSign' | 'capacity' | 'classGroups' | 'mlm' | 'mtom' | 'minFuel' | 'balloonTemp' | 'envelopeManufacturer' | 'envelopeType' | 'envelopeVolume' | 'envelopeWeight' | 'envelopeHours' | 'envelopeInspectionDate' | 'burnerManufacturer' | 'burnerType' | 'burnerWeight' | 'basketManufacturer' | 'basketType' | 'basketWeight' | 'insuranceCompany' | 'insuranceDueDate'>
    & { cylinder: Array<Maybe<(
      { __typename?: 'Cylinder' }
      & Pick<Cylinder, 'id' | 'emptyWeight' | 'fullWeight' | 'manufacturer' | 'volume' | 'number' | 'serialNumber'>
    )>>, classGroup: Array<Maybe<(
      { __typename?: 'ClassGroup' }
      & Pick<ClassGroup, 'id' | 'description'>
    )>>, balloonWeight?: Maybe<(
      { __typename?: 'BalloonWeight' }
      & Pick<BalloonWeight, 'totalWeight' | 'cylindersWeight'>
    )> }
  ) }
);

export type GetManufacturersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManufacturersQuery = (
  { __typename?: 'Query' }
  & { manufacturers?: Maybe<(
    { __typename?: 'Manufacturers' }
    & Pick<Manufacturers, 'envelopeManufacturers' | 'basketManufacturers' | 'burnerManufacturers' | 'cylinderManufacturers'>
  )> }
);

export type BalloonWeightQueryVariables = Exact<{
  balloonId: Scalars['ID'];
}>;


export type BalloonWeightQuery = (
  { __typename?: 'Query' }
  & { balloonWeight: (
    { __typename?: 'BalloonWeight' }
    & Pick<BalloonWeight, 'totalWeight' | 'cylindersWeight'>
  ) }
);

export type SaveBalloonMutationVariables = Exact<{
  balloonInput: BalloonInput;
  cylinders: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  balloonId?: Maybe<Scalars['ID']>;
}>;


export type SaveBalloonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveBalloon'>
);

export type DeleteBalloonMutationVariables = Exact<{
  balloonId: Scalars['ID'];
}>;


export type DeleteBalloonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBalloon'>
);

export type GetBookingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookingQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'inviteCount' | 'status' | 'icon' | 'iconColor' | 'bookingDate' | 'contactName' | 'contactEmail' | 'contactPhone' | 'contactLanguage' | 'comments' | 'bookingCode' | 'startDate' | 'endDate' | 'daysExpired' | 'external' | 'externalSource' | 'city' | 'locationType' | 'availabilityType' | 'availabilityMondayMorning' | 'availabilityTuesdayMorning' | 'availabilityWednesdayMorning' | 'availabilityThursdayMorning' | 'availabilityFridayMorning' | 'availabilitySaturdayMorning' | 'availabilitySundayMorning' | 'availabilityMondayEvening' | 'availabilityTuesdayEvening' | 'availabilityWednesdayEvening' | 'availabilityThursdayEvening' | 'availabilityFridayEvening' | 'availabilitySaturdayEvening' | 'availabilitySundayEvening' | 'prepaymentAmount' | 'prepaymentDate' | 'invoiceName' | 'VATnumber' | 'smsType' | 'smsUnread' | 'upUrl'>
    & { region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'id' | 'name'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'address' | 'city' | 'country' | 'height' | 'latitude' | 'longitude' | 'postalCode' | 'private'>
    )>, mailEventLatest?: Maybe<(
      { __typename?: 'MailEventLatest' }
      & Pick<MailEventLatest, 'date' | 'event'>
    )>, preferredFlights?: Maybe<Array<Maybe<(
      { __typename?: 'PreferredFlight' }
      & Pick<PreferredFlight, 'date' | 'period'>
    )>>>, passengers: Array<Maybe<(
      { __typename?: 'Passenger' }
      & Pick<Passenger, 'id' | 'name' | 'age' | 'transport' | 'luggage' | 'child' | 'disability' | 'weight' | 'paymentType' | 'paymentReference' | 'paymentAmount' | 'paymentStatus' | 'paymentDate' | 'email' | 'address' | 'postalCode' | 'city' | 'country' | 'signature' | 'voucherNumber'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name' | 'description'>
      )>, voucher?: Maybe<(
        { __typename?: 'Voucher' }
        & Pick<Voucher, 'id' | 'voucherReference' | 'voucherType'>
      )> }
    )>>, passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'passengerCount' | 'unpaidPassengerCount' | 'childCount' | 'disabilityCount' | 'transportCount' | 'totalWeight' | 'totalAmount' | 'paidAmount' | 'openAmount'>
    )>, smsLatest?: Maybe<(
      { __typename?: 'SmsLatest' }
      & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'status' | 'error'>
    )>, flight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id' | 'date' | 'hour' | 'period'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'name'>
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'name'>
      )> }
    )>, mergeCandidates?: Maybe<Array<Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'bookingDate' | 'contactName' | 'id'>
      & { flight?: Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'date'>
      )> }
    )>>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )> }
);

export type GetFirstBookingWithFlightQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFirstBookingWithFlightQuery = (
  { __typename?: 'Query' }
  & { firstBookingWithFlight?: Maybe<Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'availabilityType' | 'status' | 'contactName' | 'contactPhone' | 'contactLanguage' | 'contactEmail' | 'comments' | 'bookingDate' | 'bookingCode' | 'smsType' | 'smsUnread' | 'upUrl'>
    & { region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'name'>
    )>, passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'childCount' | 'passengerCount' | 'totalWeight' | 'totalAmount' | 'openAmount' | 'disabilityCount' | 'transportCount'>
    )>, flight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'date' | 'period'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'name'>
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'name' | 'city' | 'address'>
      )>, pilot?: Maybe<(
        { __typename?: 'CrewInFlight' }
        & { crew?: Maybe<(
          { __typename?: 'CrewPerFlight' }
          & Pick<CrewPerFlight, 'name'>
        )> }
      )>, groundCrews: Array<Maybe<(
        { __typename?: 'CrewInFlight' }
        & Pick<CrewInFlight, 'status'>
        & { crew?: Maybe<(
          { __typename?: 'CrewPerFlight' }
          & Pick<CrewPerFlight, 'email' | 'id' | 'language' | 'name' | 'pilotLicense'>
        )> }
      )>> }
    )> }
  )>>> }
);

export type GetBookingLogsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookingLogsQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & { logs?: Maybe<Array<Maybe<(
      { __typename?: 'BookingLog' }
      & Pick<BookingLog, 'id' | 'date' | 'user' | 'action'>
      & { flight?: Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'id' | 'hour'>
        & { balloon?: Maybe<(
          { __typename?: 'Balloon' }
          & Pick<Balloon, 'name'>
        )> }
      )> }
    )>>>, logsMerged?: Maybe<Array<Maybe<(
      { __typename?: 'BookingLog' }
      & Pick<BookingLog, 'id' | 'date' | 'user' | 'action'>
      & { flight?: Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'id' | 'hour'>
        & { balloon?: Maybe<(
          { __typename?: 'Balloon' }
          & Pick<Balloon, 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetBookingMailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookingMailsQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & { mails?: Maybe<Array<Maybe<(
      { __typename?: 'Email' }
      & Pick<Email, 'to' | 'subject' | 'text' | 'html' | 'result' | 'resultAt'>
      & { from?: Maybe<(
        { __typename?: 'EmailAddressObject' }
        & Pick<EmailAddressObject, 'email' | 'name'>
      )>, replyTo?: Maybe<(
        { __typename?: 'EmailAddressObject' }
        & Pick<EmailAddressObject, 'email' | 'name'>
      )>, attachments: Array<Maybe<(
        { __typename?: 'EmailAttachment' }
        & Pick<EmailAttachment, 'filename'>
      )>>, events?: Maybe<Array<Maybe<(
        { __typename?: 'EmailEvent' }
        & Pick<EmailEvent, 'event' | 'timestamp' | 'url'>
      )>>> }
    )>>>, mailsMerged?: Maybe<Array<Maybe<(
      { __typename?: 'Email' }
      & Pick<Email, 'to' | 'subject' | 'text' | 'html' | 'result' | 'resultAt'>
      & { from?: Maybe<(
        { __typename?: 'EmailAddressObject' }
        & Pick<EmailAddressObject, 'email' | 'name'>
      )>, replyTo?: Maybe<(
        { __typename?: 'EmailAddressObject' }
        & Pick<EmailAddressObject, 'email' | 'name'>
      )>, attachments: Array<Maybe<(
        { __typename?: 'EmailAttachment' }
        & Pick<EmailAttachment, 'filename'>
      )>>, events?: Maybe<Array<Maybe<(
        { __typename?: 'EmailEvent' }
        & Pick<EmailEvent, 'event' | 'timestamp' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type GetUpcomingBookingsQueryVariables = Exact<{
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  bookingFilter?: Maybe<BookingFilter>;
  flightId?: Maybe<Scalars['ID']>;
  flightOnlyMatching?: Maybe<Scalars['Boolean']>;
}>;


export type GetUpcomingBookingsQuery = (
  { __typename?: 'Query' }
  & { upcomingBookings: Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'availabilityType' | 'status' | 'icon' | 'iconColor' | 'id' | 'inviteCount' | 'contactName' | 'contactPhone' | 'comments' | 'bookingCode' | 'bookingDate' | 'daysExpired' | 'city' | 'external' | 'externalSource' | 'locationType' | 'smsUnread' | 'preferredDate' | 'mismatchReasons' | 'upUrl'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'city'>
    )>, mailEventLatest?: Maybe<(
      { __typename?: 'MailEventLatest' }
      & Pick<MailEventLatest, 'date' | 'event'>
    )>, smsLatest?: Maybe<(
      { __typename?: 'SmsLatest' }
      & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'text' | 'status' | 'error'>
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'name'>
    )>, passengers: Array<Maybe<(
      { __typename?: 'Passenger' }
      & Pick<Passenger, 'child' | 'disability' | 'weight'>
    )>>, passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'passengerCount' | 'childCount' | 'disabilityCount' | 'transportCount' | 'totalWeight' | 'totalAmount' | 'paidAmount' | 'openAmount'>
    )>, preferredFlights?: Maybe<Array<Maybe<(
      { __typename?: 'PreferredFlight' }
      & Pick<PreferredFlight, 'date' | 'period'>
    )>>>, flight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id' | 'date' | 'period'>
    )>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )>> }
);

export type GetHistoricalBookingsQueryVariables = Exact<{
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetHistoricalBookingsQuery = (
  { __typename?: 'Query' }
  & { historicalBookings: Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'availabilityType' | 'status' | 'icon' | 'iconColor' | 'id' | 'bookingDate' | 'daysExpired' | 'contactName' | 'contactPhone' | 'comments' | 'bookingCode' | 'external' | 'externalSource' | 'locationType' | 'city' | 'preferredDate' | 'upUrl'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'city'>
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'name'>
    )>, passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'passengerCount' | 'totalWeight'>
    )>, preferredFlights?: Maybe<Array<Maybe<(
      { __typename?: 'PreferredFlight' }
      & Pick<PreferredFlight, 'date' | 'period'>
    )>>>, flight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'date' | 'period'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'name'>
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'name'>
      )>, pilot?: Maybe<(
        { __typename?: 'CrewInFlight' }
        & { crew?: Maybe<(
          { __typename?: 'CrewPerFlight' }
          & Pick<CrewPerFlight, 'name'>
        )> }
      )> }
    )>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )>> }
);

export type GetUpcomingBookingsIsQueryVariables = Exact<{
  showUpcoming?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  bookingFilter?: Maybe<BookingFilter>;
  flightId?: Maybe<Scalars['ID']>;
  flightOnlyMatching?: Maybe<Scalars['Boolean']>;
  endAt?: Maybe<Scalars['Boolean']>;
}>;


export type GetUpcomingBookingsIsQuery = (
  { __typename?: 'Query' }
  & { getBookingsIS: Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'availabilityType' | 'status' | 'icon' | 'iconColor' | 'id' | 'inviteCount' | 'contactName' | 'contactPhone' | 'comments' | 'bookingCode' | 'bookingDate' | 'daysExpired' | 'city' | 'external' | 'externalSource' | 'locationType' | 'smsUnread' | 'preferredDate' | 'mismatchReasons' | 'upUrl'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'city'>
    )>, mailEventLatest?: Maybe<(
      { __typename?: 'MailEventLatest' }
      & Pick<MailEventLatest, 'date' | 'event'>
    )>, smsLatest?: Maybe<(
      { __typename?: 'SmsLatest' }
      & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'text' | 'status' | 'error'>
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'name'>
    )>, passengers: Array<Maybe<(
      { __typename?: 'Passenger' }
      & Pick<Passenger, 'child' | 'disability' | 'weight'>
    )>>, passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'passengerCount' | 'childCount' | 'disabilityCount' | 'transportCount' | 'totalWeight' | 'totalAmount' | 'paidAmount' | 'openAmount'>
    )>, preferredFlights?: Maybe<Array<Maybe<(
      { __typename?: 'PreferredFlight' }
      & Pick<PreferredFlight, 'date' | 'period'>
    )>>>, flight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id' | 'date' | 'period' | 'passengersOccupancy' | 'passengersCount'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'capacity'>
      )> }
    )>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )>> }
);

export type GetHistoricalBookingsIsQueryVariables = Exact<{
  showUpcoming?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  bookingFilter?: Maybe<BookingFilter>;
  flightId?: Maybe<Scalars['ID']>;
  flightOnlyMatching?: Maybe<Scalars['Boolean']>;
  endAt?: Maybe<Scalars['Boolean']>;
}>;


export type GetHistoricalBookingsIsQuery = (
  { __typename?: 'Query' }
  & { getBookingsIS: Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'availabilityType' | 'status' | 'icon' | 'iconColor' | 'id' | 'bookingDate' | 'daysExpired' | 'contactName' | 'contactPhone' | 'comments' | 'bookingCode' | 'external' | 'externalSource' | 'locationType' | 'city' | 'preferredDate' | 'upUrl'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'city'>
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'name'>
    )>, passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'passengerCount' | 'totalWeight'>
    )>, preferredFlights?: Maybe<Array<Maybe<(
      { __typename?: 'PreferredFlight' }
      & Pick<PreferredFlight, 'date' | 'period'>
    )>>>, flight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'date' | 'period'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'name'>
      )>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'name'>
      )>, pilot?: Maybe<(
        { __typename?: 'CrewInFlight' }
        & { crew?: Maybe<(
          { __typename?: 'CrewPerFlight' }
          & Pick<CrewPerFlight, 'name'>
        )> }
      )> }
    )>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )>> }
);

export type DashboardBookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardBookingsQuery = (
  { __typename?: 'Query' }
  & { dashboardBookings: Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'availabilityType' | 'status' | 'icon' | 'iconColor' | 'id' | 'inviteCount' | 'bookingDate' | 'daysExpired' | 'city' | 'contactName' | 'contactPhone' | 'comments' | 'external' | 'externalSource' | 'locationType' | 'smsUnread' | 'preferredDate'>
    & { mergeCandidates?: Maybe<Array<Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id'>
      & { flight?: Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'date'>
      )> }
    )>>>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'city'>
    )>, mailEventLatest?: Maybe<(
      { __typename?: 'MailEventLatest' }
      & Pick<MailEventLatest, 'date' | 'event'>
    )>, smsLatest?: Maybe<(
      { __typename?: 'SmsLatest' }
      & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'text' | 'status' | 'error'>
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'name'>
    )>, preferredFlights?: Maybe<Array<Maybe<(
      { __typename?: 'PreferredFlight' }
      & Pick<PreferredFlight, 'date' | 'period'>
    )>>>, flight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id' | 'date' | 'period' | 'passengersOccupancy' | 'passengersCount'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'capacity'>
      )> }
    )>, passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'openAmount' | 'passengerCount' | 'childCount' | 'disabilityCount' | 'transportCount' | 'totalWeight'>
    )>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )>> }
);

export type BookingsPreferredDateQueryVariables = Exact<{
  preferredDateInput?: Maybe<PreferredDateInput>;
}>;


export type BookingsPreferredDateQuery = (
  { __typename?: 'Query' }
  & { bookingsPreferredDate: Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & { flight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id'>
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'name'>
    )> }
  )>> }
);

export type PassengerCountersQueryVariables = Exact<{
  passengers: Array<Maybe<PassengerInput>> | Maybe<PassengerInput>;
}>;


export type PassengerCountersQuery = (
  { __typename?: 'Query' }
  & { passengerCounters?: Maybe<(
    { __typename?: 'PassengerCounters' }
    & Pick<PassengerCounters, 'passengerCount' | 'childCount' | 'disabilityCount' | 'transportCount' | 'totalWeight'>
  )> }
);

export type BookingCountersQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingCountersQuery = (
  { __typename?: 'Query' }
  & { bookingCounters?: Maybe<(
    { __typename?: 'BookingCounters' }
    & Pick<BookingCounters, 'upcoming' | 'historical' | 'toConfirm'>
  )> }
);

export type BookingsToConfirmQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingsToConfirmQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bookingsToConfirm'>
);

export type BookingConversationsQueryVariables = Exact<{
  offset?: Maybe<Scalars['NonNegativeInt']>;
}>;


export type BookingConversationsQuery = (
  { __typename?: 'Query' }
  & { bookingConversations: Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'contactName' | 'contactPhone' | 'smsType' | 'smsUnread'>
    & { smsLatest?: Maybe<(
      { __typename?: 'SmsLatest' }
      & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'status' | 'error'>
    )> }
  )>> }
);

export type GetBookingsOfFlightQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type GetBookingsOfFlightQuery = (
  { __typename?: 'Query' }
  & { flight: (
    { __typename?: 'Flight' }
    & { bookings: (
      { __typename?: 'FlightBookings' }
      & Pick<FlightBookings, 'id'>
      & { list: Array<Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'id'>
      )>> }
    ) }
  ) }
);

export type GetBookingForPaymentFormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookingForPaymentFormQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'icon' | 'iconColor' | 'contactName' | 'contactPhone' | 'contactEmail' | 'comments' | 'invoiceName' | 'VATnumber' | 'prepaymentAmount' | 'prepaymentDate'>
    & { passengers: Array<Maybe<(
      { __typename?: 'Passenger' }
      & Pick<Passenger, 'id' | 'child' | 'name' | 'paymentAmount' | 'paymentReference' | 'paymentStatus' | 'paymentType' | 'paymentDate' | 'weight' | 'voucherNumber'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name' | 'description'>
      )>, voucher?: Maybe<(
        { __typename?: 'Voucher' }
        & Pick<Voucher, 'id' | 'voucherReference' | 'voucherType'>
      )> }
    )>>, passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'id' | 'openAmount' | 'paidAmount' | 'totalAmount'>
    )> }
  )> }
);

export type UpdateBookingCommentsMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  comments?: Maybe<Scalars['String']>;
}>;


export type UpdateBookingCommentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBookingComments'>
);

export type UpdateBookingTagsMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type UpdateBookingTagsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBookingTags'>
);

export type UpdateBookingPreferredFlightsMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  preferredFlights?: Maybe<Array<Maybe<PreferredFlightInput>> | Maybe<PreferredFlightInput>>;
}>;


export type UpdateBookingPreferredFlightsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBookingPreferredFlights'>
);

export type SaveBookingMutationVariables = Exact<{
  bookingInput: BookingInput;
  bookingId?: Maybe<Scalars['ID']>;
  locationInput?: Maybe<LocationInput>;
}>;


export type SaveBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveBooking'>
);

export type DeleteBookingMutationVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type DeleteBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBooking'>
);

export type CreateBookingMutationVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type CreateBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBooking'>
);

export type SavePassengerMutationVariables = Exact<{
  passengerInput: PassengerInput;
  passengerId?: Maybe<Scalars['ID']>;
  bookingId: Scalars['ID'];
}>;


export type SavePassengerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'savePassenger'>
);

export type DeletePassengerMutationVariables = Exact<{
  passengerId: Scalars['ID'];
  bookingId: Scalars['ID'];
}>;


export type DeletePassengerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePassenger'>
);

export type AddPassengersMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
}>;


export type AddPassengersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addPassengers'>
);

export type MarkBookingPaidMutationVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type MarkBookingPaidMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markBookingPaid'>
);

export type AddBookingsToFlightMutationVariables = Exact<{
  bookings: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  flightId: Scalars['ID'];
}>;


export type AddBookingsToFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addBookingsToFlight'>
);

export type InviteBookingsToFlightMutationVariables = Exact<{
  bookings: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  flightId: Scalars['ID'];
}>;


export type InviteBookingsToFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteBookingsToFlight'>
);

export type AddAndConfirmBookingsToFlightMutationVariables = Exact<{
  bookings: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  flightId: Scalars['ID'];
}>;


export type AddAndConfirmBookingsToFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addAndConfirmBookingsToFlight'>
);

export type ConfirmBookingToFlightMutationVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type ConfirmBookingToFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmBookingToFlight'>
);

export type RemoveBookingFromFlightMutationVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type RemoveBookingFromFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeBookingFromFlight'>
);

export type SaveBookingPaymentMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  bookingPaymentInput: BookingPaymentInput;
}>;


export type SaveBookingPaymentMutation = (
  { __typename?: 'Mutation' }
  & { saveBookingPayment: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'contactPhone' | 'contactEmail' | 'invoiceName' | 'VATnumber' | 'prepaymentDate' | 'prepaymentAmount'>
    & { passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'id' | 'openAmount' | 'totalAmount'>
    )> }
  ) }
);

export type SavePassengerPaymentMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  passengerId: Scalars['ID'];
  passengerPaymentInput: PassengerPaymentInput;
}>;


export type SavePassengerPaymentMutation = (
  { __typename?: 'Mutation' }
  & { savePassengerPayment?: Maybe<(
    { __typename?: 'Passenger' }
    & Pick<Passenger, 'id' | 'paymentReference' | 'child' | 'paymentStatus' | 'paymentType' | 'paymentDate' | 'paymentAmount' | 'voucherNumber'>
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
    )>, voucher?: Maybe<(
      { __typename?: 'Voucher' }
      & Pick<Voucher, 'id'>
    )> }
  )> }
);

export type UpdatePassengerPaymentStatusMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  passengerId: Scalars['ID'];
  paymentStatus: PaymentStatus;
}>;


export type UpdatePassengerPaymentStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatePassengerPaymentStatus?: Maybe<(
    { __typename?: 'Passenger' }
    & Pick<Passenger, 'id' | 'paymentStatus' | 'paymentDate'>
  )> }
);

export type UpdateBookingPaymentMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  paymentType: PaymentType;
}>;


export type UpdateBookingPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingPayment?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'contactEmail' | 'contactPhone' | 'prepaymentAmount' | 'prepaymentDate'>
    & { passengerCounters?: Maybe<(
      { __typename?: 'PassengerCounters' }
      & Pick<PassengerCounters, 'openAmount'>
    )>, passengers: Array<Maybe<(
      { __typename?: 'Passenger' }
      & Pick<Passenger, 'id' | 'paymentReference' | 'paymentStatus' | 'paymentType' | 'paymentDate' | 'voucherNumber'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
      )>, voucher?: Maybe<(
        { __typename?: 'Voucher' }
        & Pick<Voucher, 'id'>
      )> }
    )>> }
  )> }
);

export type MergeBookingsMutationVariables = Exact<{
  bookingIdFrom: Scalars['ID'];
  bookingIdTo: Scalars['ID'];
}>;


export type MergeBookingsMutation = (
  { __typename?: 'Mutation' }
  & { mergeBookings?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
  )> }
);

export type DeleteLogMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  logId: Scalars['ID'];
}>;


export type DeleteLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLog'>
);

export type BookingCountersUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BookingCountersUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { bookingCountersUpdated?: Maybe<(
    { __typename?: 'BookingCounters' }
    & Pick<BookingCounters, 'upcoming' | 'historical' | 'toConfirm'>
  )> }
);

export type CalendarQueryVariables = Exact<{
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
}>;


export type CalendarQuery = (
  { __typename?: 'Query' }
  & { calendar: (
    { __typename?: 'Calendar' }
    & { flights?: Maybe<Array<Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id' | 'date' | 'hour' | 'period' | 'status' | 'passengersCount'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'id' | 'name' | 'callSign' | 'capacity'>
      )>, groundCrews: Array<Maybe<(
        { __typename?: 'CrewInFlight' }
        & Pick<CrewInFlight, 'status'>
        & { crew?: Maybe<(
          { __typename?: 'CrewPerFlight' }
          & Pick<CrewPerFlight, 'id' | 'name' | 'isPilot'>
        )> }
      )>>, location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
      )>, pilot?: Maybe<(
        { __typename?: 'CrewInFlight' }
        & Pick<CrewInFlight, 'status'>
        & { crew?: Maybe<(
          { __typename?: 'CrewPerFlight' }
          & Pick<CrewPerFlight, 'id' | 'name' | 'isCurrent' | 'isPilot'>
        )> }
      )> }
    )>>>, events?: Maybe<Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'description' | 'date' | 'type' | 'availability' | 'period'>
      & { crew?: Maybe<(
        { __typename?: 'Crew' }
        & Pick<Crew, 'id' | 'name' | 'isCurrent' | 'isPilot'>
      )> }
    )>>> }
  ) }
);

export type CrewCalendarQueryVariables = Exact<{
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  userId?: Maybe<Scalars['String']>;
}>;


export type CrewCalendarQuery = (
  { __typename?: 'Query' }
  & { crewCalendar: Array<Maybe<(
    { __typename?: 'CrewCalendarItem' }
    & Pick<CrewCalendarItem, 'start'>
    & { meta?: Maybe<(
      { __typename?: 'CrewCalendarDay' }
      & { flightsMorning?: Maybe<(
        { __typename?: 'CrewDayContent' }
        & Pick<CrewDayContent, 'totalFlights'>
        & { crewEvent?: Maybe<(
          { __typename?: 'Event' }
          & Pick<Event, 'id' | 'date' | 'type' | 'availability' | 'period'>
        )>, selectedFlights?: Maybe<Array<Maybe<(
          { __typename?: 'Flight' }
          & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status'>
          & { balloon?: Maybe<(
            { __typename?: 'Balloon' }
            & Pick<Balloon, 'id' | 'name'>
          )>, pilot?: Maybe<(
            { __typename?: 'CrewInFlight' }
            & { crew?: Maybe<(
              { __typename?: 'CrewPerFlight' }
              & Pick<CrewPerFlight, 'name'>
            )> }
          )>, location?: Maybe<(
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'city' | 'name'>
            & { region: Array<Maybe<(
              { __typename?: 'Region' }
              & Pick<Region, 'name' | 'id' | 'color'>
            )>> }
          )> }
        )>>> }
      )>, flightsEvening?: Maybe<(
        { __typename?: 'CrewDayContent' }
        & Pick<CrewDayContent, 'totalFlights'>
        & { crewEvent?: Maybe<(
          { __typename?: 'Event' }
          & Pick<Event, 'id' | 'date' | 'type' | 'availability' | 'period'>
        )>, selectedFlights?: Maybe<Array<Maybe<(
          { __typename?: 'Flight' }
          & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status'>
          & { balloon?: Maybe<(
            { __typename?: 'Balloon' }
            & Pick<Balloon, 'name'>
          )>, pilot?: Maybe<(
            { __typename?: 'CrewInFlight' }
            & { crew?: Maybe<(
              { __typename?: 'CrewPerFlight' }
              & Pick<CrewPerFlight, 'name'>
            )> }
          )>, location?: Maybe<(
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'city' | 'name'>
            & { region: Array<Maybe<(
              { __typename?: 'Region' }
              & Pick<Region, 'name' | 'id' | 'color'>
            )>> }
          )> }
        )>>> }
      )> }
    )> }
  )>> }
);

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'child' | 'default' | 'description' | 'name' | 'price'>
  )>> }
);

export type GetCategoryQueryVariables = Exact<{
  categoryId: Scalars['ID'];
}>;


export type GetCategoryQuery = (
  { __typename?: 'Query' }
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'child' | 'default' | 'description' | 'name' | 'price'>
  ) }
);

export type SaveCategoryMutationVariables = Exact<{
  categoryInput: CategoryInput;
  categoryId?: Maybe<Scalars['ID']>;
}>;


export type SaveCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveCategory'>
);

export type DeleteCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID'];
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCategory'>
);

export type PassengerRegionDistributionQueryVariables = Exact<{ [key: string]: never; }>;


export type PassengerRegionDistributionQuery = (
  { __typename?: 'Query' }
  & { passengerRegionDistribution?: Maybe<(
    { __typename?: 'Chart' }
    & Pick<Chart, 'type' | 'data'>
  )> }
);

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'country'>
  )>> }
);

export type GetCrewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCrewsQuery = (
  { __typename?: 'Query' }
  & { crews: Array<Maybe<(
    { __typename?: 'Crew' }
    & Pick<Crew, 'driverLicense' | 'groundCrew' | 'id' | 'isCurrent' | 'isPilot' | 'language' | 'licenseExpiryDate' | 'licenseGas' | 'licenseGroupA' | 'licenseGroupB' | 'licenseGroupC' | 'licenseGroupD' | 'medicalExpiryDate' | 'name' | 'phone' | 'pilotLicense'>
  )>> }
);

export type GetCrewQueryVariables = Exact<{
  crewId: Scalars['ID'];
}>;


export type GetCrewQuery = (
  { __typename?: 'Query' }
  & { crew: (
    { __typename?: 'Crew' }
    & Pick<Crew, 'driverLicense' | 'email' | 'emailNotifications' | 'groundCrew' | 'id' | 'isCurrent' | 'isPilot' | 'language' | 'licenseExpiryDate' | 'licenseExtraCrew' | 'licenseGas' | 'licenseGroupA' | 'licenseGroupB' | 'licenseGroupC' | 'licenseGroupD' | 'licenseNumber' | 'medicalExpiryDate' | 'medicalWeight' | 'name' | 'phone' | 'pilotLicense' | 'smsType' | 'smsUnread' | 'userRole'>
    & { smsLatest?: Maybe<(
      { __typename?: 'SmsLatest' }
      & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'status' | 'error'>
    )> }
  ) }
);

export type CrewConversationsQueryVariables = Exact<{
  offset?: Maybe<Scalars['NonNegativeInt']>;
}>;


export type CrewConversationsQuery = (
  { __typename?: 'Query' }
  & { crewConversations: Array<Maybe<(
    { __typename?: 'Crew' }
    & Pick<Crew, 'id' | 'name' | 'phone' | 'smsType' | 'smsUnread'>
    & { smsLatest?: Maybe<(
      { __typename?: 'SmsLatest' }
      & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'status' | 'error'>
    )> }
  )>> }
);

export type CrewsPerFlightQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type CrewsPerFlightQuery = (
  { __typename?: 'Query' }
  & { crewsPerFlight: Array<Maybe<(
    { __typename?: 'CrewPerFlight' }
    & Pick<CrewPerFlight, 'availability' | 'groundCrew' | 'id' | 'isCurrent' | 'isPilot' | 'name' | 'pilotLicense' | 'popularity' | 'mostPopular'>
    & { otherFlight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'name'>
      )> }
    )> }
  )>> }
);

export type PilotsPerFlightQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type PilotsPerFlightQuery = (
  { __typename?: 'Query' }
  & { crewsPerFlight: Array<Maybe<(
    { __typename?: 'CrewPerFlight' }
    & Pick<CrewPerFlight, 'availability' | 'groundCrew' | 'id' | 'isCurrent' | 'isPilot' | 'name' | 'pilotLicense' | 'popularity'>
    & { otherFlight?: Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id'>
      & { balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'name'>
      )> }
    )> }
  )>> }
);

export type GetAllCrewForDialogQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCrewForDialogQuery = (
  { __typename?: 'Query' }
  & { crews: Array<Maybe<(
    { __typename?: 'Crew' }
    & Pick<Crew, 'id' | 'name' | 'isCurrent' | 'isPilot'>
  )>> }
);

export type SaveCrewMutationVariables = Exact<{
  crewInput: CrewInput;
  crewId?: Maybe<Scalars['ID']>;
}>;


export type SaveCrewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveCrew'>
);

export type DeleteCrewMutationVariables = Exact<{
  crewId: Scalars['ID'];
}>;


export type DeleteCrewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCrew'>
);

export type GetCylindersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCylindersQuery = (
  { __typename?: 'Query' }
  & { cylinders: Array<Maybe<(
    { __typename?: 'Cylinder' }
    & Pick<Cylinder, 'id' | 'number' | 'emptyWeight' | 'fullWeight' | 'manufacturer' | 'serialNumber' | 'volume'>
  )>> }
);

export type GetCylinderQueryVariables = Exact<{
  cylinderId: Scalars['ID'];
}>;


export type GetCylinderQuery = (
  { __typename?: 'Query' }
  & { cylinder: (
    { __typename?: 'Cylinder' }
    & Pick<Cylinder, 'emptyWeight' | 'fullWeight' | 'id' | 'manufacturer' | 'serialNumber' | 'volume'>
  ) }
);

export type SaveCylinderMutationVariables = Exact<{
  cylinderInput: CylinderInput;
  cylinderId?: Maybe<Scalars['ID']>;
}>;


export type SaveCylinderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveCylinder'>
);

export type DeleteCylinderMutationVariables = Exact<{
  cylinderId: Scalars['ID'];
}>;


export type DeleteCylinderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCylinder'>
);

export type GetAllEmailTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEmailTemplatesQuery = (
  { __typename?: 'Query' }
  & { getAllEmailTemplates: Array<Maybe<(
    { __typename?: 'EmailSettingsTemplate' }
    & Pick<EmailSettingsTemplate, 'id' | 'type' | 'title'>
    & { template?: Maybe<(
      { __typename?: 'EmailLanguages' }
      & { en?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, fr?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, nl?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, de?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )> }
    )> }
  )>> }
);

export type ParseEmailTemplateQueryVariables = Exact<{
  emailBody: Scalars['String'];
  language: Scalars['String'];
  flightPeriod: Scalars['String'];
}>;


export type ParseEmailTemplateQuery = (
  { __typename?: 'Query' }
  & { parseEmailTemplate: (
    { __typename?: 'EmailTemplateByLanguage' }
    & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
    & { attachments?: Maybe<Array<Maybe<(
      { __typename?: 'EmailAttachment' }
      & Pick<EmailAttachment, 'filename' | 'reference'>
    )>>> }
  ) }
);

export type GetEmailTemplateQueryVariables = Exact<{
  type: EmailTemplateType;
}>;


export type GetEmailTemplateQuery = (
  { __typename?: 'Query' }
  & { getEmailTemplate: (
    { __typename?: 'EmailSettingsTemplate' }
    & Pick<EmailSettingsTemplate, 'id' | 'title' | 'type'>
    & { template?: Maybe<(
      { __typename?: 'EmailLanguages' }
      & { en?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, fr?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, nl?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, de?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )> }
    )> }
  ) }
);

export type GetEmailTemplateByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEmailTemplateByIdQuery = (
  { __typename?: 'Query' }
  & { getEmailTemplateByID: (
    { __typename?: 'EmailSettingsTemplate' }
    & Pick<EmailSettingsTemplate, 'id' | 'title' | 'type'>
    & { template?: Maybe<(
      { __typename?: 'EmailLanguages' }
      & { en?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, fr?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, nl?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, de?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )> }
    )> }
  ) }
);

export type GetEmailTemplatesQueryVariables = Exact<{
  type: EmailTemplateType;
}>;


export type GetEmailTemplatesQuery = (
  { __typename?: 'Query' }
  & { getEmailTemplates: Array<Maybe<(
    { __typename?: 'EmailSettingsTemplate' }
    & Pick<EmailSettingsTemplate, 'id' | 'title' | 'type'>
    & { template?: Maybe<(
      { __typename?: 'EmailLanguages' }
      & { en?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, fr?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, nl?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, de?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )> }
    )> }
  )>> }
);

export type GetEmailTemplateByLanguageQueryVariables = Exact<{
  type: EmailTemplateType;
  lang: ContactLanguage;
}>;


export type GetEmailTemplateByLanguageQuery = (
  { __typename?: 'Query' }
  & { getEmailTemplateByLanguage: (
    { __typename?: 'EmailTemplateByLanguage' }
    & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
  ) }
);

export type AddEmailOutMutationVariables = Exact<{
  emailInput: EmailInput;
}>;


export type AddEmailOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addEmailOut'>
);

export type CopyEmailTemplateMutationVariables = Exact<{
  emailTemplateId: Scalars['ID'];
}>;


export type CopyEmailTemplateMutation = (
  { __typename?: 'Mutation' }
  & { copyEmailTemplate?: Maybe<(
    { __typename?: 'EmailSettingsTemplate' }
    & Pick<EmailSettingsTemplate, 'id' | 'type' | 'title'>
    & { template?: Maybe<(
      { __typename?: 'EmailLanguages' }
      & { en?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, fr?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, nl?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, de?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )> }
    )> }
  )> }
);

export type AddEmailTemplateLocationContactMutationVariables = Exact<{ [key: string]: never; }>;


export type AddEmailTemplateLocationContactMutation = (
  { __typename?: 'Mutation' }
  & { addEmailTemplateLocationContact?: Maybe<(
    { __typename?: 'EmailSettingsTemplate' }
    & Pick<EmailSettingsTemplate, 'id' | 'type' | 'title'>
    & { template?: Maybe<(
      { __typename?: 'EmailLanguages' }
      & { en?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, fr?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, nl?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, de?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )> }
    )> }
  )> }
);

export type DeleteEmailTemplateMutationVariables = Exact<{
  emailTemplateId: Scalars['ID'];
}>;


export type DeleteEmailTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEmailTemplate'>
);

export type SaveEmailTemplateMutationVariables = Exact<{
  emailTemplateInput?: Maybe<EmailTemplateInput>;
  emailTemplateId: Scalars['ID'];
}>;


export type SaveEmailTemplateMutation = (
  { __typename?: 'Mutation' }
  & { saveEmailTemplate?: Maybe<(
    { __typename?: 'EmailSettingsTemplate' }
    & Pick<EmailSettingsTemplate, 'id' | 'type' | 'title'>
    & { template?: Maybe<(
      { __typename?: 'EmailLanguages' }
      & { en?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, fr?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, nl?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )>, de?: Maybe<(
        { __typename?: 'EmailTemplateByLanguage' }
        & Pick<EmailTemplateByLanguage, 'body' | 'subject'>
        & { attachments?: Maybe<Array<Maybe<(
          { __typename?: 'EmailAttachment' }
          & Pick<EmailAttachment, 'filename' | 'reference'>
        )>>> }
      )> }
    )> }
  )> }
);

export type GetEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsQuery = (
  { __typename?: 'Query' }
  & { events: Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'description' | 'date'>
  )>> }
);

export type DeleteEventMutationVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEvent'>
);

export type SaveEventMutationVariables = Exact<{
  eventInput: EventInput;
  eventId?: Maybe<Scalars['ID']>;
}>;


export type SaveEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveEvent'>
);

export type SaveCrewAvailabilityMutationVariables = Exact<{
  crewAvailabilityInput: CrewAvailabilityInput;
  eventId?: Maybe<Scalars['ID']>;
}>;


export type SaveCrewAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveCrewAvailability'>
);

export type SetMonthAvailabilityMutationVariables = Exact<{
  availability: CrewAvailability;
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  crewId: Scalars['ID'];
}>;


export type SetMonthAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setMonthAvailability'>
);

export type DeleteMonthAvailabilityEventsMutationVariables = Exact<{
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  crewId: Scalars['ID'];
}>;


export type DeleteMonthAvailabilityEventsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMonthAvailabilityEvents'>
);

export type FetchHolidaysMutationVariables = Exact<{
  year: Scalars['PositiveInt'];
  countryCode?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
}>;


export type FetchHolidaysMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'fetchHolidays'>
);

export type GetFlightQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type GetFlightQuery = (
  { __typename?: 'Query' }
  & { flight: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'comment' | 'date' | 'hour' | 'hourMeeting' | 'id' | 'landingLocation' | 'landingTime' | 'landingDetails' | 'landingLatitude' | 'landingLongitude' | 'liftAvailable' | 'liftDataMismatch' | 'liftNeeded' | 'liftPassengers' | 'liftSpare' | 'locationType' | 'meetingPointSameAsTakeOff' | 'meetingPointAddress' | 'meetingPointLatitude' | 'meetingPointLongitude' | 'meetingPointURL' | 'meetingPointCity' | 'maximumFlightTime' | 'maximumHeight' | 'period' | 'pilotComments' | 'plannedFlightTime' | 'status' | 'takeoffTime' | 'tempBalloon' | 'tempMax' | 'tempTakeoff' | 'vfrClouds' | 'vfrVisual' | 'visibility' | 'weatherForecastDate' | 'weatherPmax' | 'weatherQnh' | 'weatherSource' | 'wind10' | 'wind10Direction' | 'wind20' | 'wind20Direction' | 'windGl' | 'windGlDirection' | 'windSummary' | 'refuelVolume' | 'passengersCount' | 'passengersOccupancy' | 'flightTime' | 'flightDistance' | 'flightHeading'>
    & { icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'color' | 'sign'>
    ), groundCrews: Array<Maybe<(
      { __typename?: 'CrewInFlight' }
      & Pick<CrewInFlight, 'status'>
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'availability' | 'email' | 'emailNotifications' | 'id' | 'language' | 'name' | 'pilotLicense'>
      )> }
    )>>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & Pick<CrewInFlight, 'status'>
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isCurrent' | 'isPilot' | 'name' | 'language' | 'email' | 'emailNotifications' | 'pilotLicense' | 'medicalWeight' | 'phone' | 'totalWeight'>
      )> }
    )>, flightsAtSameDay: (
      { __typename?: 'FlightsAtSameDay' }
      & Pick<FlightsAtSameDay, 'count' | 'names'>
      & { flights: Array<Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'id'>
      )>> }
    ), flightDuplicates?: Maybe<Array<Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id'>
    )>>>, balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'balloonTemp' | 'basketWeight' | 'burnerWeight' | 'callSign' | 'capacity' | 'envelopeType' | 'envelopeVolume' | 'envelopeWeight' | 'id' | 'isCurrent' | 'name'>
      & { balloonWeight?: Maybe<(
        { __typename?: 'BalloonWeight' }
        & Pick<BalloonWeight, 'cylindersWeight'>
      )> }
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'address' | 'city' | 'country' | 'meetingPointSameAsTakeOff' | 'meetingPointAddress' | 'meetingPointLatitude' | 'meetingPointLongitude' | 'meetingPointURL' | 'meetingPointCity' | 'description' | 'height' | 'id' | 'latitude' | 'longitude' | 'maximumHeight' | 'name' | 'postalCode'>
      & { contact?: Maybe<(
        { __typename?: 'LocationContact' }
        & Pick<LocationContact, 'comment' | 'email' | 'emailTemplate' | 'name' | 'phone' | 'language' | 'smsTemplate' | 'media'>
      )>, region: Array<Maybe<(
        { __typename?: 'Region' }
        & Pick<Region, 'id' | 'name'>
      )>> }
    )>, locationEmail?: Maybe<(
      { __typename?: 'MailEventLatest' }
      & Pick<MailEventLatest, 'date' | 'event'>
    )>, locationSmsLatest?: Maybe<(
      { __typename?: 'SmsLatest' }
      & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'status' | 'error'>
    )>, sunriseSunset?: Maybe<(
      { __typename?: 'SunriseSunset' }
      & Pick<SunriseSunset, 'sunrise' | 'sunset' | 'morningFlight' | 'eveningFlight'>
    )>, meteo?: Maybe<(
      { __typename?: 'MeteoblueForecastSlot' }
      & Pick<MeteoblueForecastSlot, 'slot' | 'tempTakeoff' | 'vfrVisual' | 'weatherQnh' | 'wind10' | 'wind10Direction' | 'wind20' | 'wind20Direction' | 'windGl' | 'windGlDirection'>
      & { forecast?: Maybe<(
        { __typename?: 'slotForecast' }
        & Pick<SlotForecast, 'daytime' | 'flytime' | 'lastUpdate' | 'latitude' | 'longitude' | 'lowCloudsData' | 'pictoCodeData' | 'precipitationData' | 'pressureData' | 'sunrise' | 'sunset' | 'temperatureData' | 'time' | 'utc_timeoffset' | 'visibilityData' | 'windDirectionData' | 'windDirectionData_1000' | 'windDirectionData_500' | 'windDirectionData_550' | 'windDirectionData_600' | 'windDirectionData_650' | 'windDirectionData_700' | 'windDirectionData_750' | 'windDirectionData_800' | 'windDirectionData_925' | 'windDirectionData_950' | 'windDirectionData_975' | 'windGustData' | 'windSpeedData' | 'windSpeedData_1000' | 'windSpeedData_500' | 'windSpeedData_550' | 'windSpeedData_600' | 'windSpeedData_650' | 'windSpeedData_700' | 'windSpeedData_750' | 'windSpeedData_800' | 'windSpeedData_925' | 'windSpeedData_950' | 'windSpeedData_975'>
        & { datepart?: Maybe<Array<Maybe<(
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'part' | 'text'>
        )>>> }
      )> }
    )>, bookings: (
      { __typename?: 'FlightBookings' }
      & Pick<FlightBookings, 'id' | 'addedCount' | 'notConfirmedCount' | 'notConfirmedNames'>
      & { list: Array<Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'icon' | 'iconColor' | 'inviteCount' | 'city' | 'contactName' | 'contactPhone' | 'contactEmail' | 'contactLanguage' | 'comments' | 'status' | 'prepaymentAmount' | 'prepaymentDate' | 'invoiceName' | 'VATnumber' | 'mismatchReasons' | 'locationType' | 'smsType' | 'smsUnread' | 'upUrl'>
        & { region?: Maybe<(
          { __typename?: 'Region' }
          & Pick<Region, 'name'>
        )>, location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'address' | 'city' | 'description' | 'height' | 'id' | 'latitude' | 'longitude' | 'maximumHeight' | 'name'>
        )>, passengers: Array<Maybe<(
          { __typename?: 'Passenger' }
          & Pick<Passenger, 'age' | 'transport' | 'child' | 'disability' | 'luggage' | 'name' | 'paymentAmount' | 'paymentReference' | 'paymentStatus' | 'paymentType' | 'totalWeight' | 'weight' | 'voucherNumber'>
          & { category?: Maybe<(
            { __typename?: 'Category' }
            & Pick<Category, 'name' | 'description'>
          )>, voucher?: Maybe<(
            { __typename?: 'Voucher' }
            & Pick<Voucher, 'voucherReference'>
          )> }
        )>>, passengerCounters?: Maybe<(
          { __typename?: 'PassengerCounters' }
          & Pick<PassengerCounters, 'id' | 'childCount' | 'disabilityCount' | 'transportCount' | 'openAmount' | 'paidAmount' | 'passengerCount' | 'totalAmount' | 'totalWeight' | 'unpaidPassengerCount'>
        )>, smsLatest?: Maybe<(
          { __typename?: 'SmsLatest' }
          & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'status' | 'error'>
        )>, tags?: Maybe<Array<Maybe<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
        )>>> }
      )>> }
    ), passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'id' | 'passengersCountAdded' | 'passengersCountInvited' | 'passengersCountConfirmed' | 'passengersWeight'>
    ), logs?: Maybe<Array<Maybe<(
      { __typename?: 'BookingLog' }
      & Pick<BookingLog, 'date'>
    )>>>, trajectory?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, trajectoryCustom?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, trajectory1000?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, trajectory950?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, trajectory925?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, trajectory800?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, trajectory700?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, trajectory600?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, trajectory500?: Maybe<Array<Maybe<(
      { __typename?: 'Mapslocation' }
      & Pick<Mapslocation, 'lat' | 'lng' | 'alt'>
    )>>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>>, tagsData?: Maybe<Array<Maybe<(
      { __typename?: 'TagData' }
      & Pick<TagData, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>>, skeyesForecast?: Maybe<(
      { __typename?: 'FlightSkeyesForecast' }
      & Pick<FlightSkeyesForecast, 'username' | 'date'>
      & { forecast?: Maybe<(
        { __typename?: 'SkeyesForecast' }
        & Pick<SkeyesForecast, 'datetime' | 'full' | 'inversions' | 'issued' | 'outlook' | 'period' | 'slot' | 'weather' | 'winds'>
      )> }
    )> }
  ) }
);

export type GetFlightDashboardQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type GetFlightDashboardQuery = (
  { __typename?: 'Query' }
  & { flight: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'date' | 'hour' | 'id' | 'period' | 'status' | 'liftSpare'>
    & { icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'color' | 'sign'>
    ), meteo?: Maybe<(
      { __typename?: 'MeteoblueForecastSlot' }
      & Pick<MeteoblueForecastSlot, 'tempTakeoff' | 'weatherQnh' | 'wind10' | 'wind10Direction' | 'wind20' | 'wind20Direction' | 'windGl' | 'windGlDirection'>
    )>, passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'id' | 'passengersCountAdded' | 'passengersCountInvited' | 'passengersCountConfirmed' | 'passengersWeight'>
    ), pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & Pick<CrewInFlight, 'status'>
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isCurrent' | 'isPilot' | 'name' | 'language' | 'email' | 'emailNotifications' | 'pilotLicense' | 'medicalWeight' | 'phone' | 'totalWeight'>
      )> }
    )>, balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, tagsData?: Maybe<Array<Maybe<(
      { __typename?: 'TagData' }
      & Pick<TagData, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>>, bookings: (
      { __typename?: 'FlightBookings' }
      & Pick<FlightBookings, 'id' | 'addedCount' | 'notConfirmedCount' | 'notConfirmedNames'>
      & { list: Array<Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'icon' | 'iconColor' | 'inviteCount' | 'city' | 'contactName' | 'contactPhone' | 'contactEmail' | 'contactLanguage' | 'comments' | 'status' | 'prepaymentAmount' | 'prepaymentDate' | 'invoiceName' | 'VATnumber' | 'mismatchReasons' | 'locationType' | 'smsType' | 'smsUnread' | 'upUrl'>
        & { region?: Maybe<(
          { __typename?: 'Region' }
          & Pick<Region, 'name'>
        )>, location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'address' | 'city' | 'description' | 'height' | 'id' | 'latitude' | 'longitude' | 'maximumHeight' | 'name'>
        )>, passengers: Array<Maybe<(
          { __typename?: 'Passenger' }
          & Pick<Passenger, 'age' | 'transport' | 'child' | 'disability' | 'luggage' | 'name' | 'paymentAmount' | 'paymentReference' | 'paymentStatus' | 'paymentType' | 'totalWeight' | 'weight' | 'voucherNumber'>
          & { category?: Maybe<(
            { __typename?: 'Category' }
            & Pick<Category, 'name' | 'description'>
          )>, voucher?: Maybe<(
            { __typename?: 'Voucher' }
            & Pick<Voucher, 'voucherReference'>
          )> }
        )>>, passengerCounters?: Maybe<(
          { __typename?: 'PassengerCounters' }
          & Pick<PassengerCounters, 'id' | 'childCount' | 'disabilityCount' | 'transportCount' | 'openAmount' | 'paidAmount' | 'passengerCount' | 'totalAmount' | 'totalWeight' | 'unpaidPassengerCount'>
        )>, smsLatest?: Maybe<(
          { __typename?: 'SmsLatest' }
          & Pick<SmsLatest, 'date' | 'direction' | 'summary' | 'status' | 'error'>
        )>, tags?: Maybe<Array<Maybe<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
        )>>> }
      )>> }
    ) }
  ) }
);

export type GetFlightLogsQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type GetFlightLogsQuery = (
  { __typename?: 'Query' }
  & { flight: (
    { __typename?: 'Flight' }
    & { logs?: Maybe<Array<Maybe<(
      { __typename?: 'BookingLog' }
      & Pick<BookingLog, 'id' | 'date' | 'user' | 'action'>
      & { booking?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'contactName' | 'removed' | 'status'>
      )> }
    )>>> }
  ) }
);

export type GetBookingFlightQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type GetBookingFlightQuery = (
  { __typename?: 'Query' }
  & { flight: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period'>
    & { icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'name'>
      )> }
    )>, balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'passengersCountConfirmed'>
    ) }
  ) }
);

export type GetMobileFlightQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type GetMobileFlightQuery = (
  { __typename?: 'Query' }
  & { flight: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'comment' | 'date' | 'hour' | 'hourMeeting' | 'id' | 'landingTime' | 'landingDetails' | 'landingLatitude' | 'landingLongitude' | 'landingLocation' | 'liftAvailable' | 'liftNeeded' | 'liftSpare' | 'meetingPointSameAsTakeOff' | 'meetingPointAddress' | 'meetingPointLatitude' | 'meetingPointLongitude' | 'meetingPointURL' | 'meetingPointCity' | 'maximumFlightTime' | 'period' | 'plannedFlightTime' | 'spareFlightTime' | 'takeoffTime' | 'tempTakeoff' | 'weatherQnh' | 'wind10' | 'wind10Direction' | 'wind20' | 'wind20Direction' | 'windGl' | 'windGlDirection' | 'windSummary' | 'refuelVolume' | 'pilotComments' | 'pilotSignature' | 'pilotSignOffDate'>
    & { icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isCurrent' | 'isPilot' | 'licenseExpiryDate' | 'medicalExpiryDate' | 'name'>
      )> }
    )>, balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'capacity' | 'envelopeInspectionDate' | 'envelopeType' | 'insuranceDueDate' | 'isCurrent' | 'name'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'address' | 'name'>
    )>, passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'passengersCountConfirmed'>
    ), bookings: (
      { __typename?: 'FlightBookings' }
      & { list: Array<Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'comments' | 'contactLanguage' | 'contactName' | 'contactPhone' | 'id' | 'status'>
        & { passengerCounters?: Maybe<(
          { __typename?: 'PassengerCounters' }
          & Pick<PassengerCounters, 'openAmount'>
        )>, passengers: Array<Maybe<(
          { __typename?: 'Passenger' }
          & Pick<Passenger, 'accompany' | 'address' | 'age' | 'transport' | 'child' | 'city' | 'country' | 'disability' | 'email' | 'id' | 'luggage' | 'name' | 'paymentAmount' | 'paymentReference' | 'paymentStatus' | 'paymentType' | 'postalCode' | 'signature' | 'weight' | 'voucherNumber'>
          & { category?: Maybe<(
            { __typename?: 'Category' }
            & Pick<Category, 'id' | 'name' | 'description'>
          )> }
        )>> }
      )>> }
    ) }
  ) }
);

export type GetCertificateFlightQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type GetCertificateFlightQuery = (
  { __typename?: 'Query' }
  & { flight: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'date'>
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name'>
    )>, bookings: (
      { __typename?: 'FlightBookings' }
      & { list: Array<Maybe<(
        { __typename?: 'Booking' }
        & { passengers: Array<Maybe<(
          { __typename?: 'Passenger' }
          & Pick<Passenger, 'name'>
        )>> }
      )>> }
    ) }
  ) }
);

export type GetFlightsOfDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFlightsOfDashboardQuery = (
  { __typename?: 'Query' }
  & { flightsOfDashboard: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'period' | 'date'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'isCurrent'>
    )> }
  )>> }
);

export type GetUpcomingFlightsQueryVariables = Exact<{
  orderBy?: Maybe<OrderByFlight>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
}>;


export type GetUpcomingFlightsQuery = (
  { __typename?: 'Query' }
  & { upcomingFlights: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'comment' | 'passengersCount' | 'passengersOccupancy'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'city' | 'address'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isPilot' | 'isCurrent' | 'name'>
      )> }
    )>, groundCrews: Array<Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'name'>
      )> }
    )>>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), bookings: (
      { __typename?: 'FlightBookings' }
      & Pick<FlightBookings, 'id' | 'count'>
      & { list: Array<Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'status' | 'contactName'>
        & { passengers: Array<Maybe<(
          { __typename?: 'Passenger' }
          & Pick<Passenger, 'id' | 'name'>
        )>> }
      )>> }
    ), passengersData?: Maybe<(
      { __typename?: 'PassengerData' }
      & Pick<PassengerData, 'passengersCount' | 'passengersWeight' | 'freeSpots' | 'occupancy'>
    )>, events: Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'description' | 'date'>
    )>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>>, tagsData?: Maybe<Array<Maybe<(
      { __typename?: 'TagData' }
      & Pick<TagData, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )>> }
);

export type GetUpcomingFlightQueryVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type GetUpcomingFlightQuery = (
  { __typename?: 'Query' }
  & { flight: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'comment' | 'passengersCount' | 'passengersOccupancy'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'city' | 'address'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'isPilot' | 'isCurrent' | 'name'>
      )> }
    )>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), bookings: (
      { __typename?: 'FlightBookings' }
      & Pick<FlightBookings, 'id' | 'count'>
      & { list: Array<Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'status' | 'contactName'>
        & { passengers: Array<Maybe<(
          { __typename?: 'Passenger' }
          & Pick<Passenger, 'name'>
        )>> }
      )>> }
    ), passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'id' | 'passengersWeight' | 'freeSpots'>
    ), passengersData?: Maybe<(
      { __typename?: 'PassengerData' }
      & Pick<PassengerData, 'passengersCount' | 'passengersWeight' | 'freeSpots' | 'occupancy'>
    )>, events: Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'description' | 'date'>
    )>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>>, tagsData?: Maybe<Array<Maybe<(
      { __typename?: 'TagData' }
      & Pick<TagData, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  ) }
);

export type GetScheduledFlightsQueryVariables = Exact<{
  orderBy?: Maybe<OrderByFlight>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
}>;


export type GetScheduledFlightsQuery = (
  { __typename?: 'Query' }
  & { scheduledFlights: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'period'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name'>
    )> }
  )>> }
);

export type GetFlightsListQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filterRange?: Maybe<FilterRangeInput>;
}>;


export type GetFlightsListQuery = (
  { __typename?: 'Query' }
  & { getFlightsList: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'comment'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'city' | 'address'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isPilot' | 'isCurrent' | 'name'>
      )> }
    )>, groundCrews: Array<Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'name'>
      )> }
    )>>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), bookingsData?: Maybe<(
      { __typename?: 'BookingsData' }
      & Pick<BookingsData, 'id' | 'count'>
      & { list?: Maybe<Array<Maybe<(
        { __typename?: 'BookingData' }
        & Pick<BookingData, 'id' | 'status' | 'icon' | 'iconColor' | 'contactName'>
        & { passengers?: Maybe<Array<Maybe<(
          { __typename?: 'PassengerData' }
          & Pick<PassengerData, 'name'>
        )>>> }
      )>>> }
    )>, passengersData?: Maybe<(
      { __typename?: 'PassengerData' }
      & Pick<PassengerData, 'passengersCount' | 'passengersWeight' | 'freeSpots' | 'occupancy'>
    )>, eventsData?: Maybe<Array<Maybe<(
      { __typename?: 'EventData' }
      & Pick<EventData, 'id' | 'description'>
    )>>>, tagsData?: Maybe<Array<Maybe<(
      { __typename?: 'TagData' }
      & Pick<TagData, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )>> }
);

export type GetFlightsListSummaryQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filterRange?: Maybe<FilterRangeInput>;
}>;


export type GetFlightsListSummaryQuery = (
  { __typename?: 'Query' }
  & { getFlightsList: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'passengersCount' | 'passengersOccupancy'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'name'>
      )> }
    )>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), passengersData?: Maybe<(
      { __typename?: 'PassengerData' }
      & Pick<PassengerData, 'passengersCount' | 'passengersWeight' | 'freeSpots' | 'occupancy'>
    )> }
  )>> }
);

export type GetHistoricalFlightsListQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filterRange?: Maybe<FilterRangeInput>;
}>;


export type GetHistoricalFlightsListQuery = (
  { __typename?: 'Query' }
  & { getHistoricalFlightsList: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'comment' | 'passengersCount' | 'passengersOccupancy'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'city' | 'address'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isPilot' | 'isCurrent' | 'name'>
      )> }
    )>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), bookingsData?: Maybe<(
      { __typename?: 'BookingsData' }
      & Pick<BookingsData, 'id' | 'count'>
      & { list?: Maybe<Array<Maybe<(
        { __typename?: 'BookingData' }
        & Pick<BookingData, 'id' | 'status' | 'contactName'>
        & { passengers?: Maybe<Array<Maybe<(
          { __typename?: 'PassengerData' }
          & Pick<PassengerData, 'id' | 'name'>
        )>>> }
      )>>> }
    )>, passengersData?: Maybe<(
      { __typename?: 'PassengerData' }
      & Pick<PassengerData, 'passengersCount' | 'passengersWeight' | 'freeSpots' | 'occupancy'>
    )>, events: Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'description' | 'date'>
    )>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>>, tagsData?: Maybe<Array<Maybe<(
      { __typename?: 'TagData' }
      & Pick<TagData, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  )>> }
);

export type GetHistoricalFlightsListSummaryQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filterRange?: Maybe<FilterRangeInput>;
}>;


export type GetHistoricalFlightsListSummaryQuery = (
  { __typename?: 'Query' }
  & { getHistoricalFlightsList: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'passengersCount' | 'passengersOccupancy'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'name'>
      )> }
    )>, groundCrews: Array<Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'name'>
      )> }
    )>>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), passengersData?: Maybe<(
      { __typename?: 'PassengerData' }
      & Pick<PassengerData, 'passengersCount' | 'passengersWeight' | 'freeSpots' | 'occupancy'>
    )> }
  )>> }
);

export type GetFlightsListFromDateQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetFlightsListFromDateQuery = (
  { __typename?: 'Query' }
  & { flightsOfDate: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'comment' | 'passengersCount' | 'passengersOccupancy'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'city' | 'address'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isPilot' | 'isCurrent' | 'name'>
      )> }
    )>, groundCrews: Array<Maybe<(
      { __typename?: 'CrewInFlight' }
      & Pick<CrewInFlight, 'status'>
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'availability' | 'id' | 'name' | 'isPilot'>
      )> }
    )>>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), bookingsData?: Maybe<(
      { __typename?: 'BookingsData' }
      & Pick<BookingsData, 'count'>
      & { list?: Maybe<Array<Maybe<(
        { __typename?: 'BookingData' }
        & Pick<BookingData, 'id' | 'status' | 'contactName'>
        & { passengers?: Maybe<Array<Maybe<(
          { __typename?: 'PassengerData' }
          & Pick<PassengerData, 'name'>
        )>>> }
      )>>> }
    )>, passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'passengersWeight' | 'freeSpots'>
    ), events: Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'description' | 'date'>
    )>>, eventsData?: Maybe<Array<Maybe<(
      { __typename?: 'EventData' }
      & Pick<EventData, 'id' | 'description'>
    )>>> }
  )>> }
);

export type GetFlightsListFromDateCrewQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetFlightsListFromDateCrewQuery = (
  { __typename?: 'Query' }
  & { flightsOfDateCrew: Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'comment'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'city' | 'address'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isPilot' | 'isCurrent' | 'name'>
      )> }
    )>, groundCrews: Array<Maybe<(
      { __typename?: 'CrewInFlight' }
      & Pick<CrewInFlight, 'status'>
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'availability' | 'id' | 'name' | 'isPilot'>
      )> }
    )>>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ) }
  )>> }
);

export type FlightCountersQueryVariables = Exact<{ [key: string]: never; }>;


export type FlightCountersQuery = (
  { __typename?: 'Query' }
  & { flightCounters?: Maybe<(
    { __typename?: 'FlightCounters' }
    & Pick<FlightCounters, 'upcoming' | 'historical'>
  )> }
);

export type FlightPeriodsQueryVariables = Exact<{ [key: string]: never; }>;


export type FlightPeriodsQuery = (
  { __typename?: 'Query' }
  & { flightPeriods?: Maybe<(
    { __typename?: 'FlightPeriods' }
    & { day?: Maybe<Array<Maybe<(
      { __typename?: 'FlightPeriodCountersPerPeriod' }
      & Pick<FlightPeriodCountersPerPeriod, 'id' | 'startDate' | 'endDate' | 'label'>
      & { EVE?: Maybe<(
        { __typename?: 'FlightPeriodCounters' }
        & Pick<FlightPeriodCounters, 'flightsCount'>
      )>, MOR?: Maybe<(
        { __typename?: 'FlightPeriodCounters' }
        & Pick<FlightPeriodCounters, 'flightsCount'>
      )> }
    )>>>, week?: Maybe<Array<Maybe<(
      { __typename?: 'FlightPeriodCountersPerPeriod' }
      & Pick<FlightPeriodCountersPerPeriod, 'id' | 'startDate' | 'endDate' | 'label'>
      & { EVE?: Maybe<(
        { __typename?: 'FlightPeriodCounters' }
        & Pick<FlightPeriodCounters, 'flightsCount'>
      )>, MOR?: Maybe<(
        { __typename?: 'FlightPeriodCounters' }
        & Pick<FlightPeriodCounters, 'flightsCount'>
      )> }
    )>>>, month?: Maybe<Array<Maybe<(
      { __typename?: 'FlightPeriodCountersPerPeriod' }
      & Pick<FlightPeriodCountersPerPeriod, 'id' | 'startDate' | 'endDate' | 'label'>
      & { EVE?: Maybe<(
        { __typename?: 'FlightPeriodCounters' }
        & Pick<FlightPeriodCounters, 'flightsCount'>
      )>, MOR?: Maybe<(
        { __typename?: 'FlightPeriodCounters' }
        & Pick<FlightPeriodCounters, 'flightsCount'>
      )> }
    )>>> }
  )> }
);

export type FlightCountersUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type FlightCountersUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { flightCountersUpdated?: Maybe<(
    { __typename?: 'FlightCounters' }
    & Pick<FlightCounters, 'upcoming' | 'historical'>
  )> }
);

export type SaveFlightMutationVariables = Exact<{
  flightInput: FlightInput;
  flightId?: Maybe<Scalars['ID']>;
}>;


export type SaveFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveFlight'>
);

export type DeleteFlightMutationVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type DeleteFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFlight'>
);

export type ToggleFlightVisibilityMutationVariables = Exact<{
  flightId: Scalars['ID'];
}>;


export type ToggleFlightVisibilityMutation = (
  { __typename?: 'Mutation' }
  & { toggleFlightVisibility: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'visibility'>
  ) }
);

export type ChangeFlightStatusMutationVariables = Exact<{
  flightId: Scalars['ID'];
  flightStatus: FlightStatus;
}>;


export type ChangeFlightStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeFlightStatus: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'status'>
  ) }
);

export type SaveFlightCrewMutationVariables = Exact<{
  flightCrewInput?: Maybe<Array<Maybe<FlightCrewInput>> | Maybe<FlightCrewInput>>;
  flightId: Scalars['ID'];
}>;


export type SaveFlightCrewMutation = (
  { __typename?: 'Mutation' }
  & { saveFlightCrew: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'hour' | 'period' | 'status' | 'passengersCount'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'callSign' | 'capacity'>
    )>, groundCrews: Array<Maybe<(
      { __typename?: 'CrewInFlight' }
      & Pick<CrewInFlight, 'status'>
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'name' | 'isPilot'>
      )> }
    )>>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & Pick<CrewInFlight, 'status'>
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'name' | 'isPilot' | 'isCurrent'>
      )> }
    )> }
  ) }
);

export type AddCrewToFlightFromCalendarMutationVariables = Exact<{
  flightCrewInput?: Maybe<FlightCrewInput>;
  flightId: Scalars['ID'];
}>;


export type AddCrewToFlightFromCalendarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCrewToFlightFromCalendar'>
);

export type SavePilotSignOffMutationVariables = Exact<{
  pilotSignOffInput: PilotSignOffInput;
  flightId: Scalars['ID'];
}>;


export type SavePilotSignOffMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'savePilotSignOff'>
);

export type SaveFlightLandingMutationVariables = Exact<{
  landingInput: LandingInput;
  flightId: Scalars['ID'];
}>;


export type SaveFlightLandingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveFlightLanding'>
);

export type CopyWeatherMutationVariables = Exact<{
  sourceFlightId: Scalars['ID'];
  destinationFlightIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type CopyWeatherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyWeather'>
);

export type CopyFlightMutationVariables = Exact<{
  sourceFlightId: Scalars['ID'];
  destinationDates?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  timezoneOffset?: Maybe<Scalars['Int']>;
}>;


export type CopyFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyFlight'>
);

export type UpdateFlightCommentsMutationVariables = Exact<{
  flightId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
}>;


export type UpdateFlightCommentsMutation = (
  { __typename?: 'Mutation' }
  & { updateFlightComments: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'comment' | 'passengersCount' | 'passengersOccupancy'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'city' | 'address'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isPilot' | 'isCurrent' | 'name'>
      )> }
    )>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), bookingsData?: Maybe<(
      { __typename?: 'BookingsData' }
      & Pick<BookingsData, 'id' | 'count'>
      & { list?: Maybe<Array<Maybe<(
        { __typename?: 'BookingData' }
        & Pick<BookingData, 'id' | 'status' | 'contactName'>
        & { passengers?: Maybe<Array<Maybe<(
          { __typename?: 'PassengerData' }
          & Pick<PassengerData, 'name'>
        )>>> }
      )>>> }
    )>, passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'id' | 'passengersWeight' | 'freeSpots'>
    ), events: Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'description' | 'date'>
    )>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>>, tagsData?: Maybe<Array<Maybe<(
      { __typename?: 'TagData' }
      & Pick<TagData, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  ) }
);

export type UpdateFlightTagsMutationVariables = Exact<{
  flightId: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type UpdateFlightTagsMutation = (
  { __typename?: 'Mutation' }
  & { updateFlightTags: (
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'period' | 'hour' | 'status' | 'visibility' | 'comment' | 'passengersCount' | 'passengersOccupancy'>
    & { balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'id' | 'name' | 'capacity' | 'isCurrent'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'city' | 'address'>
    )>, pilot?: Maybe<(
      { __typename?: 'CrewInFlight' }
      & { crew?: Maybe<(
        { __typename?: 'CrewPerFlight' }
        & Pick<CrewPerFlight, 'id' | 'isPilot' | 'isCurrent' | 'name'>
      )> }
    )>, icon: (
      { __typename?: 'Icon' }
      & Pick<Icon, 'sign' | 'color'>
    ), bookingsData?: Maybe<(
      { __typename?: 'BookingsData' }
      & Pick<BookingsData, 'id' | 'count'>
      & { list?: Maybe<Array<Maybe<(
        { __typename?: 'BookingData' }
        & Pick<BookingData, 'id' | 'status' | 'contactName'>
        & { passengers?: Maybe<Array<Maybe<(
          { __typename?: 'PassengerData' }
          & Pick<PassengerData, 'name'>
        )>>> }
      )>>> }
    )>, passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'id' | 'passengersWeight' | 'freeSpots'>
    ), passengersData?: Maybe<(
      { __typename?: 'PassengerData' }
      & Pick<PassengerData, 'passengersCount' | 'passengersWeight' | 'freeSpots' | 'occupancy'>
    )>, events: Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'description' | 'date'>
    )>>, tags?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>>, tagsData?: Maybe<Array<Maybe<(
      { __typename?: 'TagData' }
      & Pick<TagData, 'id' | 'tag' | 'backgroundColor' | 'fontColor'>
    )>>> }
  ) }
);

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { locations: Array<Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'address' | 'city' | 'country' | 'default' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'meetingPointSameAsTakeOff' | 'meetingPointAddress' | 'meetingPointLatitude' | 'meetingPointLongitude' | 'meetingPointURL' | 'meetingPointCity'>
    & { region: Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'name'>
    )>>, contact?: Maybe<(
      { __typename?: 'LocationContact' }
      & Pick<LocationContact, 'name' | 'media'>
    )> }
  )>> }
);

export type GetLocationQueryVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'address' | 'city' | 'country' | 'default' | 'description' | 'height' | 'latitude' | 'longitude' | 'maximumHeight' | 'meetingPointSameAsTakeOff' | 'meetingPointAddress' | 'meetingPointLatitude' | 'meetingPointLongitude' | 'meetingPointURL' | 'meetingPointCity' | 'name' | 'postalCode'>
    & { contact?: Maybe<(
      { __typename?: 'LocationContact' }
      & Pick<LocationContact, 'comment' | 'email' | 'emailTemplate' | 'name' | 'phone' | 'smsTemplate' | 'language' | 'media'>
    )>, forecasts?: Maybe<Array<Maybe<(
      { __typename?: 'MeteoblueForecastSlot' }
      & Pick<MeteoblueForecastSlot, 'slot'>
      & { forecast?: Maybe<(
        { __typename?: 'slotForecast' }
        & Pick<SlotForecast, 'daytime' | 'flytime' | 'lastUpdate' | 'latitude' | 'longitude' | 'lowCloudsData' | 'pictoCodeData' | 'precipitationData' | 'pressureData' | 'sunrise' | 'sunset' | 'temperatureData' | 'time' | 'utc_timeoffset' | 'visibilityData' | 'windDirectionData' | 'windDirectionData_1000' | 'windDirectionData_500' | 'windDirectionData_550' | 'windDirectionData_600' | 'windDirectionData_650' | 'windDirectionData_700' | 'windDirectionData_750' | 'windDirectionData_800' | 'windDirectionData_925' | 'windDirectionData_950' | 'windDirectionData_975' | 'windGustData' | 'windSpeedData' | 'windSpeedData_1000' | 'windSpeedData_500' | 'windSpeedData_550' | 'windSpeedData_600' | 'windSpeedData_650' | 'windSpeedData_700' | 'windSpeedData_750' | 'windSpeedData_800' | 'windSpeedData_925' | 'windSpeedData_950' | 'windSpeedData_975'>
        & { datepart?: Maybe<Array<Maybe<(
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'part' | 'text'>
        )>>> }
      )> }
    )>>>, region: Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'color' | 'id' | 'name'>
    )>> }
  ) }
);

export type GetGeocodeQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type GetGeocodeQuery = (
  { __typename?: 'Query' }
  & { geocode: Array<Maybe<(
    { __typename?: 'GeocodeResult' }
    & { geometry?: Maybe<(
      { __typename?: 'Geometry' }
      & { location?: Maybe<(
        { __typename?: 'Mapslocation' }
        & Pick<Mapslocation, 'lat' | 'lng'>
      )> }
    )>, elevation?: Maybe<Array<Maybe<(
      { __typename?: 'ElevationResult' }
      & Pick<ElevationResult, 'elevation'>
    )>>> }
  )>> }
);

export type ReverseGeocodeQueryVariables = Exact<{
  lat: Scalars['Float'];
  lng: Scalars['Float'];
}>;


export type ReverseGeocodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'reverseGeocode'>
);

export type SaveLocationMutationVariables = Exact<{
  locationInput: LocationInput;
  regions: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
}>;


export type SaveLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveLocation'>
);

export type DeleteLocationMutationVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLocation'>
);

export type UpdateMeteoMutationVariables = Exact<{
  locationId: Scalars['ID'];
  flightId: Scalars['ID'];
}>;


export type UpdateMeteoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMeteo'>
);

export type GetSkeyesForecastQueryVariables = Exact<{
  slot: Scalars['String'];
}>;


export type GetSkeyesForecastQuery = (
  { __typename?: 'Query' }
  & { getSkeyesForecast?: Maybe<(
    { __typename?: 'SkeyesForecast' }
    & Pick<SkeyesForecast, 'id' | 'datetime' | 'full' | 'inversions' | 'issued' | 'outlook' | 'period' | 'slot' | 'weather' | 'winds'>
  )> }
);

export type FetchSkeyesForecastQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  flightId: Scalars['ID'];
}>;


export type FetchSkeyesForecastQuery = (
  { __typename?: 'Query' }
  & { fetchSkeyesForecast?: Maybe<(
    { __typename?: 'FlightSkeyesForecast' }
    & Pick<FlightSkeyesForecast, 'username' | 'date'>
    & { forecast?: Maybe<(
      { __typename?: 'SkeyesForecast' }
      & Pick<SkeyesForecast, 'datetime' | 'full' | 'inversions' | 'issued' | 'outlook' | 'period' | 'slot' | 'weather' | 'winds'>
    )> }
  )> }
);

export type QuestionsQueryVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
}>;


export type QuestionsQuery = (
  { __typename?: 'Query' }
  & { questions: Array<Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'active' | 'code' | 'kind'>
    & { content?: Maybe<(
      { __typename?: 'QuestionContent' }
      & Pick<QuestionContent, 'order'>
      & { description?: Maybe<Array<Maybe<(
        { __typename?: 'MLText' }
        & Pick<MlText, 'lang' | 'text'>
      )>>>, title: Array<Maybe<(
        { __typename?: 'MLText' }
        & Pick<MlText, 'lang' | 'text'>
      )>> }
    )> }
  )>> }
);

export type SaveQuestionTextMutationVariables = Exact<{
  questionInput: QuestionInput;
  questionId: Scalars['ID'];
}>;


export type SaveQuestionTextMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveQuestionText'>
);

export type SaveQuestionOrderMutationVariables = Exact<{
  order: Scalars['NonNegativeInt'];
  questionId: Scalars['ID'];
}>;


export type SaveQuestionOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveQuestionOrder'>
);

export type ToggleQuestionActiveMutationVariables = Exact<{
  questionId: Scalars['ID'];
}>;


export type ToggleQuestionActiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleQuestionActive'>
);

export type GetRegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegionsQuery = (
  { __typename?: 'Query' }
  & { regions: Array<Maybe<(
    { __typename?: 'Region' }
    & Pick<Region, 'color' | 'defaultColor' | 'id' | 'name'>
    & { locations: Array<Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'id'>
    )>> }
  )>> }
);

export type GetRegionQueryVariables = Exact<{
  regionId: Scalars['ID'];
}>;


export type GetRegionQuery = (
  { __typename?: 'Query' }
  & { region: (
    { __typename?: 'Region' }
    & Pick<Region, 'color' | 'defaultColor' | 'name' | 'id'>
    & { locations: Array<Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name' | 'latitude' | 'longitude' | 'id'>
    )>> }
  ) }
);

export type SaveRegionMutationVariables = Exact<{
  regionInput: RegionInput;
  regionId?: Maybe<Scalars['ID']>;
}>;


export type SaveRegionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveRegion'>
);

export type DeleteRegionMutationVariables = Exact<{
  regionId: Scalars['ID'];
}>;


export type DeleteRegionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRegion'>
);

export type GetReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportsQuery = (
  { __typename?: 'Query' }
  & { reports?: Maybe<(
    { __typename?: 'Reports' }
    & Pick<Reports, 'flights' | 'bookings' | 'bookingsOpen' | 'vouchers' | 'payments' | 'sms'>
  )> }
);

export type ServerStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ServerStatusQuery = (
  { __typename?: 'Query' }
  & { serverStatus?: Maybe<(
    { __typename?: 'ServerStatus' }
    & Pick<ServerStatus, 'maintenance' | 'start' | 'purpose' | 'expectedDuration'>
  )> }
);

export type SaveLanguageSettingMutationVariables = Exact<{
  systemLanguage: Scalars['String'];
}>;


export type SaveLanguageSettingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveLanguageSetting'>
);

export type SmsByBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type SmsByBookingQuery = (
  { __typename?: 'Query' }
  & { smsByBooking?: Maybe<Array<Maybe<(
    { __typename?: 'Sms' }
    & Pick<Sms, 'direction' | 'text'>
    & { date?: Maybe<(
      { __typename?: 'SmsDates' }
      & Pick<SmsDates, 'sms' | 'read'>
    )>, eventSummary?: Maybe<(
      { __typename?: 'SmsEventSummary' }
      & Pick<SmsEventSummary, 'status' | 'error'>
    )> }
  )>>> }
);

export type SmsByCrewQueryVariables = Exact<{
  crewId: Scalars['ID'];
}>;


export type SmsByCrewQuery = (
  { __typename?: 'Query' }
  & { smsByCrew?: Maybe<Array<Maybe<(
    { __typename?: 'Sms' }
    & Pick<Sms, 'direction' | 'text'>
    & { date?: Maybe<(
      { __typename?: 'SmsDates' }
      & Pick<SmsDates, 'sms' | 'read'>
    )>, eventSummary?: Maybe<(
      { __typename?: 'SmsEventSummary' }
      & Pick<SmsEventSummary, 'status' | 'error'>
    )> }
  )>>> }
);

export type UnreadSmsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnreadSmsQuery = (
  { __typename?: 'Query' }
  & { unreadSms: Array<Maybe<(
    { __typename?: 'Sms' }
    & Pick<Sms, 'text' | 'path'>
    & { date?: Maybe<(
      { __typename?: 'SmsDates' }
      & Pick<SmsDates, 'sms'>
    )> }
  )>> }
);

export type GetAllSmsTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSmsTemplatesQuery = (
  { __typename?: 'Query' }
  & { getAllSmsTemplates: Array<Maybe<(
    { __typename?: 'SmsTemplate' }
    & Pick<SmsTemplate, 'id' | 'type'>
    & { template: (
      { __typename?: 'Template' }
      & Pick<Template, 'en' | 'fr' | 'nl' | 'de'>
    ) }
  )>> }
);

export type GetSmsTemplatesQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type GetSmsTemplatesQuery = (
  { __typename?: 'Query' }
  & { getSmsTemplates: Array<Maybe<(
    { __typename?: 'SmsTemplate' }
    & { template: (
      { __typename?: 'Template' }
      & Pick<Template, 'en' | 'fr' | 'nl' | 'de'>
    ) }
  )>> }
);

export type MarkBookingMessagesReadMutationVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type MarkBookingMessagesReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markBookingMessagesRead'>
);

export type AddSmsTemplateLocationContactMutationVariables = Exact<{ [key: string]: never; }>;


export type AddSmsTemplateLocationContactMutation = (
  { __typename?: 'Mutation' }
  & { addSmsTemplateLocationContact?: Maybe<(
    { __typename?: 'SmsTemplate' }
    & Pick<SmsTemplate, 'id' | 'type'>
    & { template: (
      { __typename?: 'Template' }
      & Pick<Template, 'en' | 'fr' | 'nl' | 'de'>
    ) }
  )> }
);

export type MarkCrewMessagesReadMutationVariables = Exact<{
  crewId: Scalars['ID'];
}>;


export type MarkCrewMessagesReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markCrewMessagesRead'>
);

export type SaveSmsTemplateMutationVariables = Exact<{
  smsTemplateInput: SmsTemplateInput;
  smsTemplateId: Scalars['ID'];
}>;


export type SaveSmsTemplateMutation = (
  { __typename?: 'Mutation' }
  & { saveSmsTemplate?: Maybe<(
    { __typename?: 'SmsTemplate' }
    & Pick<SmsTemplate, 'id' | 'type'>
    & { template: (
      { __typename?: 'Template' }
      & Pick<Template, 'en' | 'fr' | 'nl' | 'de'>
    ) }
  )> }
);

export type SendBookingSmsMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  smsInput: SmsInput;
}>;


export type SendBookingSmsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendBookingSms'>
);

export type SendLocationSmsMutationVariables = Exact<{
  locationId: Scalars['ID'];
  flightId: Scalars['ID'];
  smsInput: SmsInput;
}>;


export type SendLocationSmsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendLocationSms'>
);

export type SendCrewSmsMutationVariables = Exact<{
  crewId: Scalars['ID'];
  smsInput: SmsInput;
}>;


export type SendCrewSmsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendCrewSms'>
);

export type NewSmsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewSmsSubscription = (
  { __typename?: 'Subscription' }
  & { newSms?: Maybe<Array<Maybe<(
    { __typename?: 'Sms' }
    & Pick<Sms, 'text' | 'path'>
    & { date?: Maybe<(
      { __typename?: 'SmsDates' }
      & Pick<SmsDates, 'sms'>
    )> }
  )>>> }
);

export type SunriseSunsetQueryVariables = Exact<{
  sunriseSunsetInput: SunriseSunsetInput;
}>;


export type SunriseSunsetQuery = (
  { __typename?: 'Query' }
  & { sunriseSunset: (
    { __typename?: 'SunriseSunset' }
    & Pick<SunriseSunset, 'sunrise' | 'sunset' | 'morningFlight' | 'eveningFlight'>
  ) }
);

export type GetAllTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTagsQuery = (
  { __typename?: 'Query' }
  & { getAllTags: Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'type' | 'backgroundColor' | 'fontColor' | 'enabled' | 'locked' | 'dashboard'>
  )>> }
);

export type GetAllTagsWithCountersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTagsWithCountersQuery = (
  { __typename?: 'Query' }
  & { getAllTags: Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'type' | 'backgroundColor' | 'fontColor' | 'enabled' | 'upcoming' | 'history' | 'locked' | 'dashboard'>
  )>> }
);

export type GetTagQueryVariables = Exact<{
  tagId: Scalars['ID'];
}>;


export type GetTagQuery = (
  { __typename?: 'Query' }
  & { getTag: (
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'type' | 'backgroundColor' | 'fontColor' | 'enabled' | 'locked' | 'dashboard'>
  ) }
);

export type GetTagWithCountersQueryVariables = Exact<{
  tagId: Scalars['ID'];
}>;


export type GetTagWithCountersQuery = (
  { __typename?: 'Query' }
  & { getTag: (
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'type' | 'backgroundColor' | 'fontColor' | 'enabled' | 'upcoming' | 'history' | 'locked' | 'dashboard'>
  ) }
);

export type GetTagsOfTypeQueryVariables = Exact<{
  tagType: TagType;
}>;


export type GetTagsOfTypeQuery = (
  { __typename?: 'Query' }
  & { getTagsOfType: Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'type' | 'backgroundColor' | 'fontColor' | 'enabled' | 'locked' | 'dashboard'>
  )>> }
);

export type GetTagsOfTypeWithCountersQueryVariables = Exact<{
  tagType: TagType;
}>;


export type GetTagsOfTypeWithCountersQuery = (
  { __typename?: 'Query' }
  & { getTagsOfType: Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'type' | 'backgroundColor' | 'fontColor' | 'enabled' | 'upcoming' | 'history' | 'locked' | 'dashboard'>
  )>> }
);

export type GetTagsOfTypeWithUpcomingCountersQueryVariables = Exact<{
  tagType: TagType;
}>;


export type GetTagsOfTypeWithUpcomingCountersQuery = (
  { __typename?: 'Query' }
  & { getTagsOfType: Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'type' | 'backgroundColor' | 'fontColor' | 'enabled' | 'upcoming' | 'locked'>
  )>> }
);

export type GetTagsOfTypeWithHistoryCountersQueryVariables = Exact<{
  tagType: TagType;
}>;


export type GetTagsOfTypeWithHistoryCountersQuery = (
  { __typename?: 'Query' }
  & { getTagsOfType: Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'type' | 'backgroundColor' | 'fontColor' | 'enabled' | 'history' | 'locked'>
  )>> }
);

export type SaveTagMutationVariables = Exact<{
  tagInput: TagInput;
  tagId?: Maybe<Scalars['ID']>;
}>;


export type SaveTagMutation = (
  { __typename?: 'Mutation' }
  & { saveTag: (
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag' | 'backgroundColor' | 'fontColor' | 'type' | 'enabled' | 'locked' | 'dashboard'>
  ) }
);

export type DeleteTagMutationVariables = Exact<{
  tagId: Scalars['ID'];
}>;


export type DeleteTagMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTag'>
);

export type GetTenantSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantSettingQuery = (
  { __typename?: 'Query' }
  & { tenantSetting?: Maybe<(
    { __typename?: 'TenantSetting' }
    & Pick<TenantSetting, 'upEnabled' | 'icsId' | 'tenantId' | 'name'>
    & { mailFrom?: Maybe<(
      { __typename?: 'EmailAddressObject' }
      & Pick<EmailAddressObject, 'email' | 'name'>
    )>, mailReplyTo?: Maybe<(
      { __typename?: 'EmailAddressObject' }
      & Pick<EmailAddressObject, 'email' | 'name'>
    )>, mailAccountant?: Maybe<(
      { __typename?: 'EmailAddressObject' }
      & Pick<EmailAddressObject, 'email' | 'name'>
    )>, locationOptions?: Maybe<(
      { __typename?: 'LocationOptions' }
      & { defaultLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id'>
      )> }
    )>, operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & Pick<OperatorSettings, 'currency' | 'timezone'>
      & { backup?: Maybe<(
        { __typename?: 'OperatorSettingsBackup' }
        & Pick<OperatorSettingsBackup, 'active' | 'email'>
      )>, bookings?: Maybe<(
        { __typename?: 'OperatorSettingsBookings' }
        & Pick<OperatorSettingsBookings, 'childAge' | 'showPayments' | 'disabledPaymentTypes'>
      )>, crew?: Maybe<(
        { __typename?: 'OperatorSettingsCrew' }
        & Pick<OperatorSettingsCrew, 'shareBookings' | 'allowFlightSelection'>
      )>, flights?: Maybe<(
        { __typename?: 'OperatorSettingsFlights' }
        & Pick<OperatorSettingsFlights, 'sunriseOffset' | 'sunsetOffset' | 'meetingTimeOffset'>
      )>, sms?: Maybe<(
        { __typename?: 'OperatorSettingsSms' }
        & Pick<OperatorSettingsSms, 'footerEnabled'>
      )>, manifest?: Maybe<(
        { __typename?: 'OperatorSettingsManifest' }
        & Pick<OperatorSettingsManifest, 'footer' | 'pilotDeclaration'>
      )>, myflight?: Maybe<(
        { __typename?: 'OperatorSettingsMyflight' }
        & Pick<OperatorSettingsMyflight, 'autoConfirm' | 'flights' | 'endOfSeason'>
      )>, trajectoryProfile?: Maybe<Array<Maybe<(
        { __typename?: 'TrajectoryProfile' }
        & Pick<TrajectoryProfile, 'duration' | 'level'>
      )>>> }
    )>, dashboardOptions?: Maybe<(
      { __typename?: 'DashboardOptions' }
      & Pick<DashboardOptions, 'preferredDateToday' | 'recent' | 'toConfirm'>
    )>, subscription?: Maybe<(
      { __typename?: 'Subscription' }
      & { sms?: Maybe<(
        { __typename?: 'SubscriptionSMS' }
        & Pick<SubscriptionSms, 'available' | 'pctUsed' | 'ignoreWarning'>
        & { usedSMS?: Maybe<Array<Maybe<(
          { __typename?: 'usedSMS' }
          & Pick<UsedSms, 'year' | 'month' | 'used'>
        )>>> }
      )> }
    )> }
  )> }
);

export type GetAllReleaseNotesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllReleaseNotesQuery = (
  { __typename?: 'Query' }
  & { allReleaseNotes?: Maybe<Array<Maybe<(
    { __typename?: 'ReleaseNote' }
    & Pick<ReleaseNote, 'id' | 'date' | 'description' | 'documentation' | 'oneliner' | 'video' | 'tenants'>
  )>>> }
);

export type GetReleaseNotesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReleaseNotesQuery = (
  { __typename?: 'Query' }
  & { releaseNotes?: Maybe<Array<Maybe<(
    { __typename?: 'ReleaseNote' }
    & Pick<ReleaseNote, 'id' | 'date' | 'description' | 'documentation' | 'oneliner' | 'video'>
  )>>> }
);

export type GetReleaseNoteQueryVariables = Exact<{
  releaseNoteId: Scalars['ID'];
}>;


export type GetReleaseNoteQuery = (
  { __typename?: 'Query' }
  & { releaseNote?: Maybe<(
    { __typename?: 'ReleaseNote' }
    & Pick<ReleaseNote, 'id' | 'date' | 'description' | 'documentation' | 'oneliner' | 'video'>
  )> }
);

export type GetReleaseNotesCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReleaseNotesCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'releaseNotesCount'>
);

export type GetTenantSettingMyFlightQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantSettingMyFlightQuery = (
  { __typename?: 'Query' }
  & { tenantSetting?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { myflight?: Maybe<(
        { __typename?: 'OperatorSettingsMyflight' }
        & Pick<OperatorSettingsMyflight, 'autoConfirm' | 'flights' | 'endOfSeason' | 'colors' | 'logo'>
      )> }
    )> }
  )> }
);

export type GetSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionQuery = (
  { __typename?: 'Query' }
  & { tenantSetting?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { subscription?: Maybe<(
      { __typename?: 'Subscription' }
      & { sms?: Maybe<(
        { __typename?: 'SubscriptionSMS' }
        & Pick<SubscriptionSms, 'available' | 'pctUsed' | 'ignoreWarning'>
        & { usedSMS?: Maybe<Array<Maybe<(
          { __typename?: 'usedSMS' }
          & Pick<UsedSms, 'year' | 'month' | 'used'>
        )>>> }
      )>, plan?: Maybe<(
        { __typename?: 'SubscriptionPlan' }
        & Pick<SubscriptionPlan, 'name' | 'seats' | 'used' | 'pctUsed' | 'validUntil' | 'valid'>
      )> }
    )> }
  )> }
);

export type GetDefaultLocationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultLocationQuery = (
  { __typename?: 'Query' }
  & { tenantSetting?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { locationOptions?: Maybe<(
      { __typename?: 'LocationOptions' }
      & { defaultLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'address' | 'city' | 'country' | 'default' | 'description' | 'height' | 'latitude' | 'longitude' | 'maximumHeight' | 'name' | 'postalCode'>
        & { forecasts?: Maybe<Array<Maybe<(
          { __typename?: 'MeteoblueForecastSlot' }
          & Pick<MeteoblueForecastSlot, 'slot'>
          & { forecast?: Maybe<(
            { __typename?: 'slotForecast' }
            & Pick<SlotForecast, 'daytime' | 'flytime' | 'lastUpdate' | 'latitude' | 'longitude' | 'lowCloudsData' | 'pictoCodeData' | 'precipitationData' | 'pressureData' | 'sunrise' | 'sunset' | 'temperatureData' | 'time' | 'utc_timeoffset' | 'visibilityData' | 'windDirectionData' | 'windDirectionData_1000' | 'windDirectionData_500' | 'windDirectionData_550' | 'windDirectionData_600' | 'windDirectionData_650' | 'windDirectionData_700' | 'windDirectionData_750' | 'windDirectionData_800' | 'windDirectionData_925' | 'windDirectionData_950' | 'windDirectionData_975' | 'windGustData' | 'windSpeedData' | 'windSpeedData_1000' | 'windSpeedData_500' | 'windSpeedData_550' | 'windSpeedData_600' | 'windSpeedData_650' | 'windSpeedData_700' | 'windSpeedData_750' | 'windSpeedData_800' | 'windSpeedData_925' | 'windSpeedData_950' | 'windSpeedData_975'>
            & { datepart?: Maybe<Array<Maybe<(
              { __typename?: 'DatePart' }
              & Pick<DatePart, 'part' | 'text'>
            )>>> }
          )> }
        )>>> }
      )> }
    )> }
  )> }
);

export type GetUpUrlQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type GetUpUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'upUrl'>
);

export type UpdateTenantLocationOptionsMutationVariables = Exact<{
  options: InputLocationOptions;
}>;


export type UpdateTenantLocationOptionsMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantLocationOptions?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { locationOptions?: Maybe<(
      { __typename?: 'LocationOptions' }
      & { defaultLocation?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'id'>
      )> }
    )> }
  )> }
);

export type UpdateTenantOperatorSettingsBackupMutationVariables = Exact<{
  settings: InputOperatorSettingsBackup;
}>;


export type UpdateTenantOperatorSettingsBackupMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantOperatorSettingsBackup?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { backup?: Maybe<(
        { __typename?: 'OperatorSettingsBackup' }
        & Pick<OperatorSettingsBackup, 'active' | 'email'>
      )> }
    )> }
  )> }
);

export type UpdateTenantOperatorSettingsCrewMutationVariables = Exact<{
  settings: InputOperatorSettingsCrew;
}>;


export type UpdateTenantOperatorSettingsCrewMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantOperatorSettingsCrew?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { crew?: Maybe<(
        { __typename?: 'OperatorSettingsCrew' }
        & Pick<OperatorSettingsCrew, 'shareBookings' | 'allowFlightSelection'>
      )> }
    )> }
  )> }
);

export type UpdateTenantOperatorSettingsTrajectoryProfileMutationVariables = Exact<{
  settings: Array<Maybe<InputOperatorSettingsTrajectoryProfile>> | Maybe<InputOperatorSettingsTrajectoryProfile>;
}>;


export type UpdateTenantOperatorSettingsTrajectoryProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantOperatorSettingsTrajectoryProfile?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { trajectoryProfile?: Maybe<Array<Maybe<(
        { __typename?: 'TrajectoryProfile' }
        & Pick<TrajectoryProfile, 'duration' | 'level'>
      )>>> }
    )> }
  )> }
);

export type UpdateTenantOperatorSettingsMyFlightMutationVariables = Exact<{
  settings: InputOperatorSettingsMyFlight;
}>;


export type UpdateTenantOperatorSettingsMyFlightMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantOperatorSettingsMyFlight?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { myflight?: Maybe<(
        { __typename?: 'OperatorSettingsMyflight' }
        & Pick<OperatorSettingsMyflight, 'autoConfirm' | 'flights' | 'endOfSeason' | 'colors' | 'logo'>
      )> }
    )> }
  )> }
);

export type UpdateTenantOperatorSettingsFlightsMutationVariables = Exact<{
  settings: InputOperatorSettingsFlights;
}>;


export type UpdateTenantOperatorSettingsFlightsMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantOperatorSettingsFlights?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { flights?: Maybe<(
        { __typename?: 'OperatorSettingsFlights' }
        & Pick<OperatorSettingsFlights, 'sunriseOffset' | 'sunsetOffset' | 'meetingTimeOffset'>
      )> }
    )> }
  )> }
);

export type UpdateTenantOperatorSettingsSmsMutationVariables = Exact<{
  settings: InputOperatorSettingsSms;
}>;


export type UpdateTenantOperatorSettingsSmsMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantOperatorSettingsSms?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { sms?: Maybe<(
        { __typename?: 'OperatorSettingsSms' }
        & Pick<OperatorSettingsSms, 'footerEnabled'>
      )> }
    )> }
  )> }
);

export type UpdateTenantOperatorSettingsBookingsChildAgeMutationVariables = Exact<{
  childAge?: Maybe<Scalars['NonNegativeInt']>;
}>;


export type UpdateTenantOperatorSettingsBookingsChildAgeMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantOperatorSettingsBookingsChildAge?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { bookings?: Maybe<(
        { __typename?: 'OperatorSettingsBookings' }
        & Pick<OperatorSettingsBookings, 'childAge'>
      )> }
    )> }
  )> }
);

export type UpdateTenantOperatorSettingsDisabledPaymentTypesMutationVariables = Exact<{
  disabledPaymentTypes: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type UpdateTenantOperatorSettingsDisabledPaymentTypesMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantOperatorSettingsDisabledPaymentTypes?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { bookings?: Maybe<(
        { __typename?: 'OperatorSettingsBookings' }
        & Pick<OperatorSettingsBookings, 'disabledPaymentTypes'>
      )> }
    )> }
  )> }
);

export type ToggleTenantOperatorSettingsBookingsShowPaymentsMutationVariables = Exact<{ [key: string]: never; }>;


export type ToggleTenantOperatorSettingsBookingsShowPaymentsMutation = (
  { __typename?: 'Mutation' }
  & { toggleTenantOperatorSettingsBookingsShowPayments?: Maybe<(
    { __typename?: 'TenantSetting' }
    & { operatorSettings?: Maybe<(
      { __typename?: 'OperatorSettings' }
      & { bookings?: Maybe<(
        { __typename?: 'OperatorSettingsBookings' }
        & Pick<OperatorSettingsBookings, 'showPayments'>
      )> }
    )> }
  )> }
);

export type SubscriptionSmsIgnoreWarningMutationVariables = Exact<{
  ignore: Scalars['Boolean'];
}>;


export type SubscriptionSmsIgnoreWarningMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'subscriptionSmsIgnoreWarning'>
);

export type AddReleaseNoteMutationVariables = Exact<{
  releaseNote: InputReleaseNote;
}>;


export type AddReleaseNoteMutation = (
  { __typename?: 'Mutation' }
  & { addReleaseNote?: Maybe<(
    { __typename?: 'ReleaseNote' }
    & Pick<ReleaseNote, 'id' | 'date' | 'description' | 'documentation' | 'oneliner' | 'video'>
  )> }
);

export type UpdateReleaseNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  releaseNote: InputReleaseNote;
}>;


export type UpdateReleaseNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateReleaseNote?: Maybe<(
    { __typename?: 'ReleaseNote' }
    & Pick<ReleaseNote, 'id' | 'date' | 'description' | 'documentation' | 'oneliner' | 'video'>
  )> }
);

export type DeleteReleaseNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteReleaseNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReleaseNote'>
);

export type MarkReleaseNoteAsReadMutationVariables = Exact<{
  releaseNoteIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type MarkReleaseNoteAsReadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markReleaseNoteAsRead'>
);

export type CreateIcsIdMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateIcsIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createIcsId'>
);

export type GetTenantByDisplayNameQueryVariables = Exact<{
  displayName: Scalars['String'];
}>;


export type GetTenantByDisplayNameQuery = (
  { __typename?: 'Query' }
  & { getTenantByDisplayName: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'tenantId' | 'displayName'>
  ) }
);

export type GetTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantsQuery = (
  { __typename?: 'Query' }
  & { getTenants: Array<Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'tenantId' | 'displayName'>
    & { tenantSetting?: Maybe<(
      { __typename?: 'TenantSetting' }
      & Pick<TenantSetting, 'upEnabled' | 'tenantId'>
      & { mailFrom?: Maybe<(
        { __typename?: 'EmailAddressObject' }
        & Pick<EmailAddressObject, 'email' | 'name'>
      )>, mailReplyTo?: Maybe<(
        { __typename?: 'EmailAddressObject' }
        & Pick<EmailAddressObject, 'email' | 'name'>
      )>, mailAccountant?: Maybe<(
        { __typename?: 'EmailAddressObject' }
        & Pick<EmailAddressObject, 'email' | 'name'>
      )>, locationOptions?: Maybe<(
        { __typename?: 'LocationOptions' }
        & { defaultLocation?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'id'>
        )> }
      )>, operatorSettings?: Maybe<(
        { __typename?: 'OperatorSettings' }
        & Pick<OperatorSettings, 'timezone'>
        & { backup?: Maybe<(
          { __typename?: 'OperatorSettingsBackup' }
          & Pick<OperatorSettingsBackup, 'active' | 'email'>
        )>, crew?: Maybe<(
          { __typename?: 'OperatorSettingsCrew' }
          & Pick<OperatorSettingsCrew, 'shareBookings' | 'allowFlightSelection'>
        )>, sms?: Maybe<(
          { __typename?: 'OperatorSettingsSms' }
          & Pick<OperatorSettingsSms, 'footerEnabled'>
        )>, manifest?: Maybe<(
          { __typename?: 'OperatorSettingsManifest' }
          & Pick<OperatorSettingsManifest, 'footer' | 'pilotDeclaration'>
        )>, myflight?: Maybe<(
          { __typename?: 'OperatorSettingsMyflight' }
          & Pick<OperatorSettingsMyflight, 'autoConfirm' | 'flights' | 'endOfSeason'>
        )> }
      )>, dashboardOptions?: Maybe<(
        { __typename?: 'DashboardOptions' }
        & Pick<DashboardOptions, 'preferredDateToday' | 'recent' | 'toConfirm'>
      )> }
    )> }
  )>> }
);

export type GetUsersQueryVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers: Array<Maybe<(
    { __typename?: 'GCPIPUser' }
    & Pick<GcpipUser, 'uid' | 'email' | 'customClaims'>
  )>> }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'GCPIPUser' }
    & Pick<GcpipUser, 'uid' | 'email'>
    & { userData?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'displayName' | 'language' | 'role' | 'viewState' | 'tenant'>
      & { crew?: Maybe<(
        { __typename?: 'Crew' }
        & Pick<Crew, 'id' | 'email' | 'emailNotifications'>
      )> }
    )> }
  )> }
);

export type TenantQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantQuery = (
  { __typename?: 'Query' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantInfo' }
    & Pick<TenantInfo, 'name'>
  )> }
);

export type CreateTenantMutationVariables = Exact<{
  displayName: Scalars['String'];
}>;


export type CreateTenantMutation = (
  { __typename?: 'Mutation' }
  & { createTenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'tenantId' | 'displayName'>
  ) }
);

export type InitializeTenantMutationVariables = Exact<{
  tenantId: Scalars['ID'];
}>;


export type InitializeTenantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'initializeTenant'>
);

export type CreateUserMutationVariables = Exact<{
  GcpipUserInput: GcpipUserInput;
  tenantId?: Maybe<Scalars['ID']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'GCPIPUser' }
    & Pick<GcpipUser, 'uid' | 'email' | 'customClaims'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  uid: Scalars['String'];
  tenantId?: Maybe<Scalars['ID']>;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['ID'];
  tenantId?: Maybe<Scalars['ID']>;
}>;


export type SendPasswordResetEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendPasswordResetEmail'>
);

export type SaveUserViewStateMutationVariables = Exact<{
  viewState: Scalars['JSONObject'];
}>;


export type SaveUserViewStateMutation = (
  { __typename?: 'Mutation' }
  & { saveUserViewState: (
    { __typename?: 'User' }
    & Pick<User, 'displayName' | 'language' | 'viewState'>
  ) }
);

export type GetVoucherQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetVoucherQuery = (
  { __typename?: 'Query' }
  & { voucher?: Maybe<(
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'id' | 'adults' | 'adultsUsed' | 'adultsAvailable' | 'children' | 'childrenUsed' | 'childrenAvailable' | 'contactName' | 'contactPhone' | 'external' | 'externalSource' | 'issueDate' | 'paymentAmount' | 'paymentDate' | 'paymentStatus' | 'paymentType' | 'voucherReference' | 'voucherType' | 'expiryDate' | 'status' | 'comments' | 'contactCity' | 'contactEmail' | 'contactLanguage' | 'recipientName' | 'recipientPhone' | 'recipientLanguage' | 'recipientEmail' | 'recipientAddress' | 'recipientPostalCode' | 'recipientCity' | 'recipientCountry'>
    & { tickets?: Maybe<Array<Maybe<(
      { __typename?: 'Ticket' }
      & { passenger?: Maybe<(
        { __typename?: 'Passenger' }
        & Pick<Passenger, 'id' | 'name' | 'child' | 'disability'>
      )>, booking?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'removed' | 'id' | 'inviteCount' | 'status' | 'icon' | 'iconColor' | 'contactName' | 'contactEmail' | 'contactPhone' | 'comments' | 'startDate' | 'endDate' | 'city'>
      )>, flight?: Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'date' | 'period' | 'id'>
        & { balloon?: Maybe<(
          { __typename?: 'Balloon' }
          & Pick<Balloon, 'name'>
        )>, location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'name'>
        )>, icon: (
          { __typename?: 'Icon' }
          & Pick<Icon, 'sign' | 'color'>
        ) }
      )> }
    )>>> }
  )> }
);

export type VouchersQueryVariables = Exact<{ [key: string]: never; }>;


export type VouchersQuery = (
  { __typename?: 'Query' }
  & { vouchers: Array<Maybe<(
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'id' | 'adults' | 'children' | 'contactName' | 'contactPhone' | 'external' | 'externalSource' | 'issueDate' | 'paymentAmount' | 'paymentDate' | 'paymentStatus' | 'paymentType' | 'voucherReference' | 'voucherType' | 'expiryDate' | 'status' | 'comments' | 'contactCity' | 'contactEmail' | 'contactLanguage'>
  )>> }
);

export type GetOpenVouchersQueryVariables = Exact<{
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
}>;


export type GetOpenVouchersQuery = (
  { __typename?: 'Query' }
  & { openVouchers: Array<Maybe<(
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'id' | 'adults' | 'adultsUsed' | 'adultsAvailable' | 'children' | 'childrenUsed' | 'childrenAvailable' | 'contactName' | 'contactPhone' | 'external' | 'externalSource' | 'issueDate' | 'paymentAmount' | 'paymentDate' | 'paymentStatus' | 'paymentType' | 'voucherReference' | 'voucherType' | 'expiryDate' | 'status' | 'comments' | 'contactCity' | 'contactEmail' | 'contactLanguage'>
  )>> }
);

export type GetUsedVouchersQueryVariables = Exact<{
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
}>;


export type GetUsedVouchersQuery = (
  { __typename?: 'Query' }
  & { usedVouchers: Array<Maybe<(
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'id' | 'adults' | 'children' | 'contactName' | 'contactPhone' | 'external' | 'externalSource' | 'issueDate' | 'paymentAmount' | 'paymentDate' | 'paymentStatus' | 'paymentType' | 'voucherReference' | 'voucherType' | 'expiryDate' | 'status' | 'comments' | 'contactCity' | 'contactEmail' | 'contactLanguage'>
  )>> }
);

export type GetExpiredVouchersQueryVariables = Exact<{
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
}>;


export type GetExpiredVouchersQuery = (
  { __typename?: 'Query' }
  & { expiredVouchers: Array<Maybe<(
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'id' | 'adults' | 'children' | 'contactName' | 'contactPhone' | 'external' | 'externalSource' | 'issueDate' | 'paymentAmount' | 'paymentDate' | 'paymentStatus' | 'paymentType' | 'voucherReference' | 'voucherType' | 'expiryDate' | 'status' | 'comments' | 'contactCity' | 'contactEmail' | 'contactLanguage'>
  )>> }
);

export type VoucherCountersQueryVariables = Exact<{ [key: string]: never; }>;


export type VoucherCountersQuery = (
  { __typename?: 'Query' }
  & { voucherCounters?: Maybe<(
    { __typename?: 'VoucherCounters' }
    & Pick<VoucherCounters, 'voucherTypes'>
    & { statusCounters?: Maybe<(
      { __typename?: 'VoucherStatusCounters' }
      & Pick<VoucherStatusCounters, 'AVAI' | 'USED' | 'EXPI'>
    )> }
  )> }
);

export type VoucherCountersUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type VoucherCountersUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { voucherCountersUpdated?: Maybe<(
    { __typename?: 'VoucherCounters' }
    & Pick<VoucherCounters, 'voucherTypes'>
    & { statusCounters?: Maybe<(
      { __typename?: 'VoucherStatusCounters' }
      & Pick<VoucherStatusCounters, 'AVAI' | 'USED' | 'EXPI'>
    )> }
  )> }
);

export type SaveVoucherMutationVariables = Exact<{
  voucherInput: VoucherInput;
  voucherId?: Maybe<Scalars['ID']>;
}>;


export type SaveVoucherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveVoucher'>
);

export type DeleteVoucherMutationVariables = Exact<{
  voucherId: Scalars['ID'];
}>;


export type DeleteVoucherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteVoucher'>
);

export type CreateBookingFromVoucherMutationVariables = Exact<{
  voucherId: Scalars['ID'];
}>;


export type CreateBookingFromVoucherMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBookingFromVoucher'>
);

export const GetBalloonsDocument = gql`
    query getBalloons {
  balloons {
    isCurrent
    callSign
    capacity
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBalloonsGQL extends Apollo.Query<GetBalloonsQuery, GetBalloonsQueryVariables> {
    document = GetBalloonsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBalloonDocument = gql`
    query getBalloon($balloonId: ID!) {
  balloon(balloonId: $balloonId) {
    id
    isCurrent
    name
    callSign
    capacity
    classGroups
    mlm
    mtom
    minFuel
    balloonTemp
    envelopeManufacturer
    envelopeType
    envelopeVolume
    envelopeWeight
    envelopeHours
    envelopeInspectionDate
    burnerManufacturer
    burnerType
    burnerWeight
    basketManufacturer
    basketType
    basketWeight
    insuranceCompany
    insuranceDueDate
    cylinder {
      id
      emptyWeight
      fullWeight
      manufacturer
      volume
      number
      serialNumber
    }
    classGroup {
      id
      description
    }
    balloonWeight {
      totalWeight
      cylindersWeight
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBalloonGQL extends Apollo.Query<GetBalloonQuery, GetBalloonQueryVariables> {
    document = GetBalloonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetManufacturersDocument = gql`
    query getManufacturers {
  manufacturers {
    envelopeManufacturers
    basketManufacturers
    burnerManufacturers
    cylinderManufacturers
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetManufacturersGQL extends Apollo.Query<GetManufacturersQuery, GetManufacturersQueryVariables> {
    document = GetManufacturersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BalloonWeightDocument = gql`
    query balloonWeight($balloonId: ID!) {
  balloonWeight(balloonId: $balloonId) {
    totalWeight
    cylindersWeight
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BalloonWeightGQL extends Apollo.Query<BalloonWeightQuery, BalloonWeightQueryVariables> {
    document = BalloonWeightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveBalloonDocument = gql`
    mutation saveBalloon($balloonInput: BalloonInput!, $cylinders: [ID]!, $balloonId: ID) {
  saveBalloon(
    balloonInput: $balloonInput
    cylinders: $cylinders
    balloonId: $balloonId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveBalloonGQL extends Apollo.Mutation<SaveBalloonMutation, SaveBalloonMutationVariables> {
    document = SaveBalloonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBalloonDocument = gql`
    mutation deleteBalloon($balloonId: ID!) {
  deleteBalloon(balloonId: $balloonId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBalloonGQL extends Apollo.Mutation<DeleteBalloonMutation, DeleteBalloonMutationVariables> {
    document = DeleteBalloonDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBookingDocument = gql`
    query getBooking($id: ID!) {
  booking(id: $id) {
    id
    inviteCount
    status
    icon
    iconColor
    bookingDate
    contactName
    contactEmail
    contactPhone
    contactLanguage
    comments
    bookingCode
    startDate
    endDate
    daysExpired
    external
    externalSource
    city
    region {
      color
      id
      name
    }
    locationType
    location {
      id
      name
      address
      city
      country
      height
      latitude
      longitude
      postalCode
      private
    }
    mailEventLatest {
      date
      event
    }
    availabilityType
    availabilityMondayMorning
    availabilityTuesdayMorning
    availabilityWednesdayMorning
    availabilityThursdayMorning
    availabilityFridayMorning
    availabilitySaturdayMorning
    availabilitySundayMorning
    availabilityMondayEvening
    availabilityTuesdayEvening
    availabilityWednesdayEvening
    availabilityThursdayEvening
    availabilityFridayEvening
    availabilitySaturdayEvening
    availabilitySundayEvening
    preferredFlights {
      date
      period
    }
    passengers {
      id
      name
      age
      transport
      luggage
      child
      disability
      weight
      category {
        id
        name
        description
      }
      paymentType
      voucher {
        id
        voucherReference
        voucherType
      }
      paymentReference
      paymentAmount
      paymentStatus
      paymentDate
      email
      address
      postalCode
      city
      country
      signature
      voucherNumber
    }
    prepaymentAmount
    prepaymentDate
    invoiceName
    VATnumber
    passengerCounters {
      passengerCount
      unpaidPassengerCount
      childCount
      disabilityCount
      transportCount
      totalWeight
      totalAmount
      paidAmount
      openAmount
    }
    smsLatest {
      date
      direction
      summary
      status
      error
    }
    smsType
    smsUnread
    flight {
      id
      date
      hour
      period
      balloon {
        name
      }
      location {
        name
      }
    }
    mergeCandidates {
      bookingDate
      contactName
      flight {
        date
      }
      id
    }
    upUrl
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookingGQL extends Apollo.Query<GetBookingQuery, GetBookingQueryVariables> {
    document = GetBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFirstBookingWithFlightDocument = gql`
    query getFirstBookingWithFlight {
  firstBookingWithFlight {
    id
    availabilityType
    status
    contactName
    contactPhone
    contactLanguage
    contactEmail
    comments
    bookingDate
    bookingCode
    region {
      name
    }
    passengerCounters {
      childCount
      passengerCount
      totalWeight
      totalAmount
      openAmount
      disabilityCount
      transportCount
    }
    smsType
    smsUnread
    flight {
      date
      period
      balloon {
        name
      }
      location {
        name
        city
        address
      }
      pilot {
        crew {
          name
        }
      }
      groundCrews {
        crew {
          email
          id
          language
          name
          pilotLicense
        }
        status
      }
    }
    upUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFirstBookingWithFlightGQL extends Apollo.Query<GetFirstBookingWithFlightQuery, GetFirstBookingWithFlightQueryVariables> {
    document = GetFirstBookingWithFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBookingLogsDocument = gql`
    query getBookingLogs($id: ID!) {
  booking(id: $id) {
    logs {
      id
      date
      user
      action
      flight {
        id
        balloon {
          name
        }
        hour
      }
    }
    logsMerged {
      id
      date
      user
      action
      flight {
        id
        balloon {
          name
        }
        hour
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookingLogsGQL extends Apollo.Query<GetBookingLogsQuery, GetBookingLogsQueryVariables> {
    document = GetBookingLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBookingMailsDocument = gql`
    query getBookingMails($id: ID!) {
  booking(id: $id) {
    mails {
      from {
        email
        name
      }
      replyTo {
        email
        name
      }
      to
      subject
      text
      html
      attachments {
        filename
      }
      result
      resultAt
      events {
        event
        timestamp
        url
      }
    }
    mailsMerged {
      from {
        email
        name
      }
      replyTo {
        email
        name
      }
      to
      subject
      text
      html
      attachments {
        filename
      }
      result
      resultAt
      events {
        event
        timestamp
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookingMailsGQL extends Apollo.Query<GetBookingMailsQuery, GetBookingMailsQueryVariables> {
    document = GetBookingMailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUpcomingBookingsDocument = gql`
    query getUpcomingBookings($orderBy: OrderByBooking, $orderDirection: OrderDirection, $limit: NonNegativeInt, $cursor: String, $filter: [String], $bookingFilter: BookingFilter, $flightId: ID, $flightOnlyMatching: Boolean) {
  upcomingBookings(
    orderBy: $orderBy
    orderDirection: $orderDirection
    limit: $limit
    cursor: $cursor
    filter: $filter
    bookingFilter: $bookingFilter
    flightId: $flightId
    flightOnlyMatching: $flightOnlyMatching
  ) {
    availabilityType
    status
    icon
    iconColor
    id
    inviteCount
    contactName
    contactPhone
    comments
    bookingCode
    bookingDate
    daysExpired
    city
    external
    externalSource
    locationType
    location {
      name
      city
    }
    mailEventLatest {
      date
      event
    }
    smsLatest {
      date
      direction
      summary
      text
      status
      error
    }
    smsUnread
    region {
      color
      name
    }
    passengers {
      child
      disability
      weight
    }
    passengerCounters {
      passengerCount
      childCount
      disabilityCount
      transportCount
      totalWeight
      totalAmount
      paidAmount
      openAmount
    }
    preferredDate
    preferredFlights {
      date
      period
    }
    flight {
      id
      date
      period
    }
    mismatchReasons
    upUrl
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUpcomingBookingsGQL extends Apollo.Query<GetUpcomingBookingsQuery, GetUpcomingBookingsQueryVariables> {
    document = GetUpcomingBookingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHistoricalBookingsDocument = gql`
    query getHistoricalBookings($orderBy: OrderByBooking, $orderDirection: OrderDirection, $limit: NonNegativeInt, $cursor: String, $filter: [String]) {
  historicalBookings(
    orderBy: $orderBy
    orderDirection: $orderDirection
    limit: $limit
    cursor: $cursor
    filter: $filter
  ) {
    availabilityType
    status
    icon
    iconColor
    id
    bookingDate
    daysExpired
    contactName
    contactPhone
    comments
    bookingCode
    external
    externalSource
    locationType
    location {
      name
      city
    }
    region {
      color
      name
    }
    region {
      color
      name
    }
    city
    passengerCounters {
      passengerCount
      totalWeight
    }
    preferredDate
    preferredFlights {
      date
      period
    }
    flight {
      date
      period
      balloon {
        name
      }
      location {
        name
      }
      pilot {
        crew {
          name
        }
      }
    }
    upUrl
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHistoricalBookingsGQL extends Apollo.Query<GetHistoricalBookingsQuery, GetHistoricalBookingsQueryVariables> {
    document = GetHistoricalBookingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUpcomingBookingsIsDocument = gql`
    query getUpcomingBookingsIS($showUpcoming: Boolean, $limit: NonNegativeInt, $cursor: String, $filter: [String], $orderBy: OrderByBooking, $orderDirection: OrderDirection, $bookingFilter: BookingFilter, $flightId: ID, $flightOnlyMatching: Boolean, $endAt: Boolean) {
  getBookingsIS(
    showUpcoming: $showUpcoming
    limit: $limit
    cursor: $cursor
    filter: $filter
    orderBy: $orderBy
    orderDirection: $orderDirection
    bookingFilter: $bookingFilter
    flightId: $flightId
    flightOnlyMatching: $flightOnlyMatching
    endAt: $endAt
  ) {
    availabilityType
    status
    icon
    iconColor
    id
    inviteCount
    contactName
    contactPhone
    comments
    bookingCode
    bookingDate
    daysExpired
    city
    external
    externalSource
    locationType
    location {
      name
      city
    }
    mailEventLatest {
      date
      event
    }
    smsLatest {
      date
      direction
      summary
      text
      status
      error
    }
    smsUnread
    region {
      color
      name
    }
    passengers {
      child
      disability
      weight
    }
    passengerCounters {
      passengerCount
      childCount
      disabilityCount
      transportCount
      totalWeight
      totalAmount
      paidAmount
      openAmount
    }
    preferredDate
    preferredFlights {
      date
      period
    }
    flight {
      id
      balloon {
        capacity
      }
      date
      period
      passengersOccupancy
      passengersCount
    }
    mismatchReasons
    upUrl
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUpcomingBookingsIsGQL extends Apollo.Query<GetUpcomingBookingsIsQuery, GetUpcomingBookingsIsQueryVariables> {
    document = GetUpcomingBookingsIsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHistoricalBookingsIsDocument = gql`
    query getHistoricalBookingsIS($showUpcoming: Boolean, $limit: NonNegativeInt, $cursor: String, $filter: [String], $orderBy: OrderByBooking, $orderDirection: OrderDirection, $bookingFilter: BookingFilter, $flightId: ID, $flightOnlyMatching: Boolean, $endAt: Boolean) {
  getBookingsIS(
    showUpcoming: $showUpcoming
    limit: $limit
    cursor: $cursor
    filter: $filter
    orderBy: $orderBy
    orderDirection: $orderDirection
    bookingFilter: $bookingFilter
    flightId: $flightId
    flightOnlyMatching: $flightOnlyMatching
    endAt: $endAt
  ) {
    availabilityType
    status
    icon
    iconColor
    id
    bookingDate
    daysExpired
    contactName
    contactPhone
    comments
    bookingCode
    external
    externalSource
    locationType
    location {
      name
      city
    }
    region {
      color
      name
    }
    region {
      color
      name
    }
    city
    passengerCounters {
      passengerCount
      totalWeight
    }
    preferredDate
    preferredFlights {
      date
      period
    }
    flight {
      date
      period
      balloon {
        name
      }
      location {
        name
      }
      pilot {
        crew {
          name
        }
      }
    }
    upUrl
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHistoricalBookingsIsGQL extends Apollo.Query<GetHistoricalBookingsIsQuery, GetHistoricalBookingsIsQueryVariables> {
    document = GetHistoricalBookingsIsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DashboardBookingsDocument = gql`
    query dashboardBookings {
  dashboardBookings {
    availabilityType
    status
    icon
    iconColor
    id
    inviteCount
    bookingDate
    daysExpired
    city
    contactName
    contactPhone
    comments
    external
    externalSource
    mergeCandidates {
      id
      flight {
        date
      }
    }
    locationType
    location {
      name
      city
    }
    mailEventLatest {
      date
      event
    }
    smsLatest {
      date
      direction
      summary
      text
      status
      error
    }
    smsUnread
    region {
      color
      name
    }
    region {
      color
      name
    }
    preferredDate
    preferredFlights {
      date
      period
    }
    flight {
      id
      balloon {
        capacity
      }
      date
      period
      passengersOccupancy
      passengersCount
    }
    passengerCounters {
      openAmount
      passengerCount
      childCount
      disabilityCount
      transportCount
      totalWeight
    }
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DashboardBookingsGQL extends Apollo.Query<DashboardBookingsQuery, DashboardBookingsQueryVariables> {
    document = DashboardBookingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BookingsPreferredDateDocument = gql`
    query bookingsPreferredDate($preferredDateInput: PreferredDateInput) {
  bookingsPreferredDate(preferredDateInput: $preferredDateInput) {
    id
    flight {
      id
    }
    region {
      color
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BookingsPreferredDateGQL extends Apollo.Query<BookingsPreferredDateQuery, BookingsPreferredDateQueryVariables> {
    document = BookingsPreferredDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PassengerCountersDocument = gql`
    query passengerCounters($passengers: [PassengerInput]!) {
  passengerCounters(passengers: $passengers) {
    passengerCount
    childCount
    disabilityCount
    transportCount
    totalWeight
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PassengerCountersGQL extends Apollo.Query<PassengerCountersQuery, PassengerCountersQueryVariables> {
    document = PassengerCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BookingCountersDocument = gql`
    query bookingCounters {
  bookingCounters {
    upcoming
    historical
    toConfirm
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BookingCountersGQL extends Apollo.Query<BookingCountersQuery, BookingCountersQueryVariables> {
    document = BookingCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BookingsToConfirmDocument = gql`
    query bookingsToConfirm {
  bookingsToConfirm
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BookingsToConfirmGQL extends Apollo.Query<BookingsToConfirmQuery, BookingsToConfirmQueryVariables> {
    document = BookingsToConfirmDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BookingConversationsDocument = gql`
    query bookingConversations($offset: NonNegativeInt) {
  bookingConversations(offset: $offset) {
    id
    contactName
    contactPhone
    smsLatest {
      date
      direction
      summary
      status
      error
    }
    smsType
    smsUnread
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BookingConversationsGQL extends Apollo.Query<BookingConversationsQuery, BookingConversationsQueryVariables> {
    document = BookingConversationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBookingsOfFlightDocument = gql`
    query getBookingsOfFlight($flightId: ID!) {
  flight(flightId: $flightId) {
    bookings {
      id
      list {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookingsOfFlightGQL extends Apollo.Query<GetBookingsOfFlightQuery, GetBookingsOfFlightQueryVariables> {
    document = GetBookingsOfFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBookingForPaymentFormDocument = gql`
    query getBookingForPaymentForm($id: ID!) {
  booking(id: $id) {
    id
    icon
    iconColor
    contactName
    contactPhone
    contactEmail
    comments
    invoiceName
    VATnumber
    prepaymentAmount
    prepaymentDate
    passengers {
      id
      category {
        id
        name
        description
      }
      child
      name
      paymentAmount
      paymentReference
      paymentStatus
      paymentType
      paymentDate
      weight
      voucher {
        id
        voucherReference
        voucherType
      }
      voucherNumber
    }
    passengerCounters {
      id
      openAmount
      paidAmount
      totalAmount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookingForPaymentFormGQL extends Apollo.Query<GetBookingForPaymentFormQuery, GetBookingForPaymentFormQueryVariables> {
    document = GetBookingForPaymentFormDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookingCommentsDocument = gql`
    mutation updateBookingComments($bookingId: ID!, $comments: String) {
  updateBookingComments(bookingId: $bookingId, comments: $comments)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookingCommentsGQL extends Apollo.Mutation<UpdateBookingCommentsMutation, UpdateBookingCommentsMutationVariables> {
    document = UpdateBookingCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookingTagsDocument = gql`
    mutation updateBookingTags($bookingId: ID!, $tags: [String]) {
  updateBookingTags(bookingId: $bookingId, tags: $tags)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookingTagsGQL extends Apollo.Mutation<UpdateBookingTagsMutation, UpdateBookingTagsMutationVariables> {
    document = UpdateBookingTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookingPreferredFlightsDocument = gql`
    mutation updateBookingPreferredFlights($bookingId: ID!, $preferredFlights: [PreferredFlightInput]) {
  updateBookingPreferredFlights(
    bookingId: $bookingId
    preferredFlights: $preferredFlights
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookingPreferredFlightsGQL extends Apollo.Mutation<UpdateBookingPreferredFlightsMutation, UpdateBookingPreferredFlightsMutationVariables> {
    document = UpdateBookingPreferredFlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveBookingDocument = gql`
    mutation saveBooking($bookingInput: BookingInput!, $bookingId: ID, $locationInput: LocationInput) {
  saveBooking(
    bookingInput: $bookingInput
    bookingId: $bookingId
    locationInput: $locationInput
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveBookingGQL extends Apollo.Mutation<SaveBookingMutation, SaveBookingMutationVariables> {
    document = SaveBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBookingDocument = gql`
    mutation deleteBooking($bookingId: ID!) {
  deleteBooking(bookingId: $bookingId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBookingGQL extends Apollo.Mutation<DeleteBookingMutation, DeleteBookingMutationVariables> {
    document = DeleteBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBookingDocument = gql`
    mutation createBooking($flightId: ID!) {
  createBooking(flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBookingGQL extends Apollo.Mutation<CreateBookingMutation, CreateBookingMutationVariables> {
    document = CreateBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SavePassengerDocument = gql`
    mutation savePassenger($passengerInput: PassengerInput!, $passengerId: ID, $bookingId: ID!) {
  savePassenger(
    passengerInput: $passengerInput
    passengerId: $passengerId
    bookingId: $bookingId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SavePassengerGQL extends Apollo.Mutation<SavePassengerMutation, SavePassengerMutationVariables> {
    document = SavePassengerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePassengerDocument = gql`
    mutation deletePassenger($passengerId: ID!, $bookingId: ID!) {
  deletePassenger(passengerId: $passengerId, bookingId: $bookingId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePassengerGQL extends Apollo.Mutation<DeletePassengerMutation, DeletePassengerMutationVariables> {
    document = DeletePassengerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddPassengersDocument = gql`
    mutation addPassengers($bookingId: ID!, $count: Int) {
  addPassengers(bookingId: $bookingId, count: $count)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddPassengersGQL extends Apollo.Mutation<AddPassengersMutation, AddPassengersMutationVariables> {
    document = AddPassengersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkBookingPaidDocument = gql`
    mutation markBookingPaid($bookingId: ID!) {
  markBookingPaid(bookingId: $bookingId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkBookingPaidGQL extends Apollo.Mutation<MarkBookingPaidMutation, MarkBookingPaidMutationVariables> {
    document = MarkBookingPaidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddBookingsToFlightDocument = gql`
    mutation addBookingsToFlight($bookings: [ID]!, $flightId: ID!) {
  addBookingsToFlight(bookings: $bookings, flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddBookingsToFlightGQL extends Apollo.Mutation<AddBookingsToFlightMutation, AddBookingsToFlightMutationVariables> {
    document = AddBookingsToFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteBookingsToFlightDocument = gql`
    mutation inviteBookingsToFlight($bookings: [ID]!, $flightId: ID!) {
  inviteBookingsToFlight(bookings: $bookings, flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteBookingsToFlightGQL extends Apollo.Mutation<InviteBookingsToFlightMutation, InviteBookingsToFlightMutationVariables> {
    document = InviteBookingsToFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddAndConfirmBookingsToFlightDocument = gql`
    mutation addAndConfirmBookingsToFlight($bookings: [ID]!, $flightId: ID!) {
  addAndConfirmBookingsToFlight(bookings: $bookings, flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddAndConfirmBookingsToFlightGQL extends Apollo.Mutation<AddAndConfirmBookingsToFlightMutation, AddAndConfirmBookingsToFlightMutationVariables> {
    document = AddAndConfirmBookingsToFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmBookingToFlightDocument = gql`
    mutation confirmBookingToFlight($bookingId: ID!) {
  confirmBookingToFlight(bookingId: $bookingId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmBookingToFlightGQL extends Apollo.Mutation<ConfirmBookingToFlightMutation, ConfirmBookingToFlightMutationVariables> {
    document = ConfirmBookingToFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveBookingFromFlightDocument = gql`
    mutation removeBookingFromFlight($bookingId: ID!) {
  removeBookingFromFlight(bookingId: $bookingId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveBookingFromFlightGQL extends Apollo.Mutation<RemoveBookingFromFlightMutation, RemoveBookingFromFlightMutationVariables> {
    document = RemoveBookingFromFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveBookingPaymentDocument = gql`
    mutation saveBookingPayment($bookingId: ID!, $bookingPaymentInput: BookingPaymentInput!) {
  saveBookingPayment(
    bookingId: $bookingId
    bookingPaymentInput: $bookingPaymentInput
  ) {
    id
    contactPhone
    contactEmail
    invoiceName
    VATnumber
    prepaymentDate
    prepaymentAmount
    passengerCounters {
      id
      openAmount
      totalAmount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveBookingPaymentGQL extends Apollo.Mutation<SaveBookingPaymentMutation, SaveBookingPaymentMutationVariables> {
    document = SaveBookingPaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SavePassengerPaymentDocument = gql`
    mutation savePassengerPayment($bookingId: ID!, $passengerId: ID!, $passengerPaymentInput: PassengerPaymentInput!) {
  savePassengerPayment(
    bookingId: $bookingId
    passengerId: $passengerId
    passengerPaymentInput: $passengerPaymentInput
  ) {
    id
    category {
      id
    }
    paymentReference
    child
    paymentStatus
    paymentType
    paymentDate
    paymentAmount
    voucher {
      id
    }
    voucherNumber
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SavePassengerPaymentGQL extends Apollo.Mutation<SavePassengerPaymentMutation, SavePassengerPaymentMutationVariables> {
    document = SavePassengerPaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePassengerPaymentStatusDocument = gql`
    mutation updatePassengerPaymentStatus($bookingId: ID!, $passengerId: ID!, $paymentStatus: PaymentStatus!) {
  updatePassengerPaymentStatus(
    bookingId: $bookingId
    passengerId: $passengerId
    paymentStatus: $paymentStatus
  ) {
    id
    paymentStatus
    paymentDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePassengerPaymentStatusGQL extends Apollo.Mutation<UpdatePassengerPaymentStatusMutation, UpdatePassengerPaymentStatusMutationVariables> {
    document = UpdatePassengerPaymentStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookingPaymentDocument = gql`
    mutation updateBookingPayment($bookingId: ID!, $paymentType: PaymentType!) {
  updateBookingPayment(bookingId: $bookingId, paymentType: $paymentType) {
    id
    contactEmail
    contactPhone
    prepaymentAmount
    prepaymentDate
    passengerCounters {
      openAmount
    }
    passengers {
      id
      category {
        id
      }
      paymentReference
      paymentStatus
      paymentType
      paymentDate
      voucher {
        id
      }
      voucherNumber
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookingPaymentGQL extends Apollo.Mutation<UpdateBookingPaymentMutation, UpdateBookingPaymentMutationVariables> {
    document = UpdateBookingPaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MergeBookingsDocument = gql`
    mutation mergeBookings($bookingIdFrom: ID!, $bookingIdTo: ID!) {
  mergeBookings(bookingIdFrom: $bookingIdFrom, bookingIdTo: $bookingIdTo) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MergeBookingsGQL extends Apollo.Mutation<MergeBookingsMutation, MergeBookingsMutationVariables> {
    document = MergeBookingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteLogDocument = gql`
    mutation deleteLog($bookingId: ID!, $logId: ID!) {
  deleteLog(bookingId: $bookingId, logId: $logId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteLogGQL extends Apollo.Mutation<DeleteLogMutation, DeleteLogMutationVariables> {
    document = DeleteLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BookingCountersUpdatedDocument = gql`
    subscription bookingCountersUpdated {
  bookingCountersUpdated {
    upcoming
    historical
    toConfirm
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BookingCountersUpdatedGQL extends Apollo.Subscription<BookingCountersUpdatedSubscription, BookingCountersUpdatedSubscriptionVariables> {
    document = BookingCountersUpdatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalendarDocument = gql`
    query calendar($year: PositiveInt!, $month: PositiveInt!) {
  calendar(year: $year, month: $month) {
    flights {
      id
      balloon {
        id
        name
        callSign
        capacity
      }
      groundCrews {
        crew {
          id
          name
          isPilot
        }
        status
      }
      location {
        id
        name
      }
      date
      hour
      period
      pilot {
        crew {
          id
          name
          isCurrent
          isPilot
        }
        status
      }
      status
      passengersCount
    }
    events {
      id
      description
      date
      type
      availability
      period
      crew {
        id
        name
        isCurrent
        isPilot
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalendarGQL extends Apollo.Query<CalendarQuery, CalendarQueryVariables> {
    document = CalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CrewCalendarDocument = gql`
    query crewCalendar($year: PositiveInt!, $month: PositiveInt!, $userId: String) {
  crewCalendar(year: $year, month: $month, userId: $userId) {
    start
    meta {
      flightsMorning {
        totalFlights
        crewEvent {
          id
          date
          type
          availability
          period
        }
        selectedFlights {
          id
          balloon {
            id
            name
          }
          pilot {
            crew {
              name
            }
          }
          date
          period
          hour
          location {
            id
            city
            name
            region {
              name
              id
              color
            }
          }
          status
        }
      }
      flightsEvening {
        totalFlights
        crewEvent {
          id
          date
          type
          availability
          period
        }
        selectedFlights {
          id
          balloon {
            name
          }
          pilot {
            crew {
              name
            }
          }
          date
          period
          hour
          location {
            id
            city
            name
            region {
              name
              id
              color
            }
          }
          status
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CrewCalendarGQL extends Apollo.Query<CrewCalendarQuery, CrewCalendarQueryVariables> {
    document = CrewCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCategoriesDocument = gql`
    query getCategories {
  categories {
    id
    child
    default
    description
    name
    price
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCategoriesGQL extends Apollo.Query<GetCategoriesQuery, GetCategoriesQueryVariables> {
    document = GetCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCategoryDocument = gql`
    query getCategory($categoryId: ID!) {
  category(categoryId: $categoryId) {
    id
    child
    default
    description
    name
    price
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCategoryGQL extends Apollo.Query<GetCategoryQuery, GetCategoryQueryVariables> {
    document = GetCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveCategoryDocument = gql`
    mutation saveCategory($categoryInput: CategoryInput!, $categoryId: ID) {
  saveCategory(categoryInput: $categoryInput, categoryId: $categoryId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveCategoryGQL extends Apollo.Mutation<SaveCategoryMutation, SaveCategoryMutationVariables> {
    document = SaveCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($categoryId: ID!) {
  deleteCategory(categoryId: $categoryId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCategoryGQL extends Apollo.Mutation<DeleteCategoryMutation, DeleteCategoryMutationVariables> {
    document = DeleteCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PassengerRegionDistributionDocument = gql`
    query passengerRegionDistribution {
  passengerRegionDistribution {
    type
    data
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PassengerRegionDistributionGQL extends Apollo.Query<PassengerRegionDistributionQuery, PassengerRegionDistributionQueryVariables> {
    document = PassengerRegionDistributionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCountriesDocument = gql`
    query getCountries {
  countries {
    id
    country
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCountriesGQL extends Apollo.Query<GetCountriesQuery, GetCountriesQueryVariables> {
    document = GetCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCrewsDocument = gql`
    query getCrews {
  crews {
    driverLicense
    groundCrew
    id
    isCurrent
    isPilot
    language
    licenseExpiryDate
    licenseGas
    licenseGroupA
    licenseGroupB
    licenseGroupC
    licenseGroupD
    medicalExpiryDate
    name
    phone
    pilotLicense
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCrewsGQL extends Apollo.Query<GetCrewsQuery, GetCrewsQueryVariables> {
    document = GetCrewsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCrewDocument = gql`
    query getCrew($crewId: ID!) {
  crew(crewId: $crewId) {
    driverLicense
    email
    emailNotifications
    groundCrew
    id
    isCurrent
    isPilot
    language
    licenseExpiryDate
    licenseExtraCrew
    licenseGas
    licenseGroupA
    licenseGroupB
    licenseGroupC
    licenseGroupD
    licenseNumber
    medicalExpiryDate
    medicalWeight
    name
    phone
    pilotLicense
    smsLatest {
      date
      direction
      summary
      status
      error
    }
    smsType
    smsUnread
    userRole
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCrewGQL extends Apollo.Query<GetCrewQuery, GetCrewQueryVariables> {
    document = GetCrewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CrewConversationsDocument = gql`
    query crewConversations($offset: NonNegativeInt) {
  crewConversations(offset: $offset) {
    id
    name
    phone
    smsLatest {
      date
      direction
      summary
      status
      error
    }
    smsType
    smsUnread
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CrewConversationsGQL extends Apollo.Query<CrewConversationsQuery, CrewConversationsQueryVariables> {
    document = CrewConversationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CrewsPerFlightDocument = gql`
    query crewsPerFlight($flightId: ID!) {
  crewsPerFlight(flightId: $flightId) {
    availability
    groundCrew
    id
    isCurrent
    isPilot
    name
    pilotLicense
    otherFlight {
      id
      balloon {
        name
      }
    }
    popularity
    mostPopular
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CrewsPerFlightGQL extends Apollo.Query<CrewsPerFlightQuery, CrewsPerFlightQueryVariables> {
    document = CrewsPerFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PilotsPerFlightDocument = gql`
    query pilotsPerFlight($flightId: ID!) {
  crewsPerFlight(flightId: $flightId) {
    availability
    groundCrew
    id
    isCurrent
    isPilot
    name
    pilotLicense
    otherFlight {
      id
      balloon {
        name
      }
    }
    popularity
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PilotsPerFlightGQL extends Apollo.Query<PilotsPerFlightQuery, PilotsPerFlightQueryVariables> {
    document = PilotsPerFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllCrewForDialogDocument = gql`
    query getAllCrewForDialog {
  crews {
    id
    name
    isCurrent
    isPilot
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllCrewForDialogGQL extends Apollo.Query<GetAllCrewForDialogQuery, GetAllCrewForDialogQueryVariables> {
    document = GetAllCrewForDialogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveCrewDocument = gql`
    mutation saveCrew($crewInput: CrewInput!, $crewId: ID) {
  saveCrew(crewInput: $crewInput, crewId: $crewId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveCrewGQL extends Apollo.Mutation<SaveCrewMutation, SaveCrewMutationVariables> {
    document = SaveCrewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCrewDocument = gql`
    mutation deleteCrew($crewId: ID!) {
  deleteCrew(crewId: $crewId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCrewGQL extends Apollo.Mutation<DeleteCrewMutation, DeleteCrewMutationVariables> {
    document = DeleteCrewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCylindersDocument = gql`
    query getCylinders {
  cylinders {
    id
    number
    emptyWeight
    fullWeight
    manufacturer
    serialNumber
    volume
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCylindersGQL extends Apollo.Query<GetCylindersQuery, GetCylindersQueryVariables> {
    document = GetCylindersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCylinderDocument = gql`
    query getCylinder($cylinderId: ID!) {
  cylinder(cylinderId: $cylinderId) {
    emptyWeight
    fullWeight
    id
    manufacturer
    serialNumber
    volume
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCylinderGQL extends Apollo.Query<GetCylinderQuery, GetCylinderQueryVariables> {
    document = GetCylinderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveCylinderDocument = gql`
    mutation saveCylinder($cylinderInput: CylinderInput!, $cylinderId: ID) {
  saveCylinder(cylinderInput: $cylinderInput, cylinderId: $cylinderId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveCylinderGQL extends Apollo.Mutation<SaveCylinderMutation, SaveCylinderMutationVariables> {
    document = SaveCylinderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCylinderDocument = gql`
    mutation deleteCylinder($cylinderId: ID!) {
  deleteCylinder(cylinderId: $cylinderId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCylinderGQL extends Apollo.Mutation<DeleteCylinderMutation, DeleteCylinderMutationVariables> {
    document = DeleteCylinderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllEmailTemplatesDocument = gql`
    query getAllEmailTemplates {
  getAllEmailTemplates {
    id
    template {
      en {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      fr {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      nl {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      de {
        body
        subject
        attachments {
          filename
          reference
        }
      }
    }
    type
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllEmailTemplatesGQL extends Apollo.Query<GetAllEmailTemplatesQuery, GetAllEmailTemplatesQueryVariables> {
    document = GetAllEmailTemplatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ParseEmailTemplateDocument = gql`
    query parseEmailTemplate($emailBody: String!, $language: String!, $flightPeriod: String!) {
  parseEmailTemplate(
    emailBody: $emailBody
    language: $language
    flightPeriod: $flightPeriod
  ) {
    body
    subject
    attachments {
      filename
      reference
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ParseEmailTemplateGQL extends Apollo.Query<ParseEmailTemplateQuery, ParseEmailTemplateQueryVariables> {
    document = ParseEmailTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmailTemplateDocument = gql`
    query getEmailTemplate($type: EmailTemplateType!) {
  getEmailTemplate(type: $type) {
    id
    title
    type
    template {
      en {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      fr {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      nl {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      de {
        body
        subject
        attachments {
          filename
          reference
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmailTemplateGQL extends Apollo.Query<GetEmailTemplateQuery, GetEmailTemplateQueryVariables> {
    document = GetEmailTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmailTemplateByIdDocument = gql`
    query getEmailTemplateByID($id: ID!) {
  getEmailTemplateByID(id: $id) {
    id
    title
    type
    template {
      en {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      fr {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      nl {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      de {
        body
        subject
        attachments {
          filename
          reference
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmailTemplateByIdGQL extends Apollo.Query<GetEmailTemplateByIdQuery, GetEmailTemplateByIdQueryVariables> {
    document = GetEmailTemplateByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmailTemplatesDocument = gql`
    query getEmailTemplates($type: EmailTemplateType!) {
  getEmailTemplates(type: $type) {
    id
    title
    type
    template {
      en {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      fr {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      nl {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      de {
        body
        subject
        attachments {
          filename
          reference
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmailTemplatesGQL extends Apollo.Query<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables> {
    document = GetEmailTemplatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmailTemplateByLanguageDocument = gql`
    query getEmailTemplateByLanguage($type: EmailTemplateType!, $lang: ContactLanguage!) {
  getEmailTemplateByLanguage(type: $type, lang: $lang) {
    body
    subject
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmailTemplateByLanguageGQL extends Apollo.Query<GetEmailTemplateByLanguageQuery, GetEmailTemplateByLanguageQueryVariables> {
    document = GetEmailTemplateByLanguageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddEmailOutDocument = gql`
    mutation addEmailOut($emailInput: EmailInput!) {
  addEmailOut(emailInput: $emailInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddEmailOutGQL extends Apollo.Mutation<AddEmailOutMutation, AddEmailOutMutationVariables> {
    document = AddEmailOutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CopyEmailTemplateDocument = gql`
    mutation copyEmailTemplate($emailTemplateId: ID!) {
  copyEmailTemplate(emailTemplateId: $emailTemplateId) {
    id
    type
    title
    template {
      en {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      fr {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      nl {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      de {
        body
        subject
        attachments {
          filename
          reference
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CopyEmailTemplateGQL extends Apollo.Mutation<CopyEmailTemplateMutation, CopyEmailTemplateMutationVariables> {
    document = CopyEmailTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddEmailTemplateLocationContactDocument = gql`
    mutation addEmailTemplateLocationContact {
  addEmailTemplateLocationContact {
    id
    type
    title
    template {
      en {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      fr {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      nl {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      de {
        body
        subject
        attachments {
          filename
          reference
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddEmailTemplateLocationContactGQL extends Apollo.Mutation<AddEmailTemplateLocationContactMutation, AddEmailTemplateLocationContactMutationVariables> {
    document = AddEmailTemplateLocationContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEmailTemplateDocument = gql`
    mutation deleteEmailTemplate($emailTemplateId: ID!) {
  deleteEmailTemplate(emailTemplateId: $emailTemplateId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEmailTemplateGQL extends Apollo.Mutation<DeleteEmailTemplateMutation, DeleteEmailTemplateMutationVariables> {
    document = DeleteEmailTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveEmailTemplateDocument = gql`
    mutation saveEmailTemplate($emailTemplateInput: EmailTemplateInput, $emailTemplateId: ID!) {
  saveEmailTemplate(
    emailTemplateInput: $emailTemplateInput
    emailTemplateId: $emailTemplateId
  ) {
    id
    type
    title
    template {
      en {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      fr {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      nl {
        body
        subject
        attachments {
          filename
          reference
        }
      }
      de {
        body
        subject
        attachments {
          filename
          reference
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveEmailTemplateGQL extends Apollo.Mutation<SaveEmailTemplateMutation, SaveEmailTemplateMutationVariables> {
    document = SaveEmailTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEventsDocument = gql`
    query getEvents {
  events {
    id
    description
    date
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEventsGQL extends Apollo.Query<GetEventsQuery, GetEventsQueryVariables> {
    document = GetEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEventDocument = gql`
    mutation deleteEvent($eventId: ID!) {
  deleteEvent(eventId: $eventId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEventGQL extends Apollo.Mutation<DeleteEventMutation, DeleteEventMutationVariables> {
    document = DeleteEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveEventDocument = gql`
    mutation saveEvent($eventInput: EventInput!, $eventId: ID) {
  saveEvent(eventInput: $eventInput, eventId: $eventId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveEventGQL extends Apollo.Mutation<SaveEventMutation, SaveEventMutationVariables> {
    document = SaveEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveCrewAvailabilityDocument = gql`
    mutation saveCrewAvailability($crewAvailabilityInput: CrewAvailabilityInput!, $eventId: ID) {
  saveCrewAvailability(
    crewAvailabilityInput: $crewAvailabilityInput
    eventId: $eventId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveCrewAvailabilityGQL extends Apollo.Mutation<SaveCrewAvailabilityMutation, SaveCrewAvailabilityMutationVariables> {
    document = SaveCrewAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetMonthAvailabilityDocument = gql`
    mutation setMonthAvailability($availability: CrewAvailability!, $year: PositiveInt!, $month: PositiveInt!, $crewId: ID!) {
  setMonthAvailability(
    availability: $availability
    year: $year
    month: $month
    crewId: $crewId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetMonthAvailabilityGQL extends Apollo.Mutation<SetMonthAvailabilityMutation, SetMonthAvailabilityMutationVariables> {
    document = SetMonthAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMonthAvailabilityEventsDocument = gql`
    mutation deleteMonthAvailabilityEvents($year: PositiveInt!, $month: PositiveInt!, $crewId: ID!) {
  deleteMonthAvailabilityEvents(year: $year, month: $month, crewId: $crewId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMonthAvailabilityEventsGQL extends Apollo.Mutation<DeleteMonthAvailabilityEventsMutation, DeleteMonthAvailabilityEventsMutationVariables> {
    document = DeleteMonthAvailabilityEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchHolidaysDocument = gql`
    mutation fetchHolidays($year: PositiveInt!, $countryCode: String, $language: String) {
  fetchHolidays(year: $year, countryCode: $countryCode, language: $language)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchHolidaysGQL extends Apollo.Mutation<FetchHolidaysMutation, FetchHolidaysMutationVariables> {
    document = FetchHolidaysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFlightDocument = gql`
    query getFlight($flightId: ID!) {
  flight(flightId: $flightId) {
    comment
    date
    hour
    hourMeeting
    icon {
      color
      sign
    }
    id
    landingLocation
    landingTime
    landingDetails
    landingLatitude
    landingLongitude
    liftAvailable
    liftDataMismatch
    liftNeeded
    liftPassengers
    liftSpare
    locationType
    meetingPointSameAsTakeOff
    meetingPointAddress
    meetingPointLatitude
    meetingPointLongitude
    meetingPointURL
    meetingPointCity
    maximumFlightTime
    maximumHeight
    period
    pilotComments
    plannedFlightTime
    status
    takeoffTime
    tempBalloon
    tempMax
    tempTakeoff
    vfrClouds
    vfrVisual
    visibility
    weatherForecastDate
    weatherPmax
    weatherQnh
    weatherSource
    wind10
    wind10Direction
    wind20
    wind20Direction
    windGl
    windGlDirection
    windSummary
    refuelVolume
    groundCrews {
      crew {
        availability
        email
        emailNotifications
        id
        language
        name
        pilotLicense
      }
      status
    }
    passengersCount
    passengersOccupancy
    pilot {
      crew {
        id
        isCurrent
        isPilot
        name
        language
        email
        emailNotifications
        pilotLicense
        isCurrent
        medicalWeight
        phone
        totalWeight
      }
      status
    }
    flightsAtSameDay {
      flights {
        id
      }
      count
      names
    }
    flightDuplicates {
      id
    }
    flightTime
    flightDistance
    flightHeading
    balloon {
      balloonTemp
      basketWeight
      burnerWeight
      callSign
      capacity
      envelopeType
      envelopeVolume
      envelopeWeight
      id
      isCurrent
      name
      balloonWeight {
        cylindersWeight
      }
    }
    location {
      address
      city
      country
      contact {
        comment
        email
        emailTemplate
        name
        phone
        language
        smsTemplate
        media
      }
      meetingPointSameAsTakeOff
      meetingPointAddress
      meetingPointLatitude
      meetingPointLongitude
      meetingPointURL
      meetingPointCity
      description
      height
      id
      latitude
      longitude
      maximumHeight
      name
      postalCode
      region {
        id
        name
      }
    }
    locationEmail {
      date
      event
    }
    locationSmsLatest {
      date
      direction
      summary
      status
      error
    }
    sunriseSunset {
      sunrise
      sunset
      morningFlight
      eveningFlight
    }
    meteo {
      slot
      forecast {
        datepart {
          part
          text
        }
        daytime
        flytime
        lastUpdate
        latitude
        longitude
        lowCloudsData
        pictoCodeData
        precipitationData
        pressureData
        sunrise
        sunset
        temperatureData
        time
        utc_timeoffset
        visibilityData
        windDirectionData
        windDirectionData_1000
        windDirectionData_500
        windDirectionData_550
        windDirectionData_600
        windDirectionData_650
        windDirectionData_700
        windDirectionData_750
        windDirectionData_800
        windDirectionData_925
        windDirectionData_950
        windDirectionData_975
        windGustData
        windSpeedData
        windSpeedData_1000
        windSpeedData_500
        windSpeedData_550
        windSpeedData_600
        windSpeedData_650
        windSpeedData_700
        windSpeedData_750
        windSpeedData_800
        windSpeedData_925
        windSpeedData_950
        windSpeedData_975
      }
      tempTakeoff
      vfrVisual
      weatherQnh
      wind10
      wind10Direction
      wind20
      wind20Direction
      windGl
      windGlDirection
    }
    bookings {
      id
      list {
        id
        icon
        iconColor
        inviteCount
        city
        contactName
        contactPhone
        contactEmail
        contactLanguage
        comments
        status
        prepaymentAmount
        prepaymentDate
        invoiceName
        VATnumber
        mismatchReasons
        region {
          name
        }
        locationType
        location {
          address
          city
          description
          height
          id
          latitude
          longitude
          maximumHeight
          name
        }
        passengers {
          age
          transport
          category {
            name
            description
          }
          child
          disability
          luggage
          name
          paymentAmount
          paymentReference
          paymentStatus
          paymentType
          totalWeight
          weight
          voucherNumber
          voucher {
            voucherReference
          }
        }
        passengerCounters {
          id
          childCount
          disabilityCount
          transportCount
          openAmount
          paidAmount
          passengerCount
          totalAmount
          totalWeight
          unpaidPassengerCount
        }
        smsLatest {
          date
          direction
          summary
          status
          error
        }
        smsType
        smsUnread
        upUrl
        tags {
          id
          tag
          backgroundColor
          fontColor
        }
      }
      addedCount
      notConfirmedCount
      notConfirmedNames
    }
    passengerCounters {
      id
      passengersCountAdded
      passengersCountInvited
      passengersCountConfirmed
      passengersWeight
    }
    logs {
      date
    }
    trajectory {
      lat
      lng
      alt
    }
    trajectoryCustom {
      lat
      lng
      alt
    }
    trajectory1000 {
      lat
      lng
      alt
    }
    trajectory950 {
      lat
      lng
      alt
    }
    trajectory925 {
      lat
      lng
      alt
    }
    trajectory800 {
      lat
      lng
      alt
    }
    trajectory700 {
      lat
      lng
      alt
    }
    trajectory600 {
      lat
      lng
      alt
    }
    trajectory500 {
      lat
      lng
      alt
    }
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
    tagsData {
      id
      tag
      backgroundColor
      fontColor
    }
    skeyesForecast {
      username
      date
      forecast {
        datetime
        full
        inversions
        issued
        outlook
        period
        slot
        weather
        winds
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFlightGQL extends Apollo.Query<GetFlightQuery, GetFlightQueryVariables> {
    document = GetFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFlightDashboardDocument = gql`
    query getFlightDashboard($flightId: ID!) {
  flight(flightId: $flightId) {
    date
    hour
    icon {
      color
      sign
    }
    id
    period
    status
    liftSpare
    meteo {
      tempTakeoff
      weatherQnh
      wind10
      wind10Direction
      wind20
      wind20Direction
      windGl
      windGlDirection
    }
    passengerCounters {
      id
      passengersCountAdded
      passengersCountInvited
      passengersCountConfirmed
      passengersWeight
    }
    pilot {
      crew {
        id
        isCurrent
        isPilot
        name
        language
        email
        emailNotifications
        pilotLicense
        isCurrent
        medicalWeight
        phone
        totalWeight
      }
      status
    }
    balloon {
      id
      name
      capacity
    }
    location {
      id
      name
    }
    passengerCounters {
      id
      passengersCountAdded
      passengersCountInvited
      passengersCountConfirmed
      passengersWeight
    }
    tagsData {
      id
      tag
      backgroundColor
      fontColor
    }
    bookings {
      id
      list {
        id
        icon
        iconColor
        inviteCount
        city
        contactName
        contactPhone
        contactEmail
        contactLanguage
        comments
        status
        prepaymentAmount
        prepaymentDate
        invoiceName
        VATnumber
        mismatchReasons
        region {
          name
        }
        locationType
        location {
          address
          city
          description
          height
          id
          latitude
          longitude
          maximumHeight
          name
        }
        passengers {
          age
          transport
          category {
            name
            description
          }
          child
          disability
          luggage
          name
          paymentAmount
          paymentReference
          paymentStatus
          paymentType
          totalWeight
          weight
          voucherNumber
          voucher {
            voucherReference
          }
        }
        passengerCounters {
          id
          childCount
          disabilityCount
          transportCount
          openAmount
          paidAmount
          passengerCount
          totalAmount
          totalWeight
          unpaidPassengerCount
        }
        smsLatest {
          date
          direction
          summary
          status
          error
        }
        smsType
        smsUnread
        upUrl
        tags {
          id
          tag
          backgroundColor
          fontColor
        }
      }
      addedCount
      notConfirmedCount
      notConfirmedNames
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFlightDashboardGQL extends Apollo.Query<GetFlightDashboardQuery, GetFlightDashboardQueryVariables> {
    document = GetFlightDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFlightLogsDocument = gql`
    query getFlightLogs($flightId: ID!) {
  flight(flightId: $flightId) {
    logs {
      id
      date
      user
      action
      booking {
        id
        contactName
        removed
        status
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFlightLogsGQL extends Apollo.Query<GetFlightLogsQuery, GetFlightLogsQueryVariables> {
    document = GetFlightLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBookingFlightDocument = gql`
    query getBookingFlight($flightId: ID!) {
  flight(flightId: $flightId) {
    id
    date
    period
    icon {
      sign
      color
    }
    pilot {
      crew {
        id
        name
      }
    }
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      id
      name
    }
    passengerCounters {
      passengersCountConfirmed
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBookingFlightGQL extends Apollo.Query<GetBookingFlightQuery, GetBookingFlightQueryVariables> {
    document = GetBookingFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMobileFlightDocument = gql`
    query getMobileFlight($flightId: ID!) {
  flight(flightId: $flightId) {
    comment
    date
    hour
    hourMeeting
    id
    landingTime
    landingDetails
    landingLatitude
    landingLongitude
    landingLocation
    liftAvailable
    liftNeeded
    liftSpare
    meetingPointSameAsTakeOff
    meetingPointAddress
    meetingPointLatitude
    meetingPointLongitude
    meetingPointURL
    meetingPointCity
    maximumFlightTime
    period
    plannedFlightTime
    spareFlightTime
    takeoffTime
    tempTakeoff
    weatherQnh
    wind10
    wind10Direction
    wind20
    wind20Direction
    windGl
    windGlDirection
    windSummary
    refuelVolume
    icon {
      sign
      color
    }
    pilot {
      crew {
        id
        isCurrent
        isPilot
        licenseExpiryDate
        medicalExpiryDate
        name
      }
    }
    pilotComments
    pilotSignature
    pilotSignOffDate
    balloon {
      id
      capacity
      envelopeInspectionDate
      envelopeType
      insuranceDueDate
      isCurrent
      name
    }
    location {
      address
      name
    }
    passengerCounters {
      passengersCountConfirmed
    }
    bookings {
      list {
        comments
        contactLanguage
        contactName
        contactPhone
        id
        status
        passengerCounters {
          openAmount
        }
        passengers {
          accompany
          address
          age
          transport
          category {
            id
            name
            description
          }
          child
          city
          country
          disability
          email
          id
          luggage
          name
          paymentAmount
          paymentReference
          paymentStatus
          paymentType
          postalCode
          signature
          weight
          voucherNumber
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMobileFlightGQL extends Apollo.Query<GetMobileFlightQuery, GetMobileFlightQueryVariables> {
    document = GetMobileFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCertificateFlightDocument = gql`
    query getCertificateFlight($flightId: ID!) {
  flight(flightId: $flightId) {
    date
    location {
      name
    }
    bookings {
      list {
        passengers {
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCertificateFlightGQL extends Apollo.Query<GetCertificateFlightQuery, GetCertificateFlightQueryVariables> {
    document = GetCertificateFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFlightsOfDashboardDocument = gql`
    query getFlightsOfDashboard {
  flightsOfDashboard {
    balloon {
      id
      name
      isCurrent
    }
    id
    period
    date
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFlightsOfDashboardGQL extends Apollo.Query<GetFlightsOfDashboardQuery, GetFlightsOfDashboardQueryVariables> {
    document = GetFlightsOfDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUpcomingFlightsDocument = gql`
    query getUpcomingFlights($orderBy: OrderByFlight, $orderDirection: OrderDirection, $limit: NonNegativeInt, $cursor: String, $filter: String) {
  upcomingFlights(
    orderBy: $orderBy
    orderDirection: $orderDirection
    limit: $limit
    cursor: $cursor
    filter: $filter
  ) {
    id
    date
    period
    hour
    status
    visibility
    comment
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      id
      name
      city
      address
    }
    passengersCount
    passengersOccupancy
    pilot {
      crew {
        id
        isPilot
        isCurrent
        name
      }
    }
    groundCrews {
      crew {
        id
        name
      }
    }
    icon {
      sign
      color
    }
    bookings {
      id
      list {
        id
        status
        contactName
        passengers {
          id
          name
        }
      }
      count
    }
    passengersData {
      passengersCount
      passengersWeight
      freeSpots
      occupancy
    }
    events {
      id
      description
      date
    }
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
    tagsData {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUpcomingFlightsGQL extends Apollo.Query<GetUpcomingFlightsQuery, GetUpcomingFlightsQueryVariables> {
    document = GetUpcomingFlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUpcomingFlightDocument = gql`
    query getUpcomingFlight($flightId: ID!) {
  flight(flightId: $flightId) {
    id
    date
    period
    hour
    status
    visibility
    comment
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      id
      name
      city
      address
    }
    passengersCount
    passengersOccupancy
    pilot {
      crew {
        isPilot
        isCurrent
        name
      }
    }
    icon {
      sign
      color
    }
    bookings {
      id
      list {
        id
        status
        contactName
        passengers {
          name
        }
      }
      count
    }
    passengerCounters {
      id
      passengersWeight
      freeSpots
    }
    passengersData {
      passengersCount
      passengersWeight
      freeSpots
      occupancy
    }
    events {
      id
      description
      date
    }
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
    tagsData {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUpcomingFlightGQL extends Apollo.Query<GetUpcomingFlightQuery, GetUpcomingFlightQueryVariables> {
    document = GetUpcomingFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetScheduledFlightsDocument = gql`
    query getScheduledFlights($orderBy: OrderByFlight, $orderDirection: OrderDirection, $limit: NonNegativeInt, $cursor: String, $filter: String) {
  scheduledFlights(
    orderBy: $orderBy
    orderDirection: $orderDirection
    limit: $limit
    cursor: $cursor
    filter: $filter
  ) {
    balloon {
      id
      name
    }
    id
    period
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetScheduledFlightsGQL extends Apollo.Query<GetScheduledFlightsQuery, GetScheduledFlightsQueryVariables> {
    document = GetScheduledFlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFlightsListDocument = gql`
    query getFlightsList($cursor: String, $limit: NonNegativeInt, $filter: [String], $filterRange: FilterRangeInput) {
  getFlightsList(
    cursor: $cursor
    limit: $limit
    filter: $filter
    filterRange: $filterRange
  ) {
    id
    date
    period
    hour
    status
    visibility
    comment
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      id
      name
      city
      address
    }
    pilot {
      crew {
        id
        isPilot
        isCurrent
        name
      }
    }
    groundCrews {
      crew {
        id
        name
      }
    }
    icon {
      sign
      color
    }
    bookingsData {
      id
      list {
        id
        status
        icon
        iconColor
        contactName
        passengers {
          name
        }
      }
      count
    }
    passengersData {
      passengersCount
      passengersWeight
      freeSpots
      occupancy
    }
    eventsData {
      id
      description
    }
    tagsData {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFlightsListGQL extends Apollo.Query<GetFlightsListQuery, GetFlightsListQueryVariables> {
    document = GetFlightsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFlightsListSummaryDocument = gql`
    query getFlightsListSummary($cursor: String, $limit: NonNegativeInt, $filter: [String], $filterRange: FilterRangeInput) {
  getFlightsList(
    cursor: $cursor
    limit: $limit
    filter: $filter
    filterRange: $filterRange
  ) {
    id
    date
    period
    hour
    status
    visibility
    balloon {
      id
      name
      capacity
    }
    location {
      id
      name
    }
    pilot {
      crew {
        id
        name
      }
    }
    icon {
      sign
      color
    }
    passengersCount
    passengersOccupancy
    passengersData {
      passengersCount
      passengersWeight
      freeSpots
      occupancy
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFlightsListSummaryGQL extends Apollo.Query<GetFlightsListSummaryQuery, GetFlightsListSummaryQueryVariables> {
    document = GetFlightsListSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHistoricalFlightsListDocument = gql`
    query getHistoricalFlightsList($cursor: String, $limit: NonNegativeInt, $filter: [String], $filterRange: FilterRangeInput) {
  getHistoricalFlightsList(
    cursor: $cursor
    limit: $limit
    filter: $filter
    filterRange: $filterRange
  ) {
    id
    date
    period
    hour
    status
    visibility
    comment
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      id
      name
      city
      address
    }
    passengersCount
    passengersOccupancy
    pilot {
      crew {
        id
        isPilot
        isCurrent
        name
      }
    }
    icon {
      sign
      color
    }
    bookingsData {
      id
      list {
        id
        status
        contactName
        passengers {
          id
          name
        }
      }
      count
    }
    passengersData {
      passengersCount
      passengersWeight
      freeSpots
      occupancy
    }
    events {
      id
      description
      date
    }
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
    tagsData {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHistoricalFlightsListGQL extends Apollo.Query<GetHistoricalFlightsListQuery, GetHistoricalFlightsListQueryVariables> {
    document = GetHistoricalFlightsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHistoricalFlightsListSummaryDocument = gql`
    query getHistoricalFlightsListSummary($cursor: String, $limit: NonNegativeInt, $filter: [String], $filterRange: FilterRangeInput) {
  getHistoricalFlightsList(
    cursor: $cursor
    limit: $limit
    filter: $filter
    filterRange: $filterRange
  ) {
    id
    date
    period
    hour
    status
    visibility
    balloon {
      id
      name
      capacity
    }
    location {
      id
      name
    }
    pilot {
      crew {
        id
        name
      }
    }
    groundCrews {
      crew {
        id
        name
      }
    }
    icon {
      sign
      color
    }
    passengersCount
    passengersOccupancy
    passengersData {
      passengersCount
      passengersWeight
      freeSpots
      occupancy
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHistoricalFlightsListSummaryGQL extends Apollo.Query<GetHistoricalFlightsListSummaryQuery, GetHistoricalFlightsListSummaryQueryVariables> {
    document = GetHistoricalFlightsListSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFlightsListFromDateDocument = gql`
    query getFlightsListFromDate($date: String!) {
  flightsOfDate(date: $date) {
    id
    date
    period
    hour
    status
    visibility
    comment
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      name
      city
      address
    }
    passengersCount
    passengersOccupancy
    pilot {
      crew {
        id
        isPilot
        isCurrent
        name
      }
    }
    groundCrews {
      crew {
        availability
        id
        name
        isPilot
      }
      status
    }
    icon {
      sign
      color
    }
    bookingsData {
      list {
        id
        status
        contactName
        passengers {
          name
        }
      }
      count
    }
    passengerCounters {
      passengersWeight
      freeSpots
    }
    events {
      id
      description
      date
    }
    eventsData {
      id
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFlightsListFromDateGQL extends Apollo.Query<GetFlightsListFromDateQuery, GetFlightsListFromDateQueryVariables> {
    document = GetFlightsListFromDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFlightsListFromDateCrewDocument = gql`
    query getFlightsListFromDateCrew($date: String!) {
  flightsOfDateCrew(date: $date) {
    id
    date
    period
    hour
    status
    visibility
    comment
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      name
      city
      address
    }
    pilot {
      crew {
        id
        isPilot
        isCurrent
        name
      }
    }
    groundCrews {
      crew {
        availability
        id
        name
        isPilot
      }
      status
    }
    icon {
      sign
      color
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFlightsListFromDateCrewGQL extends Apollo.Query<GetFlightsListFromDateCrewQuery, GetFlightsListFromDateCrewQueryVariables> {
    document = GetFlightsListFromDateCrewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FlightCountersDocument = gql`
    query flightCounters {
  flightCounters {
    upcoming
    historical
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FlightCountersGQL extends Apollo.Query<FlightCountersQuery, FlightCountersQueryVariables> {
    document = FlightCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FlightPeriodsDocument = gql`
    query flightPeriods {
  flightPeriods {
    day {
      id
      startDate
      endDate
      label
      EVE {
        flightsCount
      }
      MOR {
        flightsCount
      }
    }
    week {
      id
      startDate
      endDate
      label
      EVE {
        flightsCount
      }
      MOR {
        flightsCount
      }
    }
    month {
      id
      startDate
      endDate
      label
      EVE {
        flightsCount
      }
      MOR {
        flightsCount
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FlightPeriodsGQL extends Apollo.Query<FlightPeriodsQuery, FlightPeriodsQueryVariables> {
    document = FlightPeriodsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FlightCountersUpdatedDocument = gql`
    subscription flightCountersUpdated {
  flightCountersUpdated {
    upcoming
    historical
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FlightCountersUpdatedGQL extends Apollo.Subscription<FlightCountersUpdatedSubscription, FlightCountersUpdatedSubscriptionVariables> {
    document = FlightCountersUpdatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveFlightDocument = gql`
    mutation saveFlight($flightInput: FlightInput!, $flightId: ID) {
  saveFlight(flightInput: $flightInput, flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveFlightGQL extends Apollo.Mutation<SaveFlightMutation, SaveFlightMutationVariables> {
    document = SaveFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFlightDocument = gql`
    mutation deleteFlight($flightId: ID!) {
  deleteFlight(flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFlightGQL extends Apollo.Mutation<DeleteFlightMutation, DeleteFlightMutationVariables> {
    document = DeleteFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ToggleFlightVisibilityDocument = gql`
    mutation toggleFlightVisibility($flightId: ID!) {
  toggleFlightVisibility(flightId: $flightId) {
    id
    visibility
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleFlightVisibilityGQL extends Apollo.Mutation<ToggleFlightVisibilityMutation, ToggleFlightVisibilityMutationVariables> {
    document = ToggleFlightVisibilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeFlightStatusDocument = gql`
    mutation changeFlightStatus($flightId: ID!, $flightStatus: FlightStatus!) {
  changeFlightStatus(flightId: $flightId, flightStatus: $flightStatus) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeFlightStatusGQL extends Apollo.Mutation<ChangeFlightStatusMutation, ChangeFlightStatusMutationVariables> {
    document = ChangeFlightStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveFlightCrewDocument = gql`
    mutation saveFlightCrew($flightCrewInput: [FlightCrewInput], $flightId: ID!) {
  saveFlightCrew(flightCrewInput: $flightCrewInput, flightId: $flightId) {
    id
    balloon {
      id
      name
      callSign
      capacity
    }
    groundCrews {
      crew {
        id
        name
        isPilot
      }
      status
    }
    location {
      id
      name
    }
    date
    hour
    period
    pilot {
      crew {
        id
        name
        isPilot
        isCurrent
      }
      status
    }
    status
    passengersCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveFlightCrewGQL extends Apollo.Mutation<SaveFlightCrewMutation, SaveFlightCrewMutationVariables> {
    document = SaveFlightCrewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCrewToFlightFromCalendarDocument = gql`
    mutation addCrewToFlightFromCalendar($flightCrewInput: FlightCrewInput, $flightId: ID!) {
  addCrewToFlightFromCalendar(
    flightCrewInput: $flightCrewInput
    flightId: $flightId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCrewToFlightFromCalendarGQL extends Apollo.Mutation<AddCrewToFlightFromCalendarMutation, AddCrewToFlightFromCalendarMutationVariables> {
    document = AddCrewToFlightFromCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SavePilotSignOffDocument = gql`
    mutation savePilotSignOff($pilotSignOffInput: PilotSignOffInput!, $flightId: ID!) {
  savePilotSignOff(pilotSignOffInput: $pilotSignOffInput, flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SavePilotSignOffGQL extends Apollo.Mutation<SavePilotSignOffMutation, SavePilotSignOffMutationVariables> {
    document = SavePilotSignOffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveFlightLandingDocument = gql`
    mutation saveFlightLanding($landingInput: LandingInput!, $flightId: ID!) {
  saveFlightLanding(landingInput: $landingInput, flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveFlightLandingGQL extends Apollo.Mutation<SaveFlightLandingMutation, SaveFlightLandingMutationVariables> {
    document = SaveFlightLandingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CopyWeatherDocument = gql`
    mutation copyWeather($sourceFlightId: ID!, $destinationFlightIds: [ID]) {
  copyWeather(
    sourceFlightId: $sourceFlightId
    destinationFlightIds: $destinationFlightIds
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CopyWeatherGQL extends Apollo.Mutation<CopyWeatherMutation, CopyWeatherMutationVariables> {
    document = CopyWeatherDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CopyFlightDocument = gql`
    mutation copyFlight($sourceFlightId: ID!, $destinationDates: [String], $timezoneOffset: Int) {
  copyFlight(
    sourceFlightId: $sourceFlightId
    destinationDates: $destinationDates
    timezoneOffset: $timezoneOffset
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CopyFlightGQL extends Apollo.Mutation<CopyFlightMutation, CopyFlightMutationVariables> {
    document = CopyFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFlightCommentsDocument = gql`
    mutation updateFlightComments($flightId: ID!, $comment: String) {
  updateFlightComments(flightId: $flightId, comment: $comment) {
    id
    date
    period
    hour
    status
    visibility
    comment
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      id
      name
      city
      address
    }
    passengersCount
    passengersOccupancy
    pilot {
      crew {
        id
        isPilot
        isCurrent
        name
      }
    }
    icon {
      sign
      color
    }
    bookingsData {
      id
      list {
        id
        status
        contactName
        passengers {
          name
        }
      }
      count
    }
    passengerCounters {
      id
      passengersWeight
      freeSpots
    }
    events {
      id
      description
      date
    }
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
    tagsData {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFlightCommentsGQL extends Apollo.Mutation<UpdateFlightCommentsMutation, UpdateFlightCommentsMutationVariables> {
    document = UpdateFlightCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFlightTagsDocument = gql`
    mutation updateFlightTags($flightId: ID!, $tags: [String]) {
  updateFlightTags(flightId: $flightId, tags: $tags) {
    id
    date
    period
    hour
    status
    visibility
    comment
    balloon {
      id
      name
      capacity
      isCurrent
    }
    location {
      id
      name
      city
      address
    }
    passengersCount
    passengersOccupancy
    pilot {
      crew {
        id
        isPilot
        isCurrent
        name
      }
    }
    icon {
      sign
      color
    }
    bookingsData {
      id
      list {
        id
        status
        contactName
        passengers {
          name
        }
      }
      count
    }
    passengerCounters {
      id
      passengersWeight
      freeSpots
    }
    passengersData {
      passengersCount
      passengersWeight
      freeSpots
      occupancy
    }
    events {
      id
      description
      date
    }
    tags {
      id
      tag
      backgroundColor
      fontColor
    }
    tagsData {
      id
      tag
      backgroundColor
      fontColor
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFlightTagsGQL extends Apollo.Mutation<UpdateFlightTagsMutation, UpdateFlightTagsMutationVariables> {
    document = UpdateFlightTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLocationsDocument = gql`
    query getLocations {
  locations {
    address
    city
    country
    default
    id
    latitude
    longitude
    name
    postalCode
    region {
      color
      name
    }
    meetingPointSameAsTakeOff
    meetingPointAddress
    meetingPointLatitude
    meetingPointLongitude
    meetingPointURL
    meetingPointCity
    contact {
      name
      media
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLocationsGQL extends Apollo.Query<GetLocationsQuery, GetLocationsQueryVariables> {
    document = GetLocationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLocationDocument = gql`
    query getLocation($locationId: ID!) {
  location(locationId: $locationId) {
    id
    address
    city
    country
    contact {
      comment
      email
      emailTemplate
      name
      phone
      smsTemplate
      language
      media
    }
    default
    description
    height
    latitude
    longitude
    maximumHeight
    meetingPointSameAsTakeOff
    meetingPointAddress
    meetingPointLatitude
    meetingPointLongitude
    meetingPointURL
    meetingPointCity
    name
    postalCode
    forecasts {
      slot
      forecast {
        datepart {
          part
          text
        }
        daytime
        flytime
        lastUpdate
        latitude
        longitude
        lowCloudsData
        pictoCodeData
        precipitationData
        pressureData
        sunrise
        sunset
        temperatureData
        time
        utc_timeoffset
        visibilityData
        windDirectionData
        windDirectionData_1000
        windDirectionData_500
        windDirectionData_550
        windDirectionData_600
        windDirectionData_650
        windDirectionData_700
        windDirectionData_750
        windDirectionData_800
        windDirectionData_925
        windDirectionData_950
        windDirectionData_975
        windGustData
        windSpeedData
        windSpeedData_1000
        windSpeedData_500
        windSpeedData_550
        windSpeedData_600
        windSpeedData_650
        windSpeedData_700
        windSpeedData_750
        windSpeedData_800
        windSpeedData_925
        windSpeedData_950
        windSpeedData_975
      }
    }
    region {
      color
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLocationGQL extends Apollo.Query<GetLocationQuery, GetLocationQueryVariables> {
    document = GetLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGeocodeDocument = gql`
    query getGeocode($address: String!) {
  geocode(address: $address) {
    geometry {
      location {
        lat
        lng
      }
    }
    elevation {
      elevation
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGeocodeGQL extends Apollo.Query<GetGeocodeQuery, GetGeocodeQueryVariables> {
    document = GetGeocodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReverseGeocodeDocument = gql`
    query reverseGeocode($lat: Float!, $lng: Float!) {
  reverseGeocode(lat: $lat, lng: $lng)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReverseGeocodeGQL extends Apollo.Query<ReverseGeocodeQuery, ReverseGeocodeQueryVariables> {
    document = ReverseGeocodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveLocationDocument = gql`
    mutation saveLocation($locationInput: LocationInput!, $regions: [ID]!, $locationId: ID) {
  saveLocation(
    locationInput: $locationInput
    regions: $regions
    locationId: $locationId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveLocationGQL extends Apollo.Mutation<SaveLocationMutation, SaveLocationMutationVariables> {
    document = SaveLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteLocationDocument = gql`
    mutation deleteLocation($locationId: ID!) {
  deleteLocation(locationId: $locationId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteLocationGQL extends Apollo.Mutation<DeleteLocationMutation, DeleteLocationMutationVariables> {
    document = DeleteLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMeteoDocument = gql`
    mutation updateMeteo($locationId: ID!, $flightId: ID!) {
  updateMeteo(locationId: $locationId, flightId: $flightId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMeteoGQL extends Apollo.Mutation<UpdateMeteoMutation, UpdateMeteoMutationVariables> {
    document = UpdateMeteoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSkeyesForecastDocument = gql`
    query getSkeyesForecast($slot: String!) {
  getSkeyesForecast(slot: $slot) {
    id
    datetime
    full
    inversions
    issued
    outlook
    period
    slot
    weather
    winds
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSkeyesForecastGQL extends Apollo.Query<GetSkeyesForecastQuery, GetSkeyesForecastQueryVariables> {
    document = GetSkeyesForecastDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchSkeyesForecastDocument = gql`
    query fetchSkeyesForecast($username: String!, $password: String!, $flightId: ID!) {
  fetchSkeyesForecast(
    username: $username
    password: $password
    flightId: $flightId
  ) {
    username
    date
    forecast {
      datetime
      full
      inversions
      issued
      outlook
      period
      slot
      weather
      winds
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchSkeyesForecastGQL extends Apollo.Query<FetchSkeyesForecastQuery, FetchSkeyesForecastQueryVariables> {
    document = FetchSkeyesForecastDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QuestionsDocument = gql`
    query questions($active: Boolean) {
  questions(active: $active) {
    id
    active
    code
    content {
      description {
        lang
        text
      }
      order
      title {
        lang
        text
      }
    }
    kind
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QuestionsGQL extends Apollo.Query<QuestionsQuery, QuestionsQueryVariables> {
    document = QuestionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveQuestionTextDocument = gql`
    mutation saveQuestionText($questionInput: QuestionInput!, $questionId: ID!) {
  saveQuestionText(questionInput: $questionInput, questionId: $questionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveQuestionTextGQL extends Apollo.Mutation<SaveQuestionTextMutation, SaveQuestionTextMutationVariables> {
    document = SaveQuestionTextDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveQuestionOrderDocument = gql`
    mutation saveQuestionOrder($order: NonNegativeInt!, $questionId: ID!) {
  saveQuestionOrder(order: $order, questionId: $questionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveQuestionOrderGQL extends Apollo.Mutation<SaveQuestionOrderMutation, SaveQuestionOrderMutationVariables> {
    document = SaveQuestionOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ToggleQuestionActiveDocument = gql`
    mutation toggleQuestionActive($questionId: ID!) {
  toggleQuestionActive(questionId: $questionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleQuestionActiveGQL extends Apollo.Mutation<ToggleQuestionActiveMutation, ToggleQuestionActiveMutationVariables> {
    document = ToggleQuestionActiveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRegionsDocument = gql`
    query getRegions {
  regions {
    color
    defaultColor
    id
    name
    locations {
      name
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRegionsGQL extends Apollo.Query<GetRegionsQuery, GetRegionsQueryVariables> {
    document = GetRegionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRegionDocument = gql`
    query getRegion($regionId: ID!) {
  region(regionId: $regionId) {
    color
    defaultColor
    name
    id
    locations {
      name
      latitude
      longitude
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRegionGQL extends Apollo.Query<GetRegionQuery, GetRegionQueryVariables> {
    document = GetRegionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveRegionDocument = gql`
    mutation saveRegion($regionInput: RegionInput!, $regionId: ID) {
  saveRegion(regionInput: $regionInput, regionId: $regionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveRegionGQL extends Apollo.Mutation<SaveRegionMutation, SaveRegionMutationVariables> {
    document = SaveRegionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteRegionDocument = gql`
    mutation deleteRegion($regionId: ID!) {
  deleteRegion(regionId: $regionId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRegionGQL extends Apollo.Mutation<DeleteRegionMutation, DeleteRegionMutationVariables> {
    document = DeleteRegionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReportsDocument = gql`
    query getReports {
  reports {
    flights
    bookings
    bookingsOpen
    vouchers
    payments
    sms
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReportsGQL extends Apollo.Query<GetReportsQuery, GetReportsQueryVariables> {
    document = GetReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ServerStatusDocument = gql`
    query serverStatus {
  serverStatus {
    maintenance
    start
    purpose
    expectedDuration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ServerStatusGQL extends Apollo.Query<ServerStatusQuery, ServerStatusQueryVariables> {
    document = ServerStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveLanguageSettingDocument = gql`
    mutation saveLanguageSetting($systemLanguage: String!) {
  saveLanguageSetting(systemLanguage: $systemLanguage)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveLanguageSettingGQL extends Apollo.Mutation<SaveLanguageSettingMutation, SaveLanguageSettingMutationVariables> {
    document = SaveLanguageSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SmsByBookingDocument = gql`
    query smsByBooking($bookingId: ID!) {
  smsByBooking(bookingId: $bookingId) {
    date {
      sms
      read
    }
    direction
    text
    eventSummary {
      status
      error
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SmsByBookingGQL extends Apollo.Query<SmsByBookingQuery, SmsByBookingQueryVariables> {
    document = SmsByBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SmsByCrewDocument = gql`
    query smsByCrew($crewId: ID!) {
  smsByCrew(crewId: $crewId) {
    date {
      sms
      read
    }
    direction
    text
    eventSummary {
      status
      error
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SmsByCrewGQL extends Apollo.Query<SmsByCrewQuery, SmsByCrewQueryVariables> {
    document = SmsByCrewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnreadSmsDocument = gql`
    query unreadSms {
  unreadSms {
    date {
      sms
    }
    text
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnreadSmsGQL extends Apollo.Query<UnreadSmsQuery, UnreadSmsQueryVariables> {
    document = UnreadSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllSmsTemplatesDocument = gql`
    query getAllSmsTemplates {
  getAllSmsTemplates {
    id
    template {
      en
      fr
      nl
      de
    }
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllSmsTemplatesGQL extends Apollo.Query<GetAllSmsTemplatesQuery, GetAllSmsTemplatesQueryVariables> {
    document = GetAllSmsTemplatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSmsTemplatesDocument = gql`
    query getSmsTemplates($type: String!) {
  getSmsTemplates(type: $type) {
    template {
      en
      fr
      nl
      de
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSmsTemplatesGQL extends Apollo.Query<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables> {
    document = GetSmsTemplatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkBookingMessagesReadDocument = gql`
    mutation markBookingMessagesRead($bookingId: ID!) {
  markBookingMessagesRead(bookingId: $bookingId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkBookingMessagesReadGQL extends Apollo.Mutation<MarkBookingMessagesReadMutation, MarkBookingMessagesReadMutationVariables> {
    document = MarkBookingMessagesReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddSmsTemplateLocationContactDocument = gql`
    mutation addSmsTemplateLocationContact {
  addSmsTemplateLocationContact {
    id
    template {
      en
      fr
      nl
      de
    }
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddSmsTemplateLocationContactGQL extends Apollo.Mutation<AddSmsTemplateLocationContactMutation, AddSmsTemplateLocationContactMutationVariables> {
    document = AddSmsTemplateLocationContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkCrewMessagesReadDocument = gql`
    mutation markCrewMessagesRead($crewId: ID!) {
  markCrewMessagesRead(crewId: $crewId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkCrewMessagesReadGQL extends Apollo.Mutation<MarkCrewMessagesReadMutation, MarkCrewMessagesReadMutationVariables> {
    document = MarkCrewMessagesReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveSmsTemplateDocument = gql`
    mutation saveSmsTemplate($smsTemplateInput: SmsTemplateInput!, $smsTemplateId: ID!) {
  saveSmsTemplate(
    smsTemplateInput: $smsTemplateInput
    smsTemplateId: $smsTemplateId
  ) {
    id
    template {
      en
      fr
      nl
      de
    }
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveSmsTemplateGQL extends Apollo.Mutation<SaveSmsTemplateMutation, SaveSmsTemplateMutationVariables> {
    document = SaveSmsTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendBookingSmsDocument = gql`
    mutation sendBookingSms($bookingId: ID!, $smsInput: SmsInput!) {
  sendBookingSms(bookingId: $bookingId, smsInput: $smsInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendBookingSmsGQL extends Apollo.Mutation<SendBookingSmsMutation, SendBookingSmsMutationVariables> {
    document = SendBookingSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendLocationSmsDocument = gql`
    mutation sendLocationSms($locationId: ID!, $flightId: ID!, $smsInput: SmsInput!) {
  sendLocationSms(
    locationId: $locationId
    flightId: $flightId
    smsInput: $smsInput
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendLocationSmsGQL extends Apollo.Mutation<SendLocationSmsMutation, SendLocationSmsMutationVariables> {
    document = SendLocationSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendCrewSmsDocument = gql`
    mutation sendCrewSms($crewId: ID!, $smsInput: SmsInput!) {
  sendCrewSms(crewId: $crewId, smsInput: $smsInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendCrewSmsGQL extends Apollo.Mutation<SendCrewSmsMutation, SendCrewSmsMutationVariables> {
    document = SendCrewSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewSmsDocument = gql`
    subscription newSms {
  newSms {
    date {
      sms
    }
    text
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewSmsGQL extends Apollo.Subscription<NewSmsSubscription, NewSmsSubscriptionVariables> {
    document = NewSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SunriseSunsetDocument = gql`
    query sunriseSunset($sunriseSunsetInput: SunriseSunsetInput!) {
  sunriseSunset(sunriseSunsetInput: $sunriseSunsetInput) {
    sunrise
    sunset
    morningFlight
    eveningFlight
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SunriseSunsetGQL extends Apollo.Query<SunriseSunsetQuery, SunriseSunsetQueryVariables> {
    document = SunriseSunsetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllTagsDocument = gql`
    query getAllTags {
  getAllTags {
    id
    tag
    type
    backgroundColor
    fontColor
    type
    enabled
    locked
    dashboard
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllTagsGQL extends Apollo.Query<GetAllTagsQuery, GetAllTagsQueryVariables> {
    document = GetAllTagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllTagsWithCountersDocument = gql`
    query getAllTagsWithCounters {
  getAllTags {
    id
    tag
    type
    backgroundColor
    fontColor
    type
    enabled
    upcoming
    history
    locked
    dashboard
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllTagsWithCountersGQL extends Apollo.Query<GetAllTagsWithCountersQuery, GetAllTagsWithCountersQueryVariables> {
    document = GetAllTagsWithCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagDocument = gql`
    query getTag($tagId: ID!) {
  getTag(tagId: $tagId) {
    id
    tag
    type
    backgroundColor
    fontColor
    type
    enabled
    locked
    dashboard
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagGQL extends Apollo.Query<GetTagQuery, GetTagQueryVariables> {
    document = GetTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagWithCountersDocument = gql`
    query getTagWithCounters($tagId: ID!) {
  getTag(tagId: $tagId) {
    id
    tag
    type
    backgroundColor
    fontColor
    type
    enabled
    upcoming
    history
    locked
    dashboard
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagWithCountersGQL extends Apollo.Query<GetTagWithCountersQuery, GetTagWithCountersQueryVariables> {
    document = GetTagWithCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsOfTypeDocument = gql`
    query getTagsOfType($tagType: TagType!) {
  getTagsOfType(tagType: $tagType) {
    id
    tag
    type
    backgroundColor
    fontColor
    type
    enabled
    locked
    dashboard
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsOfTypeGQL extends Apollo.Query<GetTagsOfTypeQuery, GetTagsOfTypeQueryVariables> {
    document = GetTagsOfTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsOfTypeWithCountersDocument = gql`
    query getTagsOfTypeWithCounters($tagType: TagType!) {
  getTagsOfType(tagType: $tagType) {
    id
    tag
    type
    backgroundColor
    fontColor
    type
    enabled
    upcoming
    history
    locked
    dashboard
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsOfTypeWithCountersGQL extends Apollo.Query<GetTagsOfTypeWithCountersQuery, GetTagsOfTypeWithCountersQueryVariables> {
    document = GetTagsOfTypeWithCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsOfTypeWithUpcomingCountersDocument = gql`
    query getTagsOfTypeWithUpcomingCounters($tagType: TagType!) {
  getTagsOfType(tagType: $tagType) {
    id
    tag
    type
    backgroundColor
    fontColor
    type
    enabled
    upcoming
    locked
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsOfTypeWithUpcomingCountersGQL extends Apollo.Query<GetTagsOfTypeWithUpcomingCountersQuery, GetTagsOfTypeWithUpcomingCountersQueryVariables> {
    document = GetTagsOfTypeWithUpcomingCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTagsOfTypeWithHistoryCountersDocument = gql`
    query getTagsOfTypeWithHistoryCounters($tagType: TagType!) {
  getTagsOfType(tagType: $tagType) {
    id
    tag
    type
    backgroundColor
    fontColor
    type
    enabled
    history
    locked
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTagsOfTypeWithHistoryCountersGQL extends Apollo.Query<GetTagsOfTypeWithHistoryCountersQuery, GetTagsOfTypeWithHistoryCountersQueryVariables> {
    document = GetTagsOfTypeWithHistoryCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveTagDocument = gql`
    mutation saveTag($tagInput: TagInput!, $tagId: ID) {
  saveTag(tagInput: $tagInput, tagId: $tagId) {
    id
    tag
    backgroundColor
    fontColor
    type
    enabled
    locked
    dashboard
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveTagGQL extends Apollo.Mutation<SaveTagMutation, SaveTagMutationVariables> {
    document = SaveTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTagDocument = gql`
    mutation deleteTag($tagId: ID!) {
  deleteTag(tagId: $tagId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTagGQL extends Apollo.Mutation<DeleteTagMutation, DeleteTagMutationVariables> {
    document = DeleteTagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTenantSettingDocument = gql`
    query getTenantSetting {
  tenantSetting {
    upEnabled
    icsId
    mailFrom {
      email
      name
    }
    mailReplyTo {
      email
      name
    }
    mailAccountant {
      email
      name
    }
    locationOptions {
      defaultLocation {
        id
      }
    }
    tenantId
    name
    operatorSettings {
      currency
      backup {
        active
        email
      }
      bookings {
        childAge
        showPayments
        disabledPaymentTypes
      }
      crew {
        shareBookings
        allowFlightSelection
      }
      flights {
        sunriseOffset
        sunsetOffset
        meetingTimeOffset
      }
      sms {
        footerEnabled
      }
      timezone
      manifest {
        footer
        pilotDeclaration
      }
      myflight {
        autoConfirm
        flights
        endOfSeason
      }
      trajectoryProfile {
        duration
        level
      }
    }
    dashboardOptions {
      preferredDateToday
      recent
      toConfirm
    }
    subscription {
      sms {
        available
        usedSMS {
          year
          month
          used
        }
        pctUsed
        ignoreWarning
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTenantSettingGQL extends Apollo.Query<GetTenantSettingQuery, GetTenantSettingQueryVariables> {
    document = GetTenantSettingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllReleaseNotesDocument = gql`
    query getAllReleaseNotes {
  allReleaseNotes {
    id
    date
    description
    documentation
    oneliner
    video
    tenants
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllReleaseNotesGQL extends Apollo.Query<GetAllReleaseNotesQuery, GetAllReleaseNotesQueryVariables> {
    document = GetAllReleaseNotesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReleaseNotesDocument = gql`
    query getReleaseNotes {
  releaseNotes {
    id
    date
    description
    documentation
    oneliner
    video
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReleaseNotesGQL extends Apollo.Query<GetReleaseNotesQuery, GetReleaseNotesQueryVariables> {
    document = GetReleaseNotesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReleaseNoteDocument = gql`
    query getReleaseNote($releaseNoteId: ID!) {
  releaseNote(releaseNoteId: $releaseNoteId) {
    id
    date
    description
    documentation
    oneliner
    video
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReleaseNoteGQL extends Apollo.Query<GetReleaseNoteQuery, GetReleaseNoteQueryVariables> {
    document = GetReleaseNoteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetReleaseNotesCountDocument = gql`
    query getReleaseNotesCount {
  releaseNotesCount
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetReleaseNotesCountGQL extends Apollo.Query<GetReleaseNotesCountQuery, GetReleaseNotesCountQueryVariables> {
    document = GetReleaseNotesCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTenantSettingMyFlightDocument = gql`
    query getTenantSettingMyFlight {
  tenantSetting {
    operatorSettings {
      myflight {
        autoConfirm
        flights
        endOfSeason
        colors
        logo
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTenantSettingMyFlightGQL extends Apollo.Query<GetTenantSettingMyFlightQuery, GetTenantSettingMyFlightQueryVariables> {
    document = GetTenantSettingMyFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubscriptionDocument = gql`
    query getSubscription {
  tenantSetting {
    subscription {
      sms {
        available
        usedSMS {
          year
          month
          used
        }
        pctUsed
        ignoreWarning
      }
      plan {
        name
        seats
        used
        pctUsed
        validUntil
        valid
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubscriptionGQL extends Apollo.Query<GetSubscriptionQuery, GetSubscriptionQueryVariables> {
    document = GetSubscriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDefaultLocationDocument = gql`
    query getDefaultLocation {
  tenantSetting {
    locationOptions {
      defaultLocation {
        id
        address
        city
        country
        default
        description
        height
        latitude
        longitude
        maximumHeight
        name
        postalCode
        forecasts {
          slot
          forecast {
            datepart {
              part
              text
            }
            daytime
            flytime
            lastUpdate
            latitude
            longitude
            lowCloudsData
            pictoCodeData
            precipitationData
            pressureData
            sunrise
            sunset
            temperatureData
            time
            utc_timeoffset
            visibilityData
            windDirectionData
            windDirectionData_1000
            windDirectionData_500
            windDirectionData_550
            windDirectionData_600
            windDirectionData_650
            windDirectionData_700
            windDirectionData_750
            windDirectionData_800
            windDirectionData_925
            windDirectionData_950
            windDirectionData_975
            windGustData
            windSpeedData
            windSpeedData_1000
            windSpeedData_500
            windSpeedData_550
            windSpeedData_600
            windSpeedData_650
            windSpeedData_700
            windSpeedData_750
            windSpeedData_800
            windSpeedData_925
            windSpeedData_950
            windSpeedData_975
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDefaultLocationGQL extends Apollo.Query<GetDefaultLocationQuery, GetDefaultLocationQueryVariables> {
    document = GetDefaultLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUpUrlDocument = gql`
    query getUpUrl($bookingId: ID!) {
  upUrl(bookingId: $bookingId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUpUrlGQL extends Apollo.Query<GetUpUrlQuery, GetUpUrlQueryVariables> {
    document = GetUpUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantLocationOptionsDocument = gql`
    mutation updateTenantLocationOptions($options: InputLocationOptions!) {
  updateTenantLocationOptions(options: $options) {
    locationOptions {
      defaultLocation {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantLocationOptionsGQL extends Apollo.Mutation<UpdateTenantLocationOptionsMutation, UpdateTenantLocationOptionsMutationVariables> {
    document = UpdateTenantLocationOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantOperatorSettingsBackupDocument = gql`
    mutation updateTenantOperatorSettingsBackup($settings: InputOperatorSettingsBackup!) {
  updateTenantOperatorSettingsBackup(settings: $settings) {
    operatorSettings {
      backup {
        active
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantOperatorSettingsBackupGQL extends Apollo.Mutation<UpdateTenantOperatorSettingsBackupMutation, UpdateTenantOperatorSettingsBackupMutationVariables> {
    document = UpdateTenantOperatorSettingsBackupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantOperatorSettingsCrewDocument = gql`
    mutation updateTenantOperatorSettingsCrew($settings: InputOperatorSettingsCrew!) {
  updateTenantOperatorSettingsCrew(settings: $settings) {
    operatorSettings {
      crew {
        shareBookings
        allowFlightSelection
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantOperatorSettingsCrewGQL extends Apollo.Mutation<UpdateTenantOperatorSettingsCrewMutation, UpdateTenantOperatorSettingsCrewMutationVariables> {
    document = UpdateTenantOperatorSettingsCrewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantOperatorSettingsTrajectoryProfileDocument = gql`
    mutation updateTenantOperatorSettingsTrajectoryProfile($settings: [InputOperatorSettingsTrajectoryProfile]!) {
  updateTenantOperatorSettingsTrajectoryProfile(settings: $settings) {
    operatorSettings {
      trajectoryProfile {
        duration
        level
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantOperatorSettingsTrajectoryProfileGQL extends Apollo.Mutation<UpdateTenantOperatorSettingsTrajectoryProfileMutation, UpdateTenantOperatorSettingsTrajectoryProfileMutationVariables> {
    document = UpdateTenantOperatorSettingsTrajectoryProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantOperatorSettingsMyFlightDocument = gql`
    mutation updateTenantOperatorSettingsMyFlight($settings: InputOperatorSettingsMyFlight!) {
  updateTenantOperatorSettingsMyFlight(settings: $settings) {
    operatorSettings {
      myflight {
        autoConfirm
        flights
        endOfSeason
        colors
        logo
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantOperatorSettingsMyFlightGQL extends Apollo.Mutation<UpdateTenantOperatorSettingsMyFlightMutation, UpdateTenantOperatorSettingsMyFlightMutationVariables> {
    document = UpdateTenantOperatorSettingsMyFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantOperatorSettingsFlightsDocument = gql`
    mutation updateTenantOperatorSettingsFlights($settings: InputOperatorSettingsFlights!) {
  updateTenantOperatorSettingsFlights(settings: $settings) {
    operatorSettings {
      flights {
        sunriseOffset
        sunsetOffset
        meetingTimeOffset
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantOperatorSettingsFlightsGQL extends Apollo.Mutation<UpdateTenantOperatorSettingsFlightsMutation, UpdateTenantOperatorSettingsFlightsMutationVariables> {
    document = UpdateTenantOperatorSettingsFlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantOperatorSettingsSmsDocument = gql`
    mutation updateTenantOperatorSettingsSms($settings: InputOperatorSettingsSms!) {
  updateTenantOperatorSettingsSms(settings: $settings) {
    operatorSettings {
      sms {
        footerEnabled
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantOperatorSettingsSmsGQL extends Apollo.Mutation<UpdateTenantOperatorSettingsSmsMutation, UpdateTenantOperatorSettingsSmsMutationVariables> {
    document = UpdateTenantOperatorSettingsSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantOperatorSettingsBookingsChildAgeDocument = gql`
    mutation updateTenantOperatorSettingsBookingsChildAge($childAge: NonNegativeInt) {
  updateTenantOperatorSettingsBookingsChildAge(childAge: $childAge) {
    operatorSettings {
      bookings {
        childAge
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantOperatorSettingsBookingsChildAgeGQL extends Apollo.Mutation<UpdateTenantOperatorSettingsBookingsChildAgeMutation, UpdateTenantOperatorSettingsBookingsChildAgeMutationVariables> {
    document = UpdateTenantOperatorSettingsBookingsChildAgeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTenantOperatorSettingsDisabledPaymentTypesDocument = gql`
    mutation updateTenantOperatorSettingsDisabledPaymentTypes($disabledPaymentTypes: [String]!) {
  updateTenantOperatorSettingsDisabledPaymentTypes(
    disabledPaymentTypes: $disabledPaymentTypes
  ) {
    operatorSettings {
      bookings {
        disabledPaymentTypes
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTenantOperatorSettingsDisabledPaymentTypesGQL extends Apollo.Mutation<UpdateTenantOperatorSettingsDisabledPaymentTypesMutation, UpdateTenantOperatorSettingsDisabledPaymentTypesMutationVariables> {
    document = UpdateTenantOperatorSettingsDisabledPaymentTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ToggleTenantOperatorSettingsBookingsShowPaymentsDocument = gql`
    mutation toggleTenantOperatorSettingsBookingsShowPayments {
  toggleTenantOperatorSettingsBookingsShowPayments {
    operatorSettings {
      bookings {
        showPayments
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToggleTenantOperatorSettingsBookingsShowPaymentsGQL extends Apollo.Mutation<ToggleTenantOperatorSettingsBookingsShowPaymentsMutation, ToggleTenantOperatorSettingsBookingsShowPaymentsMutationVariables> {
    document = ToggleTenantOperatorSettingsBookingsShowPaymentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionSmsIgnoreWarningDocument = gql`
    mutation subscriptionSmsIgnoreWarning($ignore: Boolean!) {
  subscriptionSmsIgnoreWarning(ignore: $ignore)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionSmsIgnoreWarningGQL extends Apollo.Mutation<SubscriptionSmsIgnoreWarningMutation, SubscriptionSmsIgnoreWarningMutationVariables> {
    document = SubscriptionSmsIgnoreWarningDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddReleaseNoteDocument = gql`
    mutation addReleaseNote($releaseNote: InputReleaseNote!) {
  addReleaseNote(releaseNote: $releaseNote) {
    id
    date
    description
    documentation
    oneliner
    video
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddReleaseNoteGQL extends Apollo.Mutation<AddReleaseNoteMutation, AddReleaseNoteMutationVariables> {
    document = AddReleaseNoteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReleaseNoteDocument = gql`
    mutation updateReleaseNote($id: ID!, $releaseNote: InputReleaseNote!) {
  updateReleaseNote(id: $id, releaseNote: $releaseNote) {
    id
    date
    description
    documentation
    oneliner
    video
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReleaseNoteGQL extends Apollo.Mutation<UpdateReleaseNoteMutation, UpdateReleaseNoteMutationVariables> {
    document = UpdateReleaseNoteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReleaseNoteDocument = gql`
    mutation deleteReleaseNote($id: ID!) {
  deleteReleaseNote(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReleaseNoteGQL extends Apollo.Mutation<DeleteReleaseNoteMutation, DeleteReleaseNoteMutationVariables> {
    document = DeleteReleaseNoteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkReleaseNoteAsReadDocument = gql`
    mutation markReleaseNoteAsRead($releaseNoteIds: [ID]!) {
  markReleaseNoteAsRead(releaseNoteIds: $releaseNoteIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkReleaseNoteAsReadGQL extends Apollo.Mutation<MarkReleaseNoteAsReadMutation, MarkReleaseNoteAsReadMutationVariables> {
    document = MarkReleaseNoteAsReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateIcsIdDocument = gql`
    mutation createIcsId {
  createIcsId
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateIcsIdGQL extends Apollo.Mutation<CreateIcsIdMutation, CreateIcsIdMutationVariables> {
    document = CreateIcsIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTenantByDisplayNameDocument = gql`
    query getTenantByDisplayName($displayName: String!) {
  getTenantByDisplayName(displayName: $displayName) {
    tenantId
    displayName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTenantByDisplayNameGQL extends Apollo.Query<GetTenantByDisplayNameQuery, GetTenantByDisplayNameQueryVariables> {
    document = GetTenantByDisplayNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTenantsDocument = gql`
    query getTenants {
  getTenants {
    tenantId
    displayName
    tenantSetting {
      upEnabled
      mailFrom {
        email
        name
      }
      mailReplyTo {
        email
        name
      }
      mailAccountant {
        email
        name
      }
      locationOptions {
        defaultLocation {
          id
        }
      }
      tenantId
      operatorSettings {
        backup {
          active
          email
        }
        crew {
          shareBookings
          allowFlightSelection
        }
        sms {
          footerEnabled
        }
        timezone
        manifest {
          footer
          pilotDeclaration
        }
        myflight {
          autoConfirm
          flights
          endOfSeason
        }
      }
      dashboardOptions {
        preferredDateToday
        recent
        toConfirm
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTenantsGQL extends Apollo.Query<GetTenantsQuery, GetTenantsQueryVariables> {
    document = GetTenantsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersDocument = gql`
    query getUsers($tenantId: String!) {
  getUsers(tenantId: $tenantId) {
    uid
    email
    customClaims
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query getUser {
  getUser {
    uid
    email
    userData {
      id
      crew {
        id
        email
        emailNotifications
      }
      email
      displayName
      language
      role
      viewState
      tenant
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TenantDocument = gql`
    query tenant {
  tenant {
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TenantGQL extends Apollo.Query<TenantQuery, TenantQueryVariables> {
    document = TenantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTenantDocument = gql`
    mutation createTenant($displayName: String!) {
  createTenant(displayName: $displayName) {
    tenantId
    displayName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTenantGQL extends Apollo.Mutation<CreateTenantMutation, CreateTenantMutationVariables> {
    document = CreateTenantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InitializeTenantDocument = gql`
    mutation initializeTenant($tenantId: ID!) {
  initializeTenant(tenantId: $tenantId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InitializeTenantGQL extends Apollo.Mutation<InitializeTenantMutation, InitializeTenantMutationVariables> {
    document = InitializeTenantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($GcpipUserInput: GcpipUserInput!, $tenantId: ID) {
  createUser(GcpipUserInput: $GcpipUserInput, tenantId: $tenantId) {
    uid
    email
    customClaims
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($uid: String!, $tenantId: ID) {
  deleteUser(uid: $uid, tenantId: $tenantId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendPasswordResetEmailDocument = gql`
    mutation sendPasswordResetEmail($email: ID!, $tenantId: ID) {
  sendPasswordResetEmail(email: $email, tenantId: $tenantId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendPasswordResetEmailGQL extends Apollo.Mutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables> {
    document = SendPasswordResetEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveUserViewStateDocument = gql`
    mutation saveUserViewState($viewState: JSONObject!) {
  saveUserViewState(viewState: $viewState) {
    displayName
    language
    viewState
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveUserViewStateGQL extends Apollo.Mutation<SaveUserViewStateMutation, SaveUserViewStateMutationVariables> {
    document = SaveUserViewStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetVoucherDocument = gql`
    query getVoucher($id: ID!) {
  voucher(id: $id) {
    id
    adults
    adultsUsed
    adultsAvailable
    children
    childrenUsed
    childrenAvailable
    contactName
    contactPhone
    external
    externalSource
    issueDate
    paymentAmount
    paymentDate
    paymentStatus
    paymentType
    voucherReference
    voucherType
    expiryDate
    status
    comments
    contactCity
    contactEmail
    contactLanguage
    recipientName
    recipientPhone
    recipientLanguage
    recipientEmail
    recipientAddress
    recipientPostalCode
    recipientCity
    recipientCountry
    tickets {
      passenger {
        id
        name
        child
        disability
      }
      booking {
        removed
        id
        inviteCount
        status
        icon
        iconColor
        contactName
        contactEmail
        contactPhone
        comments
        startDate
        endDate
        city
      }
      flight {
        date
        period
        balloon {
          name
        }
        id
        location {
          name
        }
        icon {
          sign
          color
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVoucherGQL extends Apollo.Query<GetVoucherQuery, GetVoucherQueryVariables> {
    document = GetVoucherDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VouchersDocument = gql`
    query Vouchers {
  vouchers {
    id
    adults
    children
    contactName
    contactPhone
    external
    externalSource
    issueDate
    paymentAmount
    paymentDate
    paymentStatus
    paymentType
    voucherReference
    voucherType
    expiryDate
    status
    comments
    contactCity
    contactEmail
    contactLanguage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VouchersGQL extends Apollo.Query<VouchersQuery, VouchersQueryVariables> {
    document = VouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOpenVouchersDocument = gql`
    query getOpenVouchers($orderBy: OrderByVoucher, $orderDirection: OrderDirection, $limit: NonNegativeInt, $filter: String, $cursor: String, $voucherType: String) {
  openVouchers(
    orderBy: $orderBy
    orderDirection: $orderDirection
    limit: $limit
    cursor: $cursor
    filter: $filter
    voucherType: $voucherType
  ) {
    id
    adults
    adultsUsed
    adultsAvailable
    children
    childrenUsed
    childrenAvailable
    contactName
    contactPhone
    external
    externalSource
    issueDate
    paymentAmount
    paymentDate
    paymentStatus
    paymentType
    voucherReference
    voucherType
    expiryDate
    status
    comments
    contactCity
    contactEmail
    contactLanguage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOpenVouchersGQL extends Apollo.Query<GetOpenVouchersQuery, GetOpenVouchersQueryVariables> {
    document = GetOpenVouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsedVouchersDocument = gql`
    query getUsedVouchers($orderBy: OrderByVoucher, $orderDirection: OrderDirection, $limit: NonNegativeInt, $filter: String, $cursor: String, $voucherType: String) {
  usedVouchers(
    orderBy: $orderBy
    orderDirection: $orderDirection
    limit: $limit
    cursor: $cursor
    filter: $filter
    voucherType: $voucherType
  ) {
    id
    adults
    children
    contactName
    contactPhone
    external
    externalSource
    issueDate
    paymentAmount
    paymentDate
    paymentStatus
    paymentType
    voucherReference
    voucherType
    expiryDate
    status
    comments
    contactCity
    contactEmail
    contactLanguage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsedVouchersGQL extends Apollo.Query<GetUsedVouchersQuery, GetUsedVouchersQueryVariables> {
    document = GetUsedVouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetExpiredVouchersDocument = gql`
    query getExpiredVouchers($orderBy: OrderByVoucher, $orderDirection: OrderDirection, $limit: NonNegativeInt, $filter: String, $cursor: String, $voucherType: String) {
  expiredVouchers(
    orderBy: $orderBy
    orderDirection: $orderDirection
    limit: $limit
    cursor: $cursor
    filter: $filter
    voucherType: $voucherType
  ) {
    id
    adults
    children
    contactName
    contactPhone
    external
    externalSource
    issueDate
    paymentAmount
    paymentDate
    paymentStatus
    paymentType
    voucherReference
    voucherType
    expiryDate
    status
    comments
    contactCity
    contactEmail
    contactLanguage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetExpiredVouchersGQL extends Apollo.Query<GetExpiredVouchersQuery, GetExpiredVouchersQueryVariables> {
    document = GetExpiredVouchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherCountersDocument = gql`
    query voucherCounters {
  voucherCounters {
    statusCounters {
      AVAI
      USED
      EXPI
    }
    voucherTypes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherCountersGQL extends Apollo.Query<VoucherCountersQuery, VoucherCountersQueryVariables> {
    document = VoucherCountersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VoucherCountersUpdatedDocument = gql`
    subscription voucherCountersUpdated {
  voucherCountersUpdated {
    statusCounters {
      AVAI
      USED
      EXPI
    }
    voucherTypes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VoucherCountersUpdatedGQL extends Apollo.Subscription<VoucherCountersUpdatedSubscription, VoucherCountersUpdatedSubscriptionVariables> {
    document = VoucherCountersUpdatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveVoucherDocument = gql`
    mutation saveVoucher($voucherInput: VoucherInput!, $voucherId: ID) {
  saveVoucher(voucherInput: $voucherInput, voucherId: $voucherId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveVoucherGQL extends Apollo.Mutation<SaveVoucherMutation, SaveVoucherMutationVariables> {
    document = SaveVoucherDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteVoucherDocument = gql`
    mutation deleteVoucher($voucherId: ID!) {
  deleteVoucher(voucherId: $voucherId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteVoucherGQL extends Apollo.Mutation<DeleteVoucherMutation, DeleteVoucherMutationVariables> {
    document = DeleteVoucherDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBookingFromVoucherDocument = gql`
    mutation createBookingFromVoucher($voucherId: ID!) {
  createBookingFromVoucher(voucherId: $voucherId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBookingFromVoucherGQL extends Apollo.Mutation<CreateBookingFromVoucherMutation, CreateBookingFromVoucherMutationVariables> {
    document = CreateBookingFromVoucherDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }