import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core';
import { onError } from 'apollo-link-error';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DialogModule } from './features/dialog/dialog.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { REGION } from '@angular/fire/functions';
import { MarkdownModule, MarkdownService, MarkedOptions } from 'ngx-markdown';

import { AuthenticationService } from './core/services';
import { CoreModule } from './core/core.module';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import * as Sentry from '@sentry/angular-ivy';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function createApollo(httpLink: HttpLink, authenticationService: AuthenticationService): ApolloClientOptions<any> {
	const uri = environment.apiUrl;
	const wsUri = environment.wsUrl;

	const tokenLink = setContext(async (operation, context) => {
		if (!authenticationService.authenticated || operation.operationName === 'getTenantByDisplayName') {
			return {};
		} else {
			// refresh Token each time
			if (authenticationService.expired) {
				// route to login page if token is 30 days expired
				if (authenticationService.expiredRefreshToken) {
					authenticationService.logout();
					return {};
				} else {
					console.log('Token expired - getting a new one');
					await authenticationService.getNewToken();
				}
			}
			const token = authenticationService.accessToken;

			return {
				headers: {
					Authorization: `Bearer ${token}`,
					client_id: `${token}`,
				},
			};
		}
	});

	const loggerLink = new ApolloLink((operation, forward) => {
		if (environment.debug) console.log(`GraphQL Request: ${operation.operationName}`);
		operation.setContext({ start: new Date() });
		return forward(operation).map((response) => {
			const responseTime = Math.abs(<any>new Date() - operation.getContext().start);
			if (environment.debug) console.log(`GraphQL Response for ${operation.operationName} took: ${responseTime} ms`);
			return response;
		});
	});

	const errorLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
		if (graphQLErrors) {
			for (let err of graphQLErrors) {
				switch (err.message) {
					case 'UNAUTHENTICATED!':
						if (environment.debug) console.log('[graphQLErrors]:', graphQLErrors);
						return forward(operation);
				}
			}
		}

		if (networkError) {
			console.log(`[Network error]: ${JSON.stringify(networkError)}`);
		}
	});

	const http = httpLink.create({
		uri,
	});

	const ws = new WebSocketLink({
		uri: wsUri,
		options: {
			reconnect: true,
		},
	});

	const link = split(
		({ query }) => {
			const data = getMainDefinition(query);
			return data.kind === 'OperationDefinition' && data.operation === 'subscription';
		},
		ws,
		http
	);

	return {
		link: ApolloLink.from([errorLink, loggerLink, tokenLink, link as any]) as any,
		cache: new InMemoryCache(),
		connectToDevTools: environment.debug,
	};
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		AngularFireAuthModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFirestoreModule,
		AngularFirePerformanceModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CoreModule,
		DialogModule,
		HttpClientModule,
		NgxGoogleAnalyticsModule.forRoot(environment.trackingCode),
		NgxGoogleAnalyticsRouterModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
			defaultLanguage: 'en',
			isolate: false,
			extend: true,
		}),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyAe5WsCZQaI9j3mJQIQoUsD2Jvs4r0E-jw',
		}),
		MarkdownModule.forRoot({
			markedOptions: {
			  provide: MarkedOptions,
			  useValue: {
				gfm: true,
				tables: true,
				breaks: true,
				pedantic: true,
				sanitize: true, 
				smartLists: true,
				smartypants: true,
			  },
			},
		  }),
	],
	exports: [SharedModule],
	bootstrap: [AppComponent],
	providers: [
		PerformanceMonitoringService,
		{
			provide: APOLLO_OPTIONS,
			useFactory: createApollo,
			deps: [HttpLink, AuthenticationService],
		},
		{ provide: REGION, useValue: 'europe-west1' },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{ provide: MarkdownService }
	],
})
export class AppModule {}
