import { Injectable } from '@angular/core';

import { Apollo, QueryRef } from 'apollo-angular';

import { CreateTenantDocument, GetTenantsDocument, InitializeTenantDocument, Tenant } from './../../graphql/generated/gen-types';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class TenantService {
	constructor(private apollo: Apollo) {}

	public createTenant(displayName: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: CreateTenantDocument,
				variables: {
					displayName: displayName,
				},
				update: (cache, { data }) => {
					const existingTenants = cache.readQuery<any>({ query: GetTenantsDocument });
					const newTenant = data.createTenant;
					cache.writeQuery({ query: GetTenantsDocument, data: { getTenants: [...existingTenants.getTenants, newTenant] } });
				},
			})
			.pipe(map((result) => result.data));
	}

	public initTenant(tenantId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: InitializeTenantDocument,
				variables: {
					tenantId,
				},
			})
			.pipe(map((result) => result.data));
	}

	public getTenants(): QueryRef<Tenant[]> {
		return this.apollo.watchQuery({ query: GetTenantsDocument });
	}
}
