import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserRole } from './core/graphql/generated/gen-types';

import { AuthenticationGuard } from './core/services/authentication/authentication.guard';
import { AuthorizationGuard } from './core/services/authorization/authorization.guard';
import { MainLayoutComponent } from './shared/layouts/main-layout.component';
import { MaintenanceComponent } from './features/account/maintenance/maintenance.component';

const accountModule = () => import('./features/account/account.module').then((x) => x.AccountModule);
const adminModule = () => import('./features/admin/admin.module').then((x) => x.AdminModule);
const balloonModule = () => import('./features/balloon/balloon.module').then((x) => x.BalloonModule);
const bookingModule = () => import('./features/booking/booking.module').then((x) => x.BookingModule);
const calendarModule = () => import('./features/calendar/calendar.module').then((x) => x.CalendarModule);
const settingsModule = () => import('./features/settings/settings.module').then((x) => x.SettingsModule);
const crewModule = () => import('./features/crew/crew.module').then((x) => x.CrewModule);
const cylinderModule = () => import('./features/cylinder/cylinder.module').then((x) => x.CylinderModule);
const dashboardModule = () => import('./features/dashboard/dashboard.module').then((x) => x.DashboardModule);
const flightModule = () => import('./features/flight/flight.module').then((x) => x.FlightModule);
const locationModule = () => import('./features/location/location.module').then((x) => x.LocationModule);
const mobileModule = () => import('./features/mobile/mobile.module').then((x) => x.MobileModule);
const regionModule = () => import('./features/region/region.module').then((x) => x.RegionModule);
const reportModule = () => import('./features/report/report.module').then((x) => x.ReportModule);
const voucherModule = () => import('./features/voucher/voucher.module').then((x) => x.VoucherModule);
const subscriptionModule = () => import('./features/subscription/subscription.module').then((x) => x.SubscriptionModule);

const routes: Routes = [
	{
		path: 'account',
		loadChildren: accountModule,
	},
	{
		path: 'maintenance',
		component: MaintenanceComponent,
	},
	{
		path: '',
		component: MainLayoutComponent,
		canActivate: [AuthenticationGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: dashboardModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr, UserRole.Cre, UserRole.Rep, UserRole.Own, UserRole.Ass, UserRole.Met] },
			},
			{
				path: 'calendar',
				loadChildren: calendarModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr, UserRole.Cre, UserRole.Ass, UserRole.Met] },
			},
			{
				path: 'flight',
				loadChildren: flightModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr, UserRole.Ass, UserRole.Met] },
			},
			{
				path: 'booking',
				loadChildren: bookingModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr, UserRole.Ass] },
			},
			{
				path: 'voucher',
				loadChildren: voucherModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr, UserRole.Ass] },
			},
			{
				path: 'settings',
				loadChildren: settingsModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr] },
			},
			{
				path: 'region',
				loadChildren: regionModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr] },
			},
			{
				path: 'crew',
				loadChildren: crewModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr] },
			},
			{
				path: 'balloon',
				loadChildren: balloonModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr] },
			},
			{
				path: 'location',
				loadChildren: locationModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr] },
			},
			{
				path: 'cylinder',
				loadChildren: cylinderModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr] },
			},
			{
				path: 'mobile',
				loadChildren: mobileModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr, UserRole.Cre, UserRole.Rep] },
			},
			{
				path: 'report',
				loadChildren: reportModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr, UserRole.Rep] },
			},
			{
				path: 'subscription',
				loadChildren: subscriptionModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Opr] },
			},
			{
				path: 'admin',
				loadChildren: adminModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Own] },
			},
			{
				path: 'releasenotes',
				loadChildren: adminModule,
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: { expectedRoles: [UserRole.Own] },
			},
		],
	},
	{ path: '**', redirectTo: 'login' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
